import React from "react";
import reviewTrans from "./img/Best-bike-advice-reviews.png";
import beoordelingTrans from "./img/Best-bike-advice-beoordelingen.png";
import {useTranslation} from "react-i18next";

const Trustpilot = () => {
  const [t, i18n] = useTranslation('homeBikefit');
  const language = i18n.language.split('-')[0]

  return (
    <a
      href="https://nl.trustpilot.com/review/bestbikeadvice.com"
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <img
        className=""
        style={{width: 160}}
        alt={t("BikefitHome_BestBikeAdviceReview")}
        src={
          language === "nl" ? beoordelingTrans : reviewTrans
        }
      />
    </a>
  )
}

export default Trustpilot;
