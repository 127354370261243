import React from 'react';

const ButtonComponent = ({ title, link, type, alignment }) => {
    return (
        <div style={{textAlign: alignment}} className="my-4">
            <a href={link} className={`btn-${type} btn btn-lg`} type={type}>{title}</a>
        </div>
    );
};

export default ButtonComponent;
