import { withTranslation } from 'react-i18next';
import React, {Component} from 'react'
import Popup from "../../../lib/Popup";
import DataStore from "../../../lib/DataStore";

class OverwriteLocalData extends Component {
    render() {
        return (
            <form className="overwrite-data">
                <div className="row mb-1">
                    <div className="col-12">
                        <h3>{this.props.t('OverwriteData_Title')}</h3>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="introText">{this.props.t('OverwriteData_OverwriteSession')}</div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className='col-12'>
                        <div className='form-group text-right'>
                            <button className="btn btn-default" onClick={this.useLocalData}>{this.props.t('OverwriteData_UseLocal')}</button>
                            {' '}
                            <button className="btn btn-secondary" onClick={this.useAccountData}>{this.props.t('OverwriteData_UseAccount')}</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    useLocalData = () => {
        DataStore.pushData().then(() => {
            window.location.reload();
            Popup.close();
        });
    };

    useAccountData = () => {
        DataStore.setValue(this.props.accountData);
        window.location.reload();
        Popup.close();
    }
}

export default withTranslation('overwriteLocalData')(OverwriteLocalData);
