import {getBaseUrl} from "../config";
import i18n from "../i18n";
import axios from "axios";
import {v4} from "uuid";
import Auth from "./Auth";
import Locale from "./Locale";

class Api {
    sessionId = '';

    constructor() {
        this.createSessionId();
    }

    get = (url, initalGetVars={}) => {
        let {getVars, config} = this.defaultGetVars(initalGetVars);

        return axios.get(this.addVarsToUrl(url, getVars), config).then(this.handleAuthError);
    };

    post = (url, data={}, initalGetVars={}) => {
        let {getVars, config} = this.defaultGetVars(initalGetVars);
        data['lang'] = getVars['lang'];
        return axios.post(this.addVarsToUrl(url, getVars), data, config).then(this.handleAuthError);
    };

    put = (url, data={}, initalGetVars={}) => {
        let {getVars, config} = this.defaultGetVars(initalGetVars);
        data['lang'] = getVars['lang'];
        return axios.put(this.addVarsToUrl(url, getVars), data, config).then(this.handleAuthError);
    };

    defaultGetVars = (initalGetVars) => {
        let getVars = initalGetVars;
        getVars['session_id'] = this.sessionId;
        getVars['base_url'] = getBaseUrl();
        // if lang is not a locale, make it one.
        getVars['lang'] = `${Locale.getLanguage()}_${Locale.getCountry()}`;

        let config = {};
        if (Auth.isLoggedIn()) {
            console.log('Sending auth header with request. Token: ' + Auth.getToken());
            config = {headers: {'Authorization': Auth.getToken()}};
        }

        return {getVars, config};
    };

    handleAuthError = (response) => {
        // old style api code
        let code = response.data[0] || false;

        // new style api code
        if (code === false) {
            code = (response.data.response || {}).status || false;
        }

        if (code === 401 || code === 400) {
            alert(i18n.t('SessionExpired'));
            Auth.clearData();
            window.location.reload();
            throw new Error("Login expired");
        }

        return response;
    };

    addVarsToUrl = (url, getVars) => url + '?' + Object.keys(getVars).map((key) => key + '=' + encodeURIComponent(getVars[key])).join('&');

    getSessionId = () => this.sessionId

    createSessionId = () => {
        let sessionId = localStorage.getItem('sessionId') || '';
        if (sessionId === '') {
            sessionId = v4();
            localStorage.setItem('sessionId', sessionId);
        }
        this.sessionId = sessionId;
    };
}
// eslint-disable-next-line
export default new Api();
