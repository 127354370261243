import {
  SET_BIKE,
  SET_BIKE_VALUE,
  SET_BIKE_SELECTION_VALUE,
  SET_CATEGORY
} from "./extraMeasurementsActionTypes";

const setExtraMeasurementBikeValue = (key, value) => ({
  type: SET_BIKE_VALUE,
  key,
  value
});

const setExtraMeasurementBikeSelection = (key, value) => ({
  type: SET_BIKE_SELECTION_VALUE,
  key,
  value
})

const setExtraMeasurementBikeCat = (value) => ({
  type: SET_CATEGORY,
  value
});

const setExtraMeasurementBike = (bike) => ({
  type: SET_BIKE,
  bike
})

export {
  setExtraMeasurementBike,
  setExtraMeasurementBikeValue,
  setExtraMeasurementBikeCat,
  setExtraMeasurementBikeSelection
};
