import React, {useEffect, useRef} from 'react';

const VideoPlayer = () => {
  const ref = useRef();

  useEffect(() => {
    ref.current.play()
  })

  return (
    <div>
      <video className="w-100 br-10 home-video"
             controls
             src={require("../video/Mountain-bike-buying-advice.mp4")}
             type="video/mp4"
             autoPlay
             ref={ref}
      >
      </video>
    </div>
  )
}

export default VideoPlayer;
