import React from 'react';
import {Link} from "react-router-dom";
import Locale from "../../../lib/Locale";
import {config} from "../../../config";

const BlogOverviewGrid = ({ blogData }) => {
  return (
    <>
      {blogData.map((blog) => (
        <div key={`blog-${blog.id}`} className="col-12 col-md-6 col-lg-4 mb-5">
          <div className="blog-overview-post">
            <div>
              <Link className="link" to={Locale.getUrlForCurrentLocale('/' + blog?.attributes?.slug)}>
                <img
                  className={`img-fluid`}
                  src={`${config.cmsApiUrl}${blog?.attributes?.image?.data?.attributes?.url}`}
                  alt={blog?.attributes?.description || ''}
                ></img>
              </Link>
            </div>
            <div className="my-2">
              <Link className="link" to={Locale.getUrlForCurrentLocale('/' + blog?.attributes?.slug)}>
                <span className="title">{blog?.attributes?.title}</span>
              </Link>
            </div>
            <div className="">
              <span className="description">{blog?.attributes?.description}</span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default BlogOverviewGrid;
