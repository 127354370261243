import React from "react";
import Card from "react-bootstrap/Card";
import "./ExpertCardCheckout.css";
import borderImage from "../../../../../pages/Homes/BikefitHome/img/border-image.png";

const ExpertCardCheckout = (review) => {
  const content = (review.lang || {}).content || "";
  const title = (review.lang || {}).title || "";
  const author = review.author || "";
  function createMarkup(text) {
    return { __html: text };
  }

  return (
    <Card className="w-auto mx-2 bg-light p-4">
      <Card.Body className="expert-review-card-body">
        <div className="row">
          <div className="col-md-4">
            <img alt="" src={`https://bestbikeadvice.com/${review.image}`} style={{ maxWidth: 100, width: '100%', height: 100 }} />
          </div>
          <div className="col-md-8">
            <div
              dangerouslySetInnerHTML={createMarkup(author)}
              style={{ fontSize: "20px" }}
            ></div>
            <div dangerouslySetInnerHTML={createMarkup(title)}></div>
          </div>
        </div>
        <Card.Text
          dangerouslySetInnerHTML={createMarkup(content)}
          className="text-muted mt-4"
        ></Card.Text>
        <div className="hr-with-img w-100">
          <hr className="w-100" />
          <span className="p-3"><img src={borderImage} alt="border-img" width="30" /></span>
        </div>
        
        <div>
          <div className="text-right">
            <img
              alt="Logo"
              src={`https://bestbikeadvice.com/${review.logo}`}
              style={{ width: "50%", maxHeight: 100 }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExpertCardCheckout;
