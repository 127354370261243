import React from 'react'
import {useTranslation} from "react-i18next";

const NoCookies = () => {
    const [t, ] = useTranslation()

    return (
        <div className="info-page">
            <div className="container main">
                {t('Cookies_Disabled')}
            </div>
        </div>
    )
}

export default NoCookies;