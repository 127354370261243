import React, {useState, useEffect} from 'react';
import {useTranslation, Trans} from "react-i18next";
import Subscription from "../../lib/Subscription";
import Redirections from "../../lib/Redirections";
import './style.css'

const Bikefinder = ({trajectory}) => {
    const [t,] = useTranslation('subscription')

    const [price, setPrice] = useState(150)

    const pay = () => {
        Subscription.createOrderAndGoToPayment(
            'bikefinder',
            'bikefinder_once',
            '/' + trajectory + '/advice/result'
        );
    }

    const loadPrice = () => {
        Subscription.getSubscriptions('bikefinder')
            .then(subscriptions => {
                const subscription = subscriptions.filter(subscription => subscription.code === 'bikefinder_once')[0] || null;
                if ( ! subscription) {
                    alert(t('Subscription_NotFound'))
                } else {
                    setPrice(subscription.price)
                }
            })
    }

    const back = () => {
        Redirections.goTo('/' + trajectory + '/advice/result');
    }

    useEffect(() => {
        loadPrice();
    })

    return (
        <div className="info-page">
            <div className="container main">
                <div className="row">
                    <div className="col-lg-10">
                        <h1>{t('Bikefinder_Title')}</h1>
                        <p className="mt-4">{t('Bikefinder_Text_Line1')}</p>
                        <p>{t('Bikefinder_Text_Line2')}</p>
                        <p>
                            <Trans i18nKey="subscription:Bikefinder_Text_Line3" values={{price: price / 100}}>
                                Temporarily we only ask for <b>{{price}}</b>. You will then receive <u>on the device on which you pay one month access</u> to all 2020 models in our system. (BMC, Cannondale, Canyon, Cube, Focus, Merida, Orbea, Scott, Specialized and Trek)
                            </Trans>
                        </p>
                        <p>{t('Bikefinder_Text_Line4')}</p>
                        <p>{t('Bikefinder_Text_Greetings')}</p>
                        <p className="mt-4">{t('Bikefinder_Text_Framefit')}</p>
                        <div className="text-right">
                            <button className="btn btn-secondary float-right" onClick={pay}>{t('Bikefinder_Pay')}</button>
                            <button className="btn btn-skip" onClick={back}>{t('Bikefinder_Back')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bikefinder;
