import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// eslint-disable-next-line
export default ({ type }) => {
  const [t] = useTranslation("bikefitPromo");

  const typeString = type === "mtb" ? t("Carousel_MTB") : t("Carousel_Race");
  const brandsTranslation = t("Carousel_Brands");

  const brands = [
    ["bmc", "png"],
    ["cannondale", "png"],
    ["canyon", "png"],
    ["cube", "png"],
    ["felt", "png"],
    ["focus", "jpg"],
    ["merida", "png"],
    ["orbea", "jpg"],
    ["scott", "png"],
    ["specialized", "png"],
    ["stevens", "png"],
    ["trek", "png"],
  ];

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      showArrows={false}
      infiniteLoop={true}
      swipeable={true}
      autoPlay={true}
    >
      {brands.map((brand, i) => (
        <div key={i}>
          <img
            className="img-responsive"
            src={`/img/logos/${typeString}-${brandsTranslation}-${brand[0]}.${brand[1]}`}
            alt={`${typeString} ${brandsTranslation} ${brand[0]}`}
          />
        </div>
      ))}
    </Carousel>
  );
};
