import { withTranslation } from 'react-i18next';
import React, {Component} from 'react';
import Auth from "../../lib/Auth";
import FormPage from "../FormPage";
import Redirections from '../../lib/Redirections';
import Locale from '../../lib/Locale';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        let requestUrl = props.history.location.pathname;
        let confirmCode = requestUrl.split("/reset/")[1].split("/")[0];
        let userEmail = requestUrl.split("/email/")[1].split("/")[0];

        this.state = {
            confirmationCode: confirmCode,
            email: userEmail,
            password: '',
            passwordRepeat: '',
            buttonDisabled: false,
            success: false
        };
    }

    render() {
        return (
            <FormPage
                handleSubmit={this.handleSubmit}
                title={this.props.t('ResetPassword_Title')}
                subTitle={this.props.t('ResetPassword_Info')}>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="password" onChange={this.inputPassword}
                                   className="form-control" placeholder={this.props.t('ResetPassword_Password')}
                                   value={this.state.password}/>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="password" onChange={this.inputPasswordRepeat}
                                   className="form-control" placeholder={this.props.t('ResetPassword_PasswordRepeat')}
                                   value={this.state.passwordRepeat}/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group text-center">
                            <input type="submit" name="btnSubmit" className="btn btn-secondary"
                                   disabled={this.state.buttonDisabled}
                                   value={this.props.t('ResetPassword_Button')}/>
                        </div>
                    </div>
                </div>
            </FormPage>
        );
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.password === this.state.passwordRepeat) {
            this.setState({buttonDisabled: true});

            Auth.reset(this.state.email, this.state.password, this.state.confirmationCode)
              .then(() => {
                  alert(this.props.t('ResetPassword_Success'));
                  const redirectUrl = localStorage.getItem('redirectUrl');
                  redirectUrl ? Redirections.replaceTo(Locale.getUrlForCurrentLocale('/subscription/payment/data/')) : window.location = '/';
              })
              .catch(() => alert(this.props.t('ResetPassword_Failure')))
              .finally(() => this.setState({buttonDisabled: false}));
        } else {
            alert(this.props.t('ResetPassword_PasswordsNoMatch'));
        }
    };

	inputPassword = (event) => {
		this.setState({password: event.target.value});
	};

	inputPasswordRepeat = (event) => {
		this.setState({passwordRepeat: event.target.value});
	};
}

export default withTranslation('resetPassword')(ResetPassword);
