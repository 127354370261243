import React from 'react'
import FeedbackFooter from './FeedbackFooter';
import { useTranslation, Trans } from 'react-i18next';


const Feedback5 = ({onFeedback, onFinish, onClose, discountPercentage}) => {
    const [t, ] = useTranslation("subscription");

    return (
        <div>
            <div className="mb-2">
                {t('Bedankt voor jouw feedback')}
            </div>
            <div className="mb-2">
                <Trans i18nKey='subscription:Feedback5_Title' />
            </div>
            <div className="mb-2">
                <Trans i18nKey='subscription:Feedback5_Desc' values={{discountPercentage: discountPercentage}} />
            </div>
            <FeedbackFooter onFeedback={onFeedback} onFinish={onFinish} onClose={onClose} discountPercentage={discountPercentage} />
        </div>
    )
}

export default Feedback5;