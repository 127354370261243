import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import './index.css';
import i18n from './i18n';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from "react-redux";
import history from './history';
import store from "./redux/store";
import { Router } from "react-router-dom";
import OpenFeatureProvider from './open-feature/OpenFeatureProvider';
import { config } from "./config";

const flagsmithApiKey = config.flagsmithEnvironmentKey;
const flagsmithApiHost = config.flagsmithHost;

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <OpenFeatureProvider apiKey={flagsmithApiKey} apiHost={flagsmithApiHost}>
            <Provider store={store}>
                <Router history={history}>
                    <App history={history} />
                </Router>
            </Provider>
        </OpenFeatureProvider>
    </I18nextProvider>
    , document.getElementById('root'));
registerServiceWorker();
