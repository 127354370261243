import React, {useEffect} from "react";
import "./style.css";
import {useTranslation} from "react-i18next";
import Popup from "../../lib/Popup";
import CookiePreferencesPopup from "./CookiePreferencesPopup";
import styled from "styled-components";
import {cookieStorage} from "./lib/cookieStorage"
import { selectWhitelabelInfo } from "../../redux/selectors/whitelabel";
import { useSelector } from "react-redux";

function CookieBanner() {
  const [t] = useTranslation("cookies");

  const storageType = cookieStorage;
  const consentPropertyName = "cookie-consent";
  const statisticsPropertyName = "stats";
  const marketingPropertyName = "marketing";
  const requiredPropertyName = "required";

  const whiteLabelInfo = useSelector(selectWhitelabelInfo);
  const shouldShowPopup = () => !storageType.isAnswered();

  const saveToStorage = (PropertyName) =>
    storageType.setItem(PropertyName, true);

  const acceptfn = (acceptBtn, event) => {
    const consentPopup = document.getElementById("consent-banner");
    saveToStorage(consentPropertyName);
    saveToStorage(statisticsPropertyName);
    saveToStorage(marketingPropertyName);
    saveToStorage(requiredPropertyName);
    consentPopup.classList.add("d-none");
  };

  useEffect(() => {
    const consentPopup = document.getElementById("consent-banner");
    if (shouldShowPopup(storageType)) {
      setTimeout(() => {
        consentPopup.classList.remove("d-none");
      }, 1000);
    }
  }, []);

  const preferencesCookies = (statistics, marketing) => {
    const consentPopup = document.getElementById("consent-banner");
    if (statistics) {
      saveToStorage(statisticsPropertyName);
    }
    if (marketing) {
      saveToStorage(marketingPropertyName);
    }
    saveToStorage(consentPropertyName);
    saveToStorage(requiredPropertyName);
    Popup.close();
    consentPopup.classList.add("d-none");
  };

  const acceptAllCookies = () => {
    const consentPopup = document.getElementById("consent-banner");
    Popup.close();
    acceptfn(consentPopup);
  };

  const showPreferences = () => {
    Popup.show(
      <CookiePreferencesPopup
        preferencesCookies={preferencesCookies}
        acceptAllCookies={acceptAllCookies}
      />,
      true
    );
  };
  const ButtonContainer = styled.div`
  .btn-secondary {
      background-color: ${whiteLabelInfo?.color2 || '#f5755d'};
      border-color: ${whiteLabelInfo?.color2 || '#f5755d'};
  }
       .btn-link {
            color: ${whiteLabelInfo?.color2 || '#f5755d'};
        }
`;
  const Container = styled.div`
      background-color: ${whiteLabelInfo?.color3 || '#696969f9'};
`;
  return (
    <Container id="consent-banner" className="d-none">
      <div className="container">
        <div className="row">
          <div className="col-4 col-lg-2">
            <h4>{t("Cookies on this website")}</h4>
          </div>
          <div className="col-8 col-lg-7">
            <p>{t("Cookies_Banner_Text")}</p>
          </div>
          <div className="col-12 mt-4 mt-lg-0 col-lg-3 text-center">
            <ButtonContainer>
              <button onClick={acceptfn} className="btn btn-secondary">
                {t("Accept_Cookies_btn")}
              </button>
            <div>
              <button className="btn btn-link" onClick={showPreferences}>
                {t("Preferences_btn")}
              </button>
            </div>
            </ButtonContainer>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default CookieBanner;
