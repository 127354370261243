import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import './style.css'

const Feedback0 = ({ onFeedback, onClose, discountPopupOptions, feedbackTexts }) => {
    const [t, ] = useTranslation("subscription");
    const [othersDisplay, setOthersDisplay] = useState('none');
    const [othersValue, setOthersValue] = useState('');

    const showOther = (event) => {
        const checked = (event?.target?.checked === true);
        console.log('checked', checked);
        if(checked) {
            setOthersDisplay('row');
        } else {
            setOthersDisplay('none');
        }

    }


    return (
        <>
            <div className="mb-2">
                {t('Help onze service te verbeteren door middel van jouw feedback')}
            </div>
            <div className="mb-2">
                {t('Ik heb de bikefit nog niet gekocht omdat:')}
            </div>
            <div className="mt-4">
                <Form>
                    {
                        discountPopupOptions?.map((option, index) => {
                            if (option) {
                                return (
                                    <div className="mb-2" key={index}>
                                        <Form.Check
                                            inline
                                            onChange={(e) => index === 4 ? showOther(e) : onFeedback(index + 1)}
                                            type="radio"
                                            label={t(feedbackTexts[index + 1])}
                                            name="radioGroup"
                                            id={`radioGroup-${index + 1}`}
                                        />
                                    </div>
                                )
                            }
                            return null
                        })
                    }
                        <div className={"row d-" + othersDisplay}>
                            <div className="col-12">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={othersValue}
                                    onChange={(event) => setOthersValue(event.target.value)}
                                />
                            </div>
                        </div>
                </Form>
            </div>
            <div className="mt-4 align-items-end text-right">
            <Button variant="outline-secondary" className="m-2" onClick={onClose}>
                {t('Afbreken')}
            </Button>
            <Button type="secondary" className={"m-2 d-"+othersDisplay} onClick={(e) => {onFeedback(othersValue); e.preventDefault();}}>{t('Volgende')}</Button>
            </div>
        </>
    )
}

export default Feedback0;