import React from 'react'
import {useTranslation} from "react-i18next";
import Auth from "../../../lib/Auth";
import Popup from "../../../lib/Popup";
import './style.css'

const TokenAccess = () => {
    const [t, ] = useTranslation('tokenAccess');

    return (
        <form className="token-access-popup">
            <div className="row mb-1">
                <div className="col-12">
                    <h3>{t('TokenAccess_Title')}</h3>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12">
                    <div className="introText">{t('TokenAccess_Info')}</div>
                </div>
            </div>
            <div className="row mt-3">
                <div className='col-12'>
                    <div className='form-group text-right'>
                        <button className="btn btn-default"
                                onClick={Auth.logout}>{t('TokenAccess_Logout')}</button>
                        {' '}
                        <button className="btn btn-secondary"
                                onClick={Popup.close}>{t('TokenAccess_Continue')}</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default TokenAccess;