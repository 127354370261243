import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function MultiCarousel({ reviews = [], component }) {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () =>
      setIsPlaying(window.innerWidth < 1025)
    );
  }, []);

  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay={isPlaying}
      display
      autoPlaySpeed={5500}
      centerMode={false}
      className="w-100"
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside={true}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1025,
          },
          items: 3,
          partialVisibilityGutter: 30,
        },
        mobile: {
          breakpoint: {
            max: 513,
            min: 1,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 513,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
      }}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {reviews.map((review, index) => (
        <div key={index}>{component(review)}</div>
      ))}
    </Carousel>
  );
}

export default MultiCarousel;
