import React, { useState } from "react";
import Card from "react-bootstrap/Card";
// import { BsStar, BsStarHalf, BsStarFill } from "react-icons/bs";
import "./Customer.css";

const CustomerCard = ({ review, cardClass }) => {
  const [rate] = useState(review.rate);

  function createMarkup(text) {
    return { __html: text };
  }

  return (
    <Card className={`card bg-light ${cardClass}`}>
      <Card.Body className="card__body p-4">
        <Card.Text className="card__author mb-4">
          <strong>{review.author}</strong>
        </Card.Text>
        <Card.Text
          dangerouslySetInnerHTML={createMarkup(review.lang.content)}
          className="card__text text-muted mb-4"
        ></Card.Text>
        <img className="star-ratting-img mb-3" alt={`rate-${rate}`} src={`https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${rate}.svg`} />
        {/* <div className="card__stars"> {stars} </div> */}
      </Card.Body>
    </Card>
  );
};

export default CustomerCard;
