import React, { useEffect, useState } from "react";
import bikeFitOptions from "../../../bikeFitOptions";
import { useTranslation } from "react-i18next";

const MeasurementList = ({ choices }) => {
  const [leftColumnMeasurements, setLeftColumnMeasurements] = useState({});
  const [rightColumnMeasurements, setRightColumnMeasurements] = useState({});
  const agility = bikeFitOptions["flexibility"];

  const [t, i18n] = useTranslation("bikefitResult");

  useEffect(() => {
    const fitness = choices.stepA["bikeType"] === 1 ? bikeFitOptions["sittingPositionRoad"] : bikeFitOptions["sittingPositionMTB"];;

    const bikeTypeData =
      choices.stepA["bikeType"] === 1
        ? bikeFitOptions["roadBikeCategory"].data.find(
            (x) => x.id === choices.stepA["roadBikeCategory"]
          )
        : bikeFitOptions["mountainBikeCategory"].data.find(
            (x) => x.id === choices.stepA["mountainBikeCategory"]
          );

    let fitnessData = {};
    if (choices.stepB["sittingPosition"] !== undefined)
      fitnessData = fitness.data.find(
        (x) => x.id === choices.stepB["sittingPosition"]
      );

    let agilityData = {};
    if (choices.stepB["flexibility"] !== undefined)
      agilityData = agility.data.find(
        (x) => x.id === choices.stepB["flexibility"]
      );

    setLeftColumnMeasurements({
      BIKETYPE: bikeTypeData !== undefined ? t(bikeTypeData["bikeName"]) : "",
      FITNESS: fitnessData["title"] ? t(fitnessData["title"]) : "",
      AGITLITY: agilityData["title"] ? t(agilityData["title"]) : "",
      SITTING_POSITION: t(`BACK_ANGLE_NAME_${choices.stepB["backAngle"]}`),
      SADDLE_LENGTH: (choices.stepD["partSizes"][1] || 0) + " mm",
      CRANK_LENGTH: (choices.stepD["partSizes"][2] || 0) + " mm",
      [choices.stepA["bikeType"] === 1 ? "STEERINGREACH" : "BACKSWEEP"]:
        (choices.stepA["bikeType"] === 1
          ? ((choices || {}).stepD || {})["partSizes"][3] || 0
          : ((choices || {}).stepD || {})["partSizes"][4] || 0) + " mm",
    });

    setRightColumnMeasurements({
      LENGTH:
        (((choices || {}).stepC || {})["bodyMeasurement"] || 0) / 10 + " cm",
      BODY_HEIGHT:
        (((choices || {}).stepC || {})["bodyHeight"] || 0) / 10 + " cm",
      BODY_LENGTH:
        (((choices || {}).stepC || {})["bodyLength"] || 0) / 10 + " cm",
      LEG_LENGTH:
        (((choices || {}).stepC || {})["legLength"] || 0) / 10 + " cm",
      UPPER_LEG_LENGTH:
        (((choices || {}).stepC || {})["upperThigh"] || 0) / 10 + " cm",
      LOWER_LEG_LENGTH:
        (((choices || {}).stepC || {})["lowerLeg"] || 0) / 10 + " cm",
      ARM_LENGTH:
        (((choices || {}).stepC || {})["armLength"] || 0) / 10 + " cm",
      SHOE_SIZE: (((choices || {}).stepC || {})["shoeSize"] || 0) + " EU",
    });
  }, [choices, i18n.language]);

  const Row = ({ title, value }) => (
    <div className="adviceList row">
      <div className="list col-6">{t(title)}</div>
      <div className="list col-6 text-right text-md-left">{value}</div>
    </div>
  );

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        {Object.entries(leftColumnMeasurements).map(([key, value]) => (
          <Row key={key} title={key} value={value} />
        ))}
        {choices.stepA["bikeType"] === 2 &&
        choices.stepD["suspension"] !== undefined ? (
          <React.Fragment>
            <Row
              title="SPRING_FRONT_WHEEL"
              value={
                (choices.stepD !== undefined
                  ? choices.stepD["suspension"][1]
                    ? choices.stepD["suspension"][1]
                    : choices.stepD["suspension"][1]
                  : 0) + " mm"
              }
            />

            <Row
              title="SPRING_WHEEL"
              value={
                (choices.stepD !== undefined
                  ? choices.stepD["suspension"][2]
                    ? choices.stepD["suspension"][2]
                    : choices.stepD["suspension"][2]
                  : 0) + " mm"
              }
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
      <div className="col-12 col-md-6">
        {Object.entries(rightColumnMeasurements).map(([key, value]) => (
          <Row key={key} title={key} value={value} />
        ))}
      </div>
    </div>
  );
};

export default MeasurementList;
