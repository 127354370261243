import React from "react";
import {useTranslation, Trans} from "react-i18next";

function WarningPopupComponent({close, changeDrop}) {
  const [t,] = useTranslation("dropWarningPopup");

  return (
    <div>
      <h3 className="mb-4 text-center">{t('warningTitle')}</h3>
      <div className="text-center">
        <Trans i18nKey={"dropWarningPopup:warningMessage"}>
          <p>Op een sportieve fiets staat het stuur meestal lager dan het zadel. In ons systeem moet je hiervoor <u>een
            negatief getal</u> invullen bij de drop.</p><p>Jij hebt een positieve drop ingevuld. <strong>Klopt het dat
          jouw stuur hoger moet staan dan jouw zadel?</strong></p><p>Een hoger stuur kan voorkomen bij een comfort
          zithouding op bijvoorbeeld een mountainbike of hybride fiets.</p>
        </Trans>
      </div>
      <div className="mt-5 d-flex justify-content-center">
        <button className="btn btn-skip btn-secondary" onClick={close}>{t('yesButton')}</button>
        <button className="btn btn-skip btn-secondary" onClick={changeDrop}>{t('noButton')}</button>
      </div>
    </div>
  );
}

export default WarningPopupComponent
