import React from 'react';
import logo from "./img/best-bike-advice-logo.png";
import logoWebp from "./img/best-bike-advice-logo.webp";
import Locale from '../../../../lib/Locale';
import { useTranslation } from 'react-i18next';
import BikeFittingButton from '../button/BikeFittingButton';
import { Link } from 'react-router-dom'

const Footer = () => {
  const [t] = useTranslation('newFooter')
  return (
    <>
      <div style={{ backgroundColor: '#2A2A2A' }} className=' text-white m-0 py-3 py-sm-3 py-md-4 py-xl-5'>

        <div className='row container mx-auto'>

        <div className='col-md-4 p-0 px-md-3'>
          <a className="navbar-brand" href={Locale.getUrlForCurrentLocale("/")}>
            <picture>
              <source srcSet={logoWebp} type="image/webp" />
              <img className="img-responsive" src={logo} alt="Logo BestBikeAdvice" />
            </picture>
          </a>
          <div className='mt-md-5 mt-2'>
              <p style={{ fontSize: '16px' }} className='m-0'>+31 854 012 539</p>
              <p style={{ fontSize: '16px' }} className='m-0'>info@bestbikeadvice.com</p>
          </div>
        </div>

        <div className='col-md-4 p-0 px-md-3 mt-md-0 mt-2'>
          <Link to="/blog" style={{ fontSize: '20px' }} className="text-white">{t("knowledge")}</Link>
          <div>
              <Link to={Locale.getUrlForCurrentLocale("/knee-pain")} style={{ fontSize: '16px' }} className="d-block text-white">{t("knee_pain")}</Link>
              <Link to={Locale.getUrlForCurrentLocale("/back-pain")} style={{ fontSize: '16px' }} className="d-block text-white">{t("back_pain")}</Link>
              <Link to={Locale.getUrlForCurrentLocale("/road-frame-size")} style={{ fontSize: '16px' }} className="d-block text-white">{t("choose_bicycle")}</Link>
              <Link to={Locale.getUrlForCurrentLocale("/saddle-tilt")} style={{ fontSize: '16px' }} className="d-block text-white">{t("saddle_angle")}</Link>
              <Link to={Locale.getUrlForCurrentLocale("/road-position")} style={{ fontSize: '16px' }} className="d-block text-white">{t("sitting_position")}</Link>
              <Link to={Locale.getUrlForCurrentLocale("/road-setup")} style={{ fontSize: '16px' }} className="d-block text-white">{t("saddle_height")}</Link>
          </div>
        </div>

        <div className='col-md-4 p-0 px-md-3 mt-md-0 mt-2'>
          <Link to="/" style={{ fontSize: '20px' }} className="text-white">Best Bike Advice</Link>
          <div>
              <Link to="/" style={{ fontSize: '16px' }} className="d-block text-white">{t("services")}</Link>
              <Link to="/blog" style={{ fontSize: '16px' }} className="d-block text-white">{t("knowledge")}</Link>
            <a href="https://nl.trustpilot.com/review/bestbikeadvice.com"
              target="_blank"
                rel="noopener noreferrer nofollow" style={{ fontSize: '16px' }} className="d-block text-white">Reviews</a>
              <Link to="/pricing" style={{ fontSize: '16px' }} className="d-block text-white">{t("pricing")}</Link>
              <Link to="/contact" style={{ fontSize: '16px' }} className="d-block text-white">Contact</Link>
          </div>
        </div>

          <div className='col p-0 px-md-3 mt-2 justify-content-md-end justify-content-center d-flex'>
          <div>

            <BikeFittingButton buttonColor="#F4DE58" />
          </div>
        </div>
        </div>


      </div>

      <div style={{ backgroundColor: '#F5755D', fontSize: '14px' }} className='text-center text-white'>
        <p className='p-2 m-0'>{t("credit")}</p>
      </div>
    </>
  );
};

export default Footer;