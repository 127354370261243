import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { getResultVars } from "../Bikefit/lib/bikefit";
import Api from "../../lib/Api";

const AdviceSource = ({
  category,
  saddleHeight,
  saddleSetback,
  handlebarReach,
  personLength,
  drop,
  submitted,
  adviceType,
  adviceSource,
  setAdviceSource
}) => {
  const [t] = useTranslation("cockpitAdvice");
  const advice = JSON.parse(localStorage.getItem("last_result_advice") || "{}");
  const choices = JSON.parse(localStorage.getItem("last_result_choices") || "{}");
  const bikeType = choices?.stepA?.bikeType === 2 ? "MTB" : "Race";

  useEffect(() => {
    getMeasurementsQuality();
  }, [submitted]);

  const getMeasurementsQuality = () => {
    console.log('choices', choices);
    if (Object.keys(choices).length === 0) {
      return;
    }


    const url = "/get_measurements_quality";
    const resultVars = getResultVars(choices);

    Api.get(url, resultVars)
      .then((response) => {
        if (!isAdvicedBikefit()) {
          setAdviceSource('adviceSourceOwnbikeFit');
        } else if (isAdvicedBikefit() && response?.data?.data?.quality_score === 'likely') {
          setAdviceSource('adviceSourceBikeFitNoWarning');
        } else if (isAdvicedBikefit() && response?.data?.data?.quality_score !== 'likely') {
          setAdviceSource('adviceSourceBikeFitWithWarning');
        }
      })
      .catch((response) => {
        console.log("error");
      });
  };

  const isAdvicedBikefit = () => {
    if(parseFloat(advice?.saddle_height/10) === parseFloat(saddleHeight) 
      && parseFloat(advice?.saddle_setback/10) === parseFloat(saddleSetback)
      && parseFloat(advice?.bike_length/10) === parseFloat(handlebarReach)
      && parseFloat(advice?.bike_drop/10) === parseFloat(drop)
      && bikeType === category) {
        return true;
      }

    return false;
  };

    return (
      <>
        <p className="mb-0">{t(adviceSource)}</p>
        {adviceType === "cockpitAdvice" && <p className="mb-0">{t('SizeIntro')}</p>}
        {adviceType === "frameSizeAdvice" && <p className="mb-0">{t('adviceText1')}</p>}
        {adviceSource === "adviceSourceBikeFitWithWarning" && <p className="warning">{t('adviceSourceWarning')}</p>}
      </>
    );
}

export default AdviceSource;