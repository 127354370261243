import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Api from "../../lib/Api";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import BikeComparisionCard from "./BikeComparisionCard"
import BikeDetailsCard from "./BikeDetailsCard"
import Subscription from "../../lib/Subscription";
import Locale from "../../lib/Locale";

//import CurrencyFormatter from "../../lib/CurrencyFormatter";

import "./style.css";

const BikeComparision = () => {
    const [t, i18n] = useTranslation("bikeComparision");
    const language = i18n.language.split('-')[0]
    // eslint-disable-next-line
    const [price, setPrice] = useState(2500);
    // eslint-disable-next-line
    const [currency, setCurrency] = useState('EUR');
    const [category, setCategory] = useState(localStorage.getItem("cb-category"));
    const [bikeTypeOptn, setBikeTypeOptn] = useState([]);
    const [bikeType1, setBikeType1] = useState(localStorage.getItem("cb-bikeType1"));
    const [bikeType2, setBikeType2] = useState(localStorage.getItem("cb-bikeType2"));
    const [year1, setYear1] = useState(localStorage.getItem("cb-year1"));
    const [year2, setYear2] = useState(localStorage.getItem("cb-year2"));
    const [brandOptn1, setBrandOptn1] = useState([]);
    const [brandOptn2, setBrandOptn2] = useState([]);
    const [brand1, setBrand1] = useState(localStorage.getItem("cb-brand1"));
    const [brand2, setBrand2] = useState(localStorage.getItem("cb-brand2"));
    const [modelOptn1, setModelOptn1] = useState([]);
    const [modelOptn2, setModelOptn2] = useState([]);
    const [model1, setModel1] = useState(localStorage.getItem("cb-model1"));
    const [model2, setModel2] = useState(localStorage.getItem("cb-model2"));
    const [bike1, setBike1] = useState();
    const [bike2, setBike2] = useState();
    
    
    const loadPrice = () => {
        Subscription.getSubscriptions("bikefit").then((subscriptions) => {
          const subscription =
            subscriptions.filter(
              (subscription) => subscription.code === "bikefit_once"
            )[0] || false;
          if (subscription) {
            setPrice(subscription.price.price);
            setCurrency(subscription.price.currency);
          }
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadPrice();
        setSelectOptn('/bike-info/' + category + '/types', 'bikeType', "", setBikeTypeOptn)
    }, [])
    useEffect(() => {
        if (category && category!=="false") {
            localStorage.setItem("cb-category",category)
            setSelectOptn('/bike-info/' + category + '/types', 'bikeType', "", setBikeTypeOptn)
        } else {
            
            setCategory(false)
        }
    }, [category])
    // useEffect(() => {
    //     setBikeType1('select one')
    //     setBikeType2('select one')
    // }, [bikeTypeOptn])
    useEffect(() => {
        localStorage.setItem("cb-bikeType1",bikeType1)
        if(!bikeType1||bikeType1==='select one'){
            setYear1('select one')
        }
    }, [bikeType1])
    useEffect(() => {
        localStorage.setItem("cb-bikeType2",bikeType2)
        if(!bikeType2||bikeType2==='select one'){
            console.log('inside if')
            setYear2('select one')
        }
    }, [bikeType2])
    useEffect(() => {
        localStorage.setItem("cb-year1",year1)
        if (bikeType1 && bikeType1 !== 'select one' && year1 && year1 !== 'select one') {
            setSelectOptn('/bike-info/' + bikeType1 + '/' + year1 + '/brands', 'brand1', brand1, setBrandOptn1)
        } else {
            setBrandOptn1([])
            setBrand1('select one')
        }

    }, [year1])
    useEffect(() => {
        localStorage.setItem("cb-year2",year2)
        if (bikeType2 && bikeType2 !== 'select one' && year2 && year2 !== 'select one') {
            setSelectOptn('/bike-info/' + bikeType2 + '/' + year2 + '/brands', 'brand2', brand2, setBrandOptn2)
        } else {
            setBrandOptn2([])
            setBrand2('select one')
        }
    }, [year2])
    useEffect(() => {
        localStorage.setItem("cb-brand1",brand1)
        if (brand1 && brand1 !== 'select one') {
            setSelectOptn('/bike-info/' + bikeType1 + '/' + year1 + '/' + brand1 + '/models', 'model1', model1, setModelOptn1)
        } else {
            setModelOptn1([])
            setModel1('select one')
        }
    }, [brand1])
    useEffect(() => {
        localStorage.setItem("cb-brand2",brand2)
        if (brand2 && brand2 !== 'select one') {
            setSelectOptn('/bike-info/' + bikeType2 + '/' + year2 + '/' + brand2 + '/models', 'model2', model2, setModelOptn2)
         }
         else {
            setModelOptn2([])
            setModel2('select one')
        }

    }, [brand2])
    useEffect(() => {
        localStorage.setItem("cb-model1",model1)
        if (model1 && model1 !== 'select one') {
            getBikeDetails(bikeType1, year1, brand1, model1, setBike1)
        } else {
            setBike1("")
        }
    }, [model1])
    useEffect(() => {
        localStorage.setItem("cb-model2",model2)
        if (model2 && model2 !== 'select one') {
            getBikeDetails(bikeType2, year2, brand2, model2, setBike2)
        } else {
            setBike2("")
        }

    }, [model2])
    const setSelectOptn = (url, key, selectedValue, fxn) => {
        Api.get(url).then(response => {
            let data = response.data ? response.data : {};
            let res = data.response ? data.response : {}
            let list = res.data ? res.data : []
            let selectOptn = [];
            if (list.length > 0) {
                list= list.sort();

                selectOptn.push(
                    <option key={key} value={'select one'} >{t('select one')}</option>
                )
            }
            for (let i in list) {
                selectOptn.push(
                    <option key={key +"-"+i } value={list[i]} >{list[i]}</option>
                )
            }
            fxn(selectOptn)
        })
    }
    const getBikeDetails = (bike_type, year, brand, model, fxn) => {
        Api.get('/bike-info/' + bike_type + '/' + year + '/' + brand + '/' + model + '/bikes').then(response => {
            let data = response.data ? response.data : {};
            let res = data.response ? data.response : {}
            let info=res.data?res.data["0"]:"";
            fxn(info)
        })
    }
    const meta = {
        title: t("CompareBikes"),
        description: t("text1"),
    };
    const categoryOptn = [
        <option key="category-1" value={false}>{t('select one')}</option>,
        <option key="category-2" value="Race" >{t('Racebike')}</option>,
        <option key="category-3" value="MTB" >{t('Mountainbike')}</option>,
    ];
    const yearOptn1 = bikeTypeOptn.length ? [
        <option key="year-1" value="select one" >{t('select one')}</option>,
        <option key="year-6" value="2023" >2023</option>,
        <option key="year-5" value="2022" >2022</option>,
        <option key="year-4" value="2021" >2021</option>,
        <option key="year-3" value="2020" >2020</option>,
        <option key="year-2" value="2019" >2019</option>
    ] : [];
    const yearOptn2 = bikeTypeOptn.length ? [
        <option key="year2-1" value='select one' >{t('select one')}</option>,
        <option key="year2-6" value="2023" >2023</option>,
        <option key="year2-5" value="2022" >2022</option>,
        <option key="year2-4" value="2021" >2021</option>,
        <option key="year2-3" value="2020" >2020</option>,
        <option key="year2-2" value="2019" >2019</option>

    ] : [];
    console.log(brand2)
    return (
        <div className={"bikeComparision bike-comparision " + language}>
            <DocumentMeta {...meta} />
            <div className="container">
                <div className="row justify-content-center">
                    <h1 className="m-4">{t("CompareBikes")}</h1>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        {t('text1')}
                        <br />
                        {t('text2')}
                        <br />
                        <br />
                        {t('text3')}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('Category')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={category} onChange={(event) =>{
                                setCategory(event.target.value);
                                setBikeType1('select one')
                                setBikeType2('select one')
                                setBikeTypeOptn([])
                                }} >
                                    {categoryOptn}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('BikeType')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={bikeType1} onChange={(event) =>{ 
                                    setBikeType1(event.target.value)
                                    setYear1('select one')
                                    }} 
                                    disabled={!bikeTypeOptn.length}>
                                    {bikeTypeOptn}
                                </select>
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('Year')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={year1} onChange={(event) =>{ 
                                    setYear1(event.target.value)
                                    setBrand1('select one')    
                                }} 
                                    disabled={!bikeTypeOptn.length || !yearOptn1.length}>
                                    {yearOptn1}
                                </select>
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('Brand')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={brand1} onChange={(event) => {
                                    setBrand1(event.target.value)
                                    setModel1('select one')
                                }}
                                     disabled={!brandOptn1.length}>
                                    {brandOptn1}
                                </select>
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('Model')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={model1} onChange={(event) => setModel1(event.target.value)} disabled={!modelOptn1.length} >
                                    {modelOptn1}
                                </select>
                            </div>
                        </div>
                        {bike1 ?
                        <div className="row">
                        <div className="col-md-12">
                            <div className="BikeComparisionCard-wrapper">
                                <BikeComparisionCard
                                    item={bike1}
                                    selectMtb={() => { }}
                                    traject={category}
                                    t={t}
                                    alphaRank="A"
                                />

                            </div>
                            </div>
                            </div>
                            : ""
                        }

                    </div>
                    <div className="col-md-4">
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('BikeType')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={bikeType2} onChange={(event) => {
                                    setBikeType2(event.target.value)
                                    setYear2('select one')
                                    }} 
                                    disabled={!bikeTypeOptn.length}>
                                    {bikeTypeOptn}
                                </select>
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('Year')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={year2} onChange={(event) =>{
                                     setYear2(event.target.value)
                                     setBrand2('select one')
                                    }} 
                                     disabled={!bikeTypeOptn.length || !yearOptn2.length} >
                                    {yearOptn2}
                                </select>
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('Brand')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={brand2} onChange={(event) => {
                                    setBrand2(event.target.value)
                                    setModel2('select one')
                                    }} 
                                    disabled={!brandOptn2.length} >
                                    {brandOptn2}
                                </select>
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col-lg-4">
                                <label className="form-check-label">{t('Model')}
                                </label>
                            </div>
                            <div className="col-lg-8">
                                <select className="form-control" value={model2} onChange={(event) => setModel2(event.target.value)} disabled={!modelOptn2.length}>
                                    {modelOptn2}
                                </select>
                            </div>
                        </div>
                        {bike2 ?
                        <div className="row">
                        <div className="col-md-12">
                            <div className="BikeComparisionCard-wrapper">
                                <BikeComparisionCard
                                    item={bike2}
                                    selectMtb={() => { }}
                                    traject={category}
                                    t={t}
                                    alphaRank="B"
                                />

                            </div>
                            </div>
                            </div>
                            :
                            ""}
                    </div>

                </div>
            </div>
            
            <div className="container-fluid">
                <div className="row justify-content-center gray-sec">
                    <div className="col-md-12 text-center">
                        <div className="container">

                            <h2 className="m-4">{t("graySecHeading")}</h2>
                            <p><strong>{t('graySecText1')}</strong> {t('graySecText2')}
                                <br />
                                {t('graySecText3')}
                                <Link to={Locale.getUrlForCurrentLocale('/road-frame-size')}>{t('graySecText4')}</Link>
                                {t('graySecText5')}
                                {/* <br />
                                <br />
                                <br />
                                {t('graySecText6', { price: CurrencyFormatter.format(price / 100, currency) })}
                                <br />
                                {t('graySecText7')}

                                <br />
                                {t('graySecText8')}
                                <Link to={Locale.getUrlForCurrentLocale('/bikefit')}>{t('graySecText9')}</Link> */}
                            </p>

                            {/* <div className="row">
                                {bike1 ?
                                    <div className="col-md-4">
                                        <button
                                            className={"btn btn-default  mb-4"}
                                            onClick={() => { }}
                                        >
                                            {t("StartBikefitBike")} A
                                </button>
                                    </div>
                                    : ""
                                }
                                {bike1 ?
                                    <div className="col-md-4">
                                        <button
                                            className={"btn btn-default  mb-4"}
                                            onClick={() => { }}
                                        >
                                            {t("StartBikefitBike")} B
                                </button>
                                    </div>
                                    : ""
                                }
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {bike1 || bike2 ?
                <div className="container">
                    <div className="row specifications">
                        <div className="col-md-12">
                            <div className="row justify-content-center">
                                <h2 className="m-4">{t("DetailedSpecifications")}</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="mt-4">
                                        <BikeDetailsCard
                                            item={bike1}
                                            traject={category}
                                            t={t}
                                            alphaRank="A"
                                        />

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mt-4">
                                        <BikeDetailsCard
                                            item={bike2}
                                            traject={category}
                                            t={t}
                                            alphaRank="B"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                : ""}
            <div className="container-fluid">
                <div className="row justify-content-center gray-sec2">
                    <div className="col-md-12 text-center ">
                        <div className="container">
                            <h2 className="m-4">{t("graySec2Heading")}</h2>
                            <p>{t('graySec2Text1')} </p>
                            <div className="row justify-content-center">
                                <div className="col-md-4">
                                    <Link to={Locale.getUrlForCurrentLocale('/road')}>
                                        <button
                                            className="btn btn-default  mb-4"
                                        >
                                            {t("RoadbikeBikefinder")}
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to={Locale.getUrlForCurrentLocale('/mtb')}>
                                        <button
                                            className="btn btn-default  mb-4"
                                        >
                                            {t("MountainbikeBikefinder")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default BikeComparision;

