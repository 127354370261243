// eslint-disable-next-line
export default {
    name: 'defaultLang',

    lookup(options) {
        return 'en-INT';
    },

    cacheUserLanguage(lng, options) {
    }
};
