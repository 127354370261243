import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { Trans, useTranslation } from 'react-i18next';
import Auth from "../../../lib/Auth";
import Newsletter from "../../../lib/Newsletter";
import Popup from "../../../lib/Popup";
import './style.css';

import Loader from "../../Loader";
import PrivacyPolicyLink from "../../Policy/PrivacyPolicyLink";
import TermsConditionsLink from "../../Policy/TermsConditionsLink";

const Register = ({introText, action}) => {
    const [loading, setLoading] = useState(false)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [optinData, setOptinData] = useState(Newsletter.getLocalEntry().optinData || false)
    const [optinMail, setOptinMail] = useState(Newsletter.getLocalEntry().optinMail || false)
    const [policy, setPolicy] = useState(Newsletter.getLocalEntry().policy || false)
    const [disableButton, setDisableButton] = useState(false)

    const [t, ] = useTranslation('register')

    const handleSubmit = (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert(t('Register_Password_do_not_match'))
        } else if (!policy) {
            alert(t('Register_AcceptPolicy'));
        } else {
            setDisableButton(true)
            setLoading(true)

            Auth.register(
              firstname,
              lastname,
              email,
              password,
              optinMail,
              optinData,
              policy,
              action || ''
            ).then(response => {
                ReactGA.event({
                    action: 'submit',
                    category: 'submit_registration',
                    label: email,
                    value: 10
                });

                Newsletter.setEntry({
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    optinMail: optinMail,
                    optinData: optinData,
                    policy: policy
                })

                setDisableButton(false)
                setLoading(false)

                Popup.close()
                setTimeout(
                  () => {
                      alert(t('Register_Success'));
                      window.location.reload();
                  },
                  100
                )
            }).catch(code => {
                setDisableButton(false)
                setLoading(false)

                setTimeout(
                  () => {
                      switch (code) {
                          case 1008:
                              alert(t('Register_FailureExists'));
                              break;
                          default:
                              alert(t('Register_FailureDefault'));
                      }
                  },
                  100
                )
            })
        }
    }

    return (
        <React.Fragment>
            {loading
                ? <div className="register-loader"><Loader/></div>
                : (
                        <form className="register" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="introText">{introText}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="section-head col-md-12">{t('Register_CreateAccount')}</div>
                                <div className='form-group col-md-12'>
                                    <input
                                        type='text'
                                        name='firstname'
                                        onChange={event => setFirstname(event.target.value)}
                                        className='form-control'
                                        placeholder={t('Register_Firstname')}
                                        value={firstname}
                                        required/>
                                </div>
                                <div className='form-group col-md-12'>
                                    <input
                                        type='text'
                                        name='lastname'
                                        onChange={event => setLastname(event.target.value)}
                                        className='form-control'
                                        placeholder={t('Register_Lastname')}
                                        value={lastname}
                                        required/>
                                </div>
                                <div className='form-group col-md-12'>
                                    <input
                                        type='email'
                                        name='email'
                                        onChange={event => setEmail(event.target.value)}
                                        className='form-control'
                                        placeholder={t('Register_email')}
                                        value={email}
                                        required/>
                                </div>
                                <div className='form-group col-md-12'>
                                    <input
                                        type='password'
                                        name='password'
                                        onChange={event => setPassword(event.target.value)}
                                        className='form-control'
                                        placeholder={t('Register_password')}
                                        value={password}
                                        required/>
                                </div>
                                <div className='form-group col-md-12'>
                                    <input
                                        type='password'
                                        name='confirmPassword'
                                        onChange={event => setConfirmPassword(event.target.value)}
                                        className='form-control'
                                        placeholder={t('Register_Confirmpassword')}
                                        required
                                        value={confirmPassword}
                                    />
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-check">
                                        <input type="checkbox" checked={optinMail}
                                            onChange={event => setOptinMail(event.target.value)}
                                            className="form-check-input" id="optin_mail"/>
                                        <label className="form-check-label"
                                            htmlFor="optin_mail">{t('Register_OptinMail')}</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox" checked={optinData}
                                            onChange={event => setOptinData(event.target.value)}
                                            className="form-check-input" id="optin_data"/>
                                        <label className="form-check-label"
                                            htmlFor="optin_data">{t('Register_OptinData')}</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox" checked={policy}
                                            onChange={event => setPolicy(event.target.value)}
                                            className="form-check-input" id="policy"/>
                                        <label className="form-check-label"
                                            htmlFor="policy">{t('Register_Policy')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row privacy-policy mt-2">
                                <div className="col-12">
                                    <Trans i18nKey="register:Register_LinksToPolicies">
                                        See here our <TermsConditionsLink/> and <PrivacyPolicyLink/>
                                    </Trans>
                                </div>
                            </div>
                            <div className="row mt-3 justify-content-center">
                                <div className=''>
                                    <div className='form-group text-left'>
                                        <button className="btn btn-default"
                                                onClick={Popup.close}>{t('Skip')}</button>
                                        &nbsp;
                                        <input
                                            disabled={disableButton}
                                            type='submit'
                                            name='btnSubmit'
                                            className='btn-secondary gtm-create-account'
                                            value={t('Register_button')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                )
            }
        </React.Fragment>
    );
}

export default Register;
