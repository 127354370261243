import React, {useEffect, useState} from 'react';
import './style.css';
import MoreInfoApi from "../../lib/MoreInfo";

const MoreInfo = () => {

    // const [isShown, setIsShown] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        MoreInfoApi.registerMoreInfoOpenListener(show);
        MoreInfoApi.registerMoreInfoCloseListener(close);
    });

    const show = (text) => {
        setText(text);
        setIsShown(true);
        document.body.classList.add('noscroll');
    };

    const close = () => {
        setIsShown(false);
        document.body.classList.remove('noscroll');
    };

    return (
        <div className={isShown ? 'show' : ''}>
            <div className="info-overlay"></div>
            <div className="more-info">
                <i onClick={close} className="closeInfo fas fa-times"></i>
                <div className="more-info-container" dangerouslySetInnerHTML={{__html: text}}></div>
            </div>
        </div>
    );
};

export default MoreInfo;