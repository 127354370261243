import { withTranslation } from 'react-i18next';
import React, {Component} from 'react';
import Footer from '../../../components/Footer';
import StartAdvice from '../../../components/StartAdvice';

class InfoMeasureDefinitions extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="main">
                <div className="info-page">
                    <StartAdvice t={this.props.t}/>
                    <div className="container">
                        <h1>{this.props.t('Fiets-maatdefinities')}</h1>

                        <p>{this.props.t('Framefit geeft advies over de optimale maten voor jouw nieuwe mountainbike. Het is hierbij')}
                            {this.props.t('essentieel dat jij bij het opmeten van jouw huidige fiets met dezelfde maatdefinities werkt als')}
                            {this.props.t('Framefit. Zie de onderstaande afbeelding en uitleg hierover')}.</p>

                        <img src="/img/mtb_maten.png" className="img-responsive" alt="MTB Maten"/>

                        <div className="row">
                            <div className="col-3">
                                {this.props.t('A. Zadelhoogte')}
                            </div>
                            <div className="col-9">
                                {this.props.t('de afstand van het centrum van de trapas tot de bovenkant van het zadel in het verlengde van je zadelbuis')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                {this.props.t('B. Zadel-setback')}
                            </div>
                            <div className="col-9">
                                {this.props.t('de horizontale afstand tussen de trapas en de voorkant van je zadel')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                {this.props.t('C. Zitlengte')}
                            </div>
                            <div className="col-9">
                                {this.props.t('	de horizontale afstand tussen de voorkant van je zadel en het midden van het stuur')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                {this.props.t('D. Zadel-grond')}
                            </div>
                            <div className="col-9">
                                {this.props.t('de verticale hoogte van de grond tot de bovenkant van je zadel')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                {this.props.t('E. Stuur-grond')}
                            </div>
                            <div className="col-9">
                                {this.props.t('de verticale hoogte van de grond tot de bovenkant van een handvat. Let er hierbij op dat je stuur recht / horizontaal staat')}
                            </div>

                        </div>

                        <p><strong>{this.props.t('Meettips')}</strong></p>

                        <p>{this.props.t('Als je de maten van je fiets opneemt, zorg er dan voor dat je fiets op een horizontale')}
                            {this.props.t('ondergrond staat')}.</p>

                        <ul>
                            <li>{this.props.t('De ‘zadelhoogte’, ‘zitlengte’, ‘zadel-grond’ en ‘stuur-grond’ maten kun je eenvoudig direct met een centimeter opmeten')}.</li>
                            <li>{this.props.t('De ‘setback’ kun je opmeten door een touwtje met een klein gewichtje aan het einde vanaf de punt van je zadel naar beneden te laten hangen. Meet nu de horizontaleafstand tussen het touwtje en de trapas')}.</li>
                        </ul>

                    </div>

                    <Footer t={this.props.t}/>

                </div>
            </div>
        );
    }
}

export default withTranslation('infoMeasureDefinitions')(InfoMeasureDefinitions);
