import ReactGA from "react-ga4";
import {config} from "../../../config";

export const updateGoogleConsent = () => {
  if (cookieStorage.isAnswered()) {

    const consentData = {
      ad_storage: cookieStorage.getItem("stats") ? 'granted' : 'denied',
      analytics_storage: cookieStorage.getItem("stats") ? 'granted' : 'denied',
      ad_user_data: cookieStorage.getItem("marketing") ? 'granted' : 'denied',
      ad_personalization: cookieStorage.getItem("marketing") ? 'granted' : 'denied',
    };

    if (config.trackGoogleAds) {
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('consent', 'update', consentData);
    }

    if (config.trackGa) {
      ReactGA.gtag('consent', 'update', consentData);
    }
  }
}

export const cookieStorage = {
  isAnswered: () => {
    return localStorage.getItem(`cookie_required`) !== null;
  },
  getItem: (key) => {
    return localStorage.getItem(`cookie_${key}`) === 'true'
  },
  setItem: (key, value) => {
    localStorage.setItem(`cookie_${key}`, value ? "true": "false")
    updateGoogleConsent();
  },
};
