import { useEffect, useState } from "react";

const useScreenSize = (breakPoint=768) => {
  const [sm, setSm]=useState(window.innerWidth <= breakPoint)

  useEffect(()=>{
    const handleResize = () => {
      setSm(window.innerWidth <= breakPoint)
    }

    window.addEventListener('resize', handleResize)

    return ()=>{
      window.removeEventListener('resize', handleResize)
    }
  },[])

  return sm
}
export default useScreenSize;