import { config } from "../config";

class UrlParser {

    isRelative(url) {
        return (url.indexOf('http://') !== 0 && url.indexOf('https://') !== 0)
    }

    isInternal(url) {
        const knownDomains = [
            'bestbikeadvice.com',
            'www.bestbikeadvice.com',
            'framefit.nl',
            'test.framefit.nl',
            'staging.framefit.nl',
            'localhost:3000',
            'localhost'
        ];
        const whitelabeDomains = config.whitelabeDomains || [];

        const parts = this.parsePath(url)

        if (this.isRelative(url) || ! parts.hasOwnProperty('domain')) {
            return true;
        }

        return knownDomains.includes(parts['domain']) || whitelabeDomains.includes(parts['domain']);
    }

    parsePath(url) {
        if (this.isRelative(url)) {
            return { path: url };
        }

        const matches = url.match(
            /(?<baseUrl>https?:\/\/(?<host>(?<domain>([0-9a-zA-Z]+)(\.[0-9a-zA-Z]+)*(\.(?<tld>[a-z-A-Z]{2,3}))?)(:(?<port>[0-9]+))?))(?<path>(?<onlyPath>[^?]*)(\?(?<query>.*))?)/
        );

        if (matches === null) {
            return { path: url };
        }

        let pathParts = {
            path: '/'
        };

        for (const possiblePart of ['baseUrl', 'host', 'domain', 'port', 'tld', 'path', 'onlyPath', 'query']) {
            if (matches.groups[possiblePart]) {
                pathParts[possiblePart] = matches.groups[possiblePart];
            }
        }

        return pathParts;
    }

    getParameter = (parameterName) => {
        var result = null,
          tmp = [];
        window.location.search
          .substr(1)
          .split("&")
          .forEach(function (item) {
              tmp = item.split("=");
              if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
          });
        return result;
    }
}
// eslint-disable-next-line
export default new UrlParser()
