import {CHECKOUT_FINISHED, CHECKOUT_VISITED, CHOOSE_SUBSCRIPTION, SET_RETURN_URL} from '../actions/checkoutActionTypes';

const initialState = {
    checkoutSession: {},
    subscription: {},
    returnUrl: ''
};

function checkoutReducer(state = initialState, action) {
    switch (action.type) {
        case CHECKOUT_VISITED:
            const {data} = action
            return {...state, checkoutSession: data}
        case CHECKOUT_FINISHED:
            return {...state, checkoutSession: {}}
        case CHOOSE_SUBSCRIPTION:
            const newParams = {
                subscription: action.subscription,
                extensionDetails: {
                    isExtension: action.extension,
                    accountSubscriptionId: action.extendingAccountSubscriptionId
                },
            }
            return {...state, ...newParams}
        case SET_RETURN_URL:
            return {...state, ...{returnUrl: action.returnUrl}}
        default:
            return state;
    }
}

export default checkoutReducer;
