import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Footer from '../../../components/Footer';
import StartAdvice from '../../../components/StartAdvice';
import DocumentMeta from 'react-document-meta';

class InfoSuspension extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render() {
		return (
			<div className="main">
				<DocumentMeta title={this.props.t('Veringopties')} description={this.props.t('InfoSuspension-De vering van')} />
			<div className="info-page ">
				<StartAdvice t={this.props.t}/>
				<div className="container">
					<h1>{this.props.t('Veringopties')}</h1>

					<p>{this.props.t('InfoSuspension-De vering van')}.</p>
					<p>{this.props.t('InfoSuspension-In Framefit vragen')}.</p>

					<h2>{this.props.t('Rigid')}</h2>

					<p>{this.props.t('InfoSuspension-Rigid mountainbikes hebben')}:</p>

					<ul>
						<li>{this.props.t('Heel goedkope mountainbikes, die bijvoorbeeld vooral worden gebruikt op de weg')} </li>
						<li>{this.props.t('InfoSuspension-Zeer ervaren')}</li>
						<li>{this.props.t('InfoSuspension-Fat bikes')}</li>
					</ul>

					<h2>{this.props.t('Hardtail')}</h2>

					<p>{this.props.t('InfoSuspension-Een hardtail heeft')}.</p>

					<h2>{this.props.t('Fully')}</h2>

					<p>{this.props.t('InfoSuspension-Een full suspension ')}.</p>

				</div>

				<Footer t={this.props.t}/>

			</div>
			</div>
		);
	}
}

export default withTranslation('infoSuspension')(InfoSuspension);
