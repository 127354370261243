// Global Variables
let config = {
    rootUrl: process.env.REACT_APP_API_URL || 'http://localhost:5000',
    cmsApiUrl: process.env.REACT_APP_CMS_API_URL || 'http://localhost:1337',
    geoApiKey: process.env.REACT_APP_GEO_KEY || '',
    generalUrl: process.env.REACT_APP_GENERAL_URL || 'http://localhost:3000',
    mtbUrl: process.env.REACT_APP_MTB_URL || 'http://localhost:3000',
    raceUrl: process.env.REACT_APP_RACE_URL || 'http://localhost:3000',
    bikefitUrl: process.env.REACT_APP_BIKEFIT_URL || 'http://localhost:3000',
    eBikeUrl: process.env.REACT_APP_EBIKE_URL || 'http://localhost:3000',
    defaultTraject: process.env.REACT_APP_TRAJECT || 'None',
    trackGa: process.env.REACT_APP_GA || false,
    hotJar: process.env.REACT_APP_HOTJAR || false,
    trackGoogleAds: process.env.REACT_APP_GOOGLE_ADS || false,
    trackFbAds: process.env.REACT_APP_FB_ADS || false,
    trackGoogleAdsAction: process.env.REACT_APP_GOOGLE_ADS_ACTION || '',
    defaultLang: 'nl_NL',
    flagsmithHost: process.env.REACT_APP_FLAG_SMITH_HOST || 'https://flagsmith.thewiseminds.nl/api/v1/',
    flagsmithEnvironmentKey: process.env.REACT_APP_FLAG_SMITH_ENVIRONMENT_KEY || '',
    gtmContainerId: process.env.REACT_APP_GOOGLE_GTM_CONTAINER_ID || false,
    msClarityProjectId: process.env.REACT_APP_MS_CLARITY_PROJECT_ID || false,
    whitelabeDomains: process.env.REACT_APP_WHITELABEL_DOMAINS?.split(", ") || [],
    recaptchaSitekey: process.env.REACT_APP_RECAPTCHA_SITEKEY || null,
};

let getBaseUrl = () => config.defaultTraject !== 'None' ? (config.defaultTraject === 'MTB' ? config.mtbUrl : config.raceUrl) : config.generalUrl;

export { config, getBaseUrl };
