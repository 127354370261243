import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PrivacyPolicyLink from "../../components/Policy/PrivacyPolicyLink";

function CookiePreferencesPopup({preferencesCookies, acceptAllCookies}) {
  const [t,] = useTranslation("cookies");
  const [statistics,setStatistics] = useState(true)
  const [marketing,setMarketing] = useState(true)
  return (
    <div>
      <h3 className="mb-4">{t('Cookie_Preferences')}</h3>
        <p>{t('Cookie_Info')}</p>
      <div className="row mt-4 px-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="required"
                checked
                disabled
              />
              <label className="form-check-label" htmlFor="required">
                {t("Required")}
              </label>
              <p>{t('Required_Text')}</p>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                value={statistics}
                onChange={(event) => setStatistics(event.target.checked)}
                className="form-check-input"
                id="statistics"
                defaultChecked={true}
              />
              <label className="form-check-label" htmlFor="statistics">
                {t("Statistics")}
              </label>
              <p>{t('Statistics_Text')}</p>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                value={marketing}
                onChange={(event) => setMarketing(event.target.checked)}
                className="form-check-input"
                id="marketing"
                defaultChecked={true}
              />
              <label className="form-check-label" htmlFor="marketing">
                {t("Marketing")}
              </label>
              <p>{t('Marketing_Text')}</p>
            </div>
            <p className="privacy-text">{t('Privacy_Policy_Text1')} <PrivacyPolicyLink />{t('Privacy_Policy_Text2')}
            </p>
        </div>
      <div className="mt-4 mb-5 pb-3 d-flex justify-content-end flex-column flex-md-row">
        <button className="btn btn-skip btn-secondary" onClick={acceptAllCookies}>{t('Accept_All_Cookies')}</button>
        <button className="btn btn-skip btn-secondary mt-4 mt-md-0" onClick={() => preferencesCookies(statistics,marketing)}>{t('Save_Preferences')}</button>
      </div>
    </div>
  );
}

export default CookiePreferencesPopup
