import Api from "./Api";

class Auth {
  register = (firstName, lastName, email, password, optinMail, optinData, policy, action) => {
    let user = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      optin_mail: optinMail,
      optin_data: optinData,
      policy: policy,
      action: action
    };
    return new Promise((resolve, reject) => {
      Api.post('/register', user).then(response => {
        if (typeof (response.data) !== 'undefined' && response.data !== null && response.data[0] === 1000) {
          resolve();
        }

        reject(response.data[0] || null);
      }).catch(() => reject(null));
    })
  };

  confirm = (code, email) => {
    let payload = {
      confirmation_code: code,
      email: email
    };

    return new Promise((resolve, reject) => {
      Api.post('/confirm', payload).then(response => {
        if (response.data[0] === 1000) {
          resolve();
        }
        reject(response.data[0] || null);
      }).catch(() => reject(null));
    });
  };

  forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
      Api.post(
        '/reset',
        {
          email: email
        }
      ).then(response => {
        if (((((((response.data) || {}).response || {}).status || {})[0] || 0) === 1000)) {
          resolve(response.data.response.status[0]);
        }

        reject(response.data[0] || null);
      }).catch(() => reject(null));
    });
  };

  reset = (email, password, token) => {
    return new Promise((resolve, reject) => {
      Api.post(
        '/reset',
        {
          email: email,
          password: password,
          reset_token: token
        }
      ).then(response => {
        if (((((((response.data) || {}).response || {}).status || {})[0] || 0) === 1000)) {
          resolve(response.data.response.status[0]);
        }

        reject(response.data[0] || null);
      }).catch(() => reject(null));
    });
  };

  login = (email, password) => {
    return new Promise((resolve, reject) => {
      Api.post(
        '/signin',
        {
          email: email,
          password: password
        }
      ).then(response => {
        if (response.data[0].token !== undefined && response.data[0].token !== null) {
          this.setAuth(response.data[0].token, 'login');
          resolve(response.data[0].token);
        }

        reject(response.data[0] || null);
      }).catch(() => reject(null));
    });
  };

  logout = () => {
    this.clearData();
  };

  getToken = () => localStorage.getItem('token');

  getAccountType = () => localStorage.getItem('account-type') || 'token';
  setAccountType = type => localStorage.setItem('account-type', type);

  setAuth = (token, accountType) => {
    localStorage.setItem("token", token);
    localStorage.setItem('account-type', accountType);
  }

  checkLogin = () => {
    return new Promise((resolve, reject) => {
      if (this.isLoggedIn()) {
        Api.get('/auth/check').then(response => {
          const status = response?.data?.response?.status?.[0] || 0
          if (status === 1000) {
            resolve()
          } else {
            reject(status)
          }
        })
      } else {
        reject(0)
      }
    })
  }

  checkValidToken = token => {
    return new Promise((resolve, reject) => {
      Api.get(`/auth/check-token/${token}`).then(response => {
        const status = (((((response.data) || {}).response || {}).status || {})[0] || 0)
        if (status === 1000) {
          resolve()
        } else {
          reject(status)
        }
      })
    })
  }

  isLoggedIn = () => localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined;

  clearData = () => {
    localStorage.removeItem("token");
  };

  setDataSettings = (dataParams) => Api.post('/profile/settings', dataParams, {bike_cat: 'race'});

  getDataSettings = () => {
    return new Promise((resolve, reject) => {
      Api.get('/profile/settings', {bike_cat: 'race'})
        .then((response) => {
          let dataSettings = ((((response.data || {}).response || {}).data || {}).settings) || {};
          resolve(dataSettings);
        })
        .catch(() => reject())
    })
  };
}
// eslint-disable-next-line
export default new Auth();
