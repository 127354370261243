import history from "../history";
import Locale from "./Locale";
import UrlParser from "./UrlParser";

class Redirections {
    goTo(path) {
        const localizedPath = Locale.isLocalizedUrl(path) ? path : Locale.getUrlForCurrentLocale(path);

        if (UrlParser.isRelative(path)) {
            history.push(localizedPath)
        } else {
            window.location = localizedPath;
        }
    }

    replaceTo(path) {
        const localizedPath = Locale.isLocalizedUrl(path) ? path : Locale.getUrlForCurrentLocale(path);

        if (UrlParser.isRelative(path)) {
            history.replace(localizedPath);
        } else {
            window.location = localizedPath;
        }
    }
}
// eslint-disable-next-line
export default new Redirections()