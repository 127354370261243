import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import { useTranslation } from "react-i18next";
import { startBikefit } from "../../../components/Bikefit/lib/bikefit";
import ReactGA from 'react-ga4';
import "./style.css";
import CustomerCard from "../../../components/Reviews/Customer/CustomerCard";
import ExpertCard2 from "./Reviews/ExpertCard2/ExpertCard2";
import Publications from "./Reviews/Publications";
import Reviews from "../../../lib/Reviews";
import { Link, Element } from "react-scroll";
import MultiCarousel from "./Reviews/MultiCarousel";
import TrustScore from "../../Homes/BikefitHome/img/Best-bike-advice-beoordelingen.jpg";

const ReviewPage = ({ history }) => {
  const [t, i18n] = useTranslation("infoReview");

  const [scrollTop, setScrollTop] = useState(0);
  const [customerReviews, setCustomerReviews] = useState([]);
  const [expertReviews, setExpertReviews] = useState([]);

  const bikefitClicked = () => {
    ReactGA.event({
      action: "click",
      category: "bikefit_home_start",
      label: `Start bikefit from the home page`,
      value: 0,
    });
    startBikefit();
  };

  const loadReviews = () => {
    Reviews.getCustomerReviews().then((response) => {
      setCustomerReviews(
        response.data.response.data.filter((review) => review.lang)
      );
    });

    Reviews.getExpertReviews().then((response) => {
      setExpertReviews(
        response.data.response.data.filter((review) => review.lang)
      );
    });
  };

  useEffect(() => {
    loadReviews();
  }, [i18n.language]);

  useEffect(() => {
    window.addEventListener("scroll", () => setScrollTop(window.scrollY));
  }, []);

  return (
    <div className="main">
      <div
        className={
          scrollTop > 83 ? "row review-header scrolled" : "row review-header"
        }
      >
        <div
          className="col-md-3 col-lg-3 d-flex justify-content-center align-items-center"
          style={{ fontSize: "15px" }}
        >
          <button className="link__button" onClick={() => history.goBack()}>
            <i className="fas fa-caret-left"></i> {t("REVIEWS_BACK_TITLE")}
          </button>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="justify-content-center align-items-center">
            <h3 style={{ marginTop: "10px" }}>{t("REVIEWS_TITLE")}</h3>
          </div>
          <div className="d-flex flex-column flex-lg-row justify-content-center flex-wrap">
            <Link
              activeClass="active"
              to="expert"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              <button className="scrollButton">
                {t("REVIEWS_EXPERT_TITLE")}
              </button>
            </Link>
            <Link
              activeClass="active"
              to="customer"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              <button className="scrollButton">
                {" "}
                {t("REVIEWS_CUSTOMER_TITLE")}
              </button>
            </Link>
            <Link
              activeClass="active"
              to="publish"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <button className="scrollButton">
                {t("REVIEWS_PUBLICATION_TITLE")}
              </button>
            </Link>
          </div>
        </div>
        <div className="col-md-3 col-lg-3 d-flex justify-content-center align-items-center">
          <button
            onClick={bikefitClicked}
            className="btn btn-lg btn-primary float-right bikefit-button"
          >
            {t("REVIEWS_START_BIKEFIT")}
          </button>{" "}
        </div>
      </div>
      <div className="container mt-5 body-review">
        <Element name="expert" className="element">
          <div className="row">
            <div className="col-md-12 mt-4 pb-4 text-center">
              <a
                href="https://nl.trustpilot.com/review/bestbikeadvice.com"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img
                  className=""
                  style={{ width: 220 }}
                  alt="..."
                  src={TrustScore}
                />
              </a>
              <p className="mt-4">
                <i>{t("REVIEWS_TRUSTPILOT_NOTE")}</i>
              </p>
            </div>
            <div className="col-md-12 mt-4 pb-4">
              <h2 className="text-center pb-4"> {t("REVIEWS_EXPERT_TITLE")}</h2>
              <div className="d-flex justify-content-center">
                <MultiCarousel
                  reviews={expertReviews}
                  component={(props) => <ExpertCard2 {...props} />}
                />
              </div>
            </div>
          </div>
        </Element>
        <Element name="customer" className="element">
          <div className="row">
            <div className="col-md-12 pt-5 mt-4 pb-4">
              <h2 className="text-center pb-4">
                {t("REVIEWS_CUSTOMER_TITLE")}
              </h2>
              <div className="row justify-content-center">
                {customerReviews.map((review, index) => (
                  <CustomerCard key={index} review={review} />
                ))}
              </div>
            </div>
          </div>
        </Element>
        <Element name="publish" className="element">
          <div className="row">
            <div className="col-md-12 pt-5 mt-4 pb-4">
              <h2 className="text-center pb-4">
                {t("REVIEWS_PUBLICATION_TITLE")}
              </h2>
              <div className="d-flex justify-content-center">
                <Publications />
              </div>
            </div>
          </div>
        </Element>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewPage;
