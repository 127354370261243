import React, {useEffect, useState} from "react";
import DocumentMeta from "react-document-meta";
import {useTranslation} from "react-i18next";
import Api from "../../lib/Api";
import Footer from "../../components/Footer";
import Locale from "../../lib/Locale";
import {Link, useLocation} from "react-router-dom";
import MoreInfo from "../../lib/MoreInfo";
import AdviceSource from "../../components/ExtraMeasurements/AdviceSource";

import "./style.css";
import BikeMeasurements from "../../components/ExtraMeasurements/BikeMeasurements";
import {useDispatch, useSelector} from "react-redux";
import BikeSelection from "../../components/ExtraMeasurements/BikeSelection";
import MailPopup from "./MailPopup";
import Popup from "../../lib/Popup";

import WarningPopupComponent from "../../components/ExtraMeasurements/DropWarningPopup"
import {setExtraMeasurementBikeValue} from "../../redux/actions/extraMeasurements";
import PencilIcon from "./PencilIcon";

// images
const mtlbFitEn = "./img/category/mtb_bikefit_en.png";
const mtlbFitNl = "./img/category/mtb_bikefit_nl.png";
const roodFitEn = "./img/category/road_bike_bikefit_en.png";
const roadFitNl = "./img/category/road_bike_bikefit_nl.png";
const saddleImage = "./img/cockpit/saddle.png";

const CockpitAdvice = (props) => {
  const [t,] = useTranslation("cockpitAdvice");
  const location = useLocation();

  const dispatch = useDispatch()

  const category = useSelector(
      (store) => store.extraMeasurements?.bikeMeasurements?.category || "MTB"
  );

  const transCat = category === "MTB" ? "mtb" : "road";
  const saddleHeight = useSelector(
      (store) =>
          store.extraMeasurements?.bikeMeasurements?.[transCat]?.["saddleHeight"] ||
          ""
  );
  const saddleSetback = useSelector(
      (store) =>
          store.extraMeasurements?.bikeMeasurements?.[transCat]?.[
              "saddleSetback"
              ] || ""
  );
  const handlebarReach = useSelector(
      (store) =>
          store.extraMeasurements?.bikeMeasurements?.[transCat]?.["handlebarReach"] || ""
  );
  const personLength = useSelector(
      (store) =>
          store.extraMeasurements?.bikeMeasurements?.[transCat]?.["personLength"] || ""
  );
  const drop = useSelector(
      (store) =>
          store.extraMeasurements?.bikeMeasurements?.[transCat]?.["drop"] || ""
  );

  const bike = useSelector(
      (store) => store.extraMeasurements?.bikeSelection?.bike
  );

  // eslint-disable-next-line no-unused-vars
  const [calculateCockpit, setCalculateCockpit] = useState(false);

  const [zitbuis, setZitbuis] = useState("");
  const [stack, setStack] = useState("");
  const [reach, setReach] = useState("");
  const [geometryType, setGeometryType] = useState("");
  const [saddleAboveFrame, setSaddleAboveFrame] = useState("");
  const [seatPost, setSeatPost] = useState("");
  const [spacers, setSpacers] = useState("");
  const [rise, setRise] = useState("");
  const [stemAngle, setStemAngle] = useState("");
  const [stemLength, setStemLength] = useState("");
  const [fromFramemaat, setFromFramemaat] = useState(false);
  const [size, setSize] = useState("");
  const [message, setMessage] = useState("");
  const [dropNegated, setDropNegated] = useState(false)
  const [showFrameSizeInput, setShowFrameSizeInput] = useState(false);
  const [temporaryFrameSize, setTemporaryFrameSize] = useState("");
  const [advicedFrameSize, setAdvicedFrameSize] = useState("");
  const [frameSizePickMsg, setFrameSizePickMsg] = useState("");
  const [bikeSizes, setBikeSizes] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [adviceSource, setAdviceSource] = useState("");

  const selectGeometryType = (type) => {
    setGeometryType(type);
    localStorage.setItem('geometryType', type)
  }

  useEffect(() => {
    const storedGeometryType = localStorage.getItem('geometryType')
    if (storedGeometryType) {
      setGeometryType(storedGeometryType)
    }
  }, [])

  useEffect(() => {
    if (fromFramemaat && bike) {
      handleSubmit();
    }

    if (bike) {
      getBikeSizes(bike.summary.bike_id);
    }
  }, [bike]);

  const getBikeSizes = (bikeId) => {
    Api.get("/bike-info/" + bikeId + "/sizes")
        .then(response => {
          if (response.data.response.data) {
            setBikeSizes(response.data.response.data);
          }
        })
        .catch(error => console.log(error));
  }

  useEffect(() => {
    if (dropNegated) {
      afterSubmit()
      setDropNegated(false)
    }
  }, [dropNegated])

  const meta = {
    title: t("CockpitAdvies"),
    description: t("text1"),
  };

  const changeDropFromPopup = () => {
    let updatedDrop = -1 * drop;
    dispatch(setExtraMeasurementBikeValue('drop', updatedDrop));
    Popup.close();
    setDropNegated(true)
  }


  const closePopup = () => {
    Popup.close();
    setTimeout(afterSubmit, 200);
  }

  const handleSubmit = (event) => {
    setFrameSizePickMsg("");
    if (event) {
      event.preventDefault();
    }
    if (drop > 0) {
      Popup.show(<WarningPopupComponent close={closePopup} changeDrop={changeDropFromPopup}/>, true);
    } else {
      afterSubmit();
    }
    setSubmitted(!submitted);
  };

  const afterSubmit = (event) => {
    setFromFramemaat(false);
    if (event) {
      event.preventDefault();
    }

    setCalculateCockpit(true);

    if (geometryType === "1") {
      let data = {
        saddle_height: saddleHeight * 10,
        saddle_setback: saddleSetback * 10,
        bike_length: handlebarReach * 10,
        person_length: personLength * 10,
        bike_drop: drop * 10,
      };
      Api.post("measurements/cockpit/for-bike/" + bike.summary.bike_id, data)
          .then((response) => {
            let msgKey = response.data.response.data.fit_summary
                ? "COCKPIT_ADVICE" +
                response.data.response.data.fit_summary.split(":")[0]
                : "NoFitMessage";

            setSaddleAboveFrame(
                response.data.response.data.seat.saddle_above_frame
            );
            setSeatPost(response.data.response.data.seat.seatpost);
            setSpacers(response.data.response.data.cockpit.spacers);
            setRise(response.data.response.data.cockpit.bar_rise);
            setStemAngle(response.data.response.data.cockpit.stem_angle);
            setStemLength(response.data.response.data.cockpit.stem_length);
            setSize(response.data.response.data.bike_size);
            setTemporaryFrameSize(response.data.response.data.bike_size);
            setAdvicedFrameSize(response.data.response.data.bike_size);
            console.log("msgKey = " + msgKey);
            setMessage(t(msgKey));
          })
          .catch((error) => {
            alert(error);
          });
    } else {
      let data = {
        saddle_height: saddleHeight * 10,
        saddle_setback: saddleSetback * 10,
        bike_length: handlebarReach * 10,
        person_length: personLength * 10,
        bike_drop: drop * 10,
        seattube: Math.round(zitbuis),
        stack: Math.round(stack),
        reach: Math.round(reach),
        bike_cat: category,
      };
      setSize('');
      getCockPitAdviceForGoemetry(data);
    }
  };

  const getCockPitAdviceForGoemetry = (data) => {
    Api.post("/measurements/cockpit/for-geometry", data)
        .then((response) => {
          let msgKey;

          if (response.data.response.data.fit_summary) {
            msgKey = "COCKPIT_ADVICE" + response.data.response.data.fit_summary.split(":")[0]
          } else if (!response.data.response.data.cockpit.stem_length) {
            msgKey = "NoFitMessage"
          } else {
            msgKey = ''
          }

          setSaddleAboveFrame(response.data.response.data.seat.saddle_above_frame);
          setSeatPost(response.data.response.data.seat.seatpost);
          setSpacers(response.data.response.data.cockpit.spacers);
          setRise(response.data.response.data.cockpit.bar_rise);
          setStemAngle(response.data.response.data.cockpit.stem_angle);
          setStemLength(response.data.response.data.cockpit.stem_length);
          if (response.data.response.data.bike_size) {
            setSize(response.data.response.data.bike_size);
            setTemporaryFrameSize(response.data.response.data.bike_size);
            setAdvicedFrameSize(response.data.response.data.bike_size);
          }
          setMessage(t(msgKey));
        })
        .catch((error) => {
          alert(error);
        });
  }

  useEffect(() => {
    if (location.state) {
      if (location.state.category === category) {
        selectGeometryType("1")
        setFromFramemaat(true);
      }
    }
  }, [category]);

  useEffect(() => {
    if (calculateCockpit) {
      document
        .getElementById("cockpitAdvice")
        .scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      setCalculateCockpit(false)
    }
  }, [calculateCockpit]);

  let disable = !saddleHeight || !saddleSetback || !handlebarReach || !drop || ! personLength;

  if (geometryType === "2") {
    disable = disable || !zitbuis || !stack || !reach;
  } else {
    disable = disable || !bike;
  }
  const choseCockpitImage = `./img/cockpit/${category.toLowerCase()}_cockpit_${
    stemAngle < 0 ? "up" : "down"
  }.png`;

  const storeFrameSize = () => {
    let data = {
      saddle_height: saddleHeight * 10,
      saddle_setback: saddleSetback * 10,
      bike_length: handlebarReach * 10,
      person_length: personLength * 10,
      bike_drop: drop * 10,
      bike_cat: category,
    };
    setSize(temporaryFrameSize);
    bikeSizes.length > 0 && bikeSizes.forEach(bikeSize => {
      if(bikeSize.frame_size === temporaryFrameSize) {
        data.seattube = bikeSize.seattube;
        data.stack = bikeSize.stack;
        data.reach = bikeSize.reach;
      }
    });

    if(advicedFrameSize === temporaryFrameSize) {
      handleSubmit()
      setFrameSizePickMsg("");
    } else {
      getCockPitAdviceForGoemetry(data);
      setFrameSizePickMsg(t('frameSizePickManually'));
    }
    setShowFrameSizeInput(false);
  }

  const printPage = () => {
    window.print();
  };

  const showMailPopup = () => {
    Popup.show(<MailPopup
                  adviceSource={adviceSource}
                  manualPick={frameSizePickMsg ? true : false}
                  geometryType={geometryType}
                  zitbuis={zitbuis}
                  stack={stack}
                  reach={reach}
                  />, true);
  };

  const FrameSize = () => {
    return(
      <>
        {showFrameSizeInput ?
          <>
            <select className="form-control manualFrameSize" value={temporaryFrameSize} onChange={(event) => setTemporaryFrameSize(event.target.value)}>
              { bikeSizes.length > 0 && bikeSizes.map((bikeSize, index) => (
                <option key={index} value={bikeSize.frame_size}>{bikeSize.frame_size}</option>
              ))}
            </select>
            <div className="frameSizeButtons no-print">

              <span className="btn btn-secondary" onClick={() => storeFrameSize()}>{t('save')}</span>
              <span id="frameSizeReset" className="btn btn-secondary" onClick={() => setShowFrameSizeInput(false)}>{t('cancel')}</span>
            </div>
          </>
        :  
          <>
            <span className="frame-size">{size}</span>
            <span className="pencilIcon no-print" onClick={(e) => setShowFrameSizeInput(true) }><PencilIcon width="15" color="black" marginTop="-80" /></span>
            <p className="frameSizeMessage">{frameSizePickMsg}</p>
          </>
        }
      </>
    )
  }
  
  return (
    <div className={"cockpitAdvice bike-comparision " + Locale.getLanguage()}>
      <DocumentMeta {...meta} />
      <div className="container adviceTitleSection">
        {size &&
          <div className="row justify-content-end pt-3">
            <button
              id="printPageButton"
              className="btn btn-secondary mt-2 mb-3 float-md-right"
              onClick={showMailPopup}
            >
              {t("MAIL")}
            </button>
            <button
              id="printPageButton"
              className="btn btn-secondary mt-2 mb-3 ml-2 float-md-right"
              onClick={printPage}
            >
              {t("PRINT")}
            </button>
          </div>
        }
        <div className="row">
          <div className="col-12 pdf-header text-center">
            <img className="black-logo" alt="black logo" src="img/logo.png" />
          </div>
        </div>
        <div className="row print-only cockpitAdvicePrintTitle">
          <h1 className="m-0 text-center">{t("cockpitAdvicePrintTitle")}</h1>
        </div>
        <div className="row justify-content-center no-print">
          <h1 className="m-4">{t("CockpitAdvies")}</h1>
        </div>
        <div className="row no-print">
          <div className="col-md-12 text-center">
            {t("text1")}
            <br/>
            <br/>
            {t("text2")}
            <Link
              to={Locale.getUrlForCurrentLocale("/bikefit/")}
              className="text-red"
            >
              {t("text2red")}
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="print-only">
          <h3>{t("yourInputs")}</h3>
        </div>
      </div>
      <div className="container step-section">
        <form className="cockpit-advice-form" onSubmit={handleSubmit}>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 cockpit-left">
                <div className="row cockpit-row">
                  <div className="col-md-12">
                    <div className="row hrLines">
                      <div className="col-xl-6 heading-hr">
                        <img
                          src="/img/home/hrline.jpg"
                          alt="line"
                        />
                      </div>
                      <div className="col-xl-6 heading">
                        <h2>{t("optimalBikefitHeading")}</h2>
                      </div>
                    </div>
                  </div>
                  <BikeMeasurements/>
                  <div className="col-md-12 pt-3 no-print">
                    <div className="row m-2">
                      <div className="col-lg-12">
                        <p>
                          <strong>{t("bikeFitpara1")}</strong>
                          <span
                            className="link moreInfo"
                            onClick={() => {
                              MoreInfo.show("info", category === "Race" ? "personalfit-road" : "personalfit");
                            }}
                          >
                            {" "}{t("Meer informatie")}
                          </span>
                        </p>
                        <div className="img-box pb-3">
                          {category === "Race" ? (
                            <img
                              alt={t("Racebike")}
                              className="img-fluid cursorPointer"
                              onClick={() => {
                                MoreInfo.show("info", "personalfit-road");
                              }}
                              src={
                                Locale.getLanguage() === "nl"
                                  ? roadFitNl
                                  : roodFitEn
                              }
                            />
                          ) : category === "MTB" ? (
                            <img
                              alt={t("Mountainbike")}
                              className="img-fluid cursorPointer"
                              onClick={() => {
                                MoreInfo.show("info", "personalfit");
                              }}
                              src={
                                Locale.getLanguage() === "nl"
                                  ? mtlbFitNl
                                  : mtlbFitEn
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <br/>
                        <br/>
                        <p className="mb-0">
                          <strong>{t("bikeFitpara2")}</strong>
                        </p>
                        <p>{t("bikeFitpara3")}</p>
                        <p className="text-center">
                          <Link
                            className="btn btn-primary btn-lg"
                            to={Locale.getUrlForCurrentLocale("/bikefit/")}
                            role="button"
                          >
                            {t("startBikefit")}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 cockpit-right">
                <div className="row hrLines">
                  <div className="col-xl-6 heading">
                    <h2>{t("bikeGeometrySizeHeading")}</h2>
                  </div>
                  <div className="col-xl-6">
                    <img
                      src="/img/home/hrline.jpg"
                      alt="line"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row m-2">
                      <div className="col-md-5">
                        <label className="form-check-label">
                          {t("source")}
                        </label>
                      </div>
                      <div className="col-md-7">
                        <div className="row">
                          <div className="col-md-11">
                            <select
                              className="form-control"
                              value={geometryType}
                              onChange={(e) => selectGeometryType(e.target.value)}
                            >
                              <option value="">{t("select")}</option>
                              <option value="1">{t("tab1")}</option>
                              <option value="2"> {t("tab2")}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    {geometryType === "" ? (
                      ""
                    ) : geometryType === "1" ? (
                      <div id="tab-one">
                        <BikeSelection geometryType={geometryType}/>
                        <div className="col-12 pt-5">
                          <div className="row m-2">
                            <div className="col-md-12">
                              <div className="calculateCockpitBtn">
                                <input
                                  id="calculateCockpit"
                                  className="btn btn-secondary"
                                  type="submit"
                                  value={t("calculateCockpit")}
                                  disabled={disable}
                                />
                              </div>
                            </div>

                            {bike &&
                              <div className="col-md-12 no-print">
                                <div className="row m-2">
                                  <div className="col-lg-12">
                                    <div className="top-wrap">
                                      <h3 className="text-center">{bike.bike_model.brand + " " + bike.bike_model.model}</h3>
                                      <img
                                        className="img-responsive mx-auto d-flex"
                                        src={"/img/bikes/" + bike.summary.image}
                                        alt={
                                          "Afbeelding " +
                                          bike.bike_model.brand +
                                          " " +
                                          bike.bike_model.model
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div id="tab-two">
                        <div className="col-md-12">
                          <div className="row m-2">
                            <div className="col-md-4">
                              <label className="form-check-label">
                                {t("zitbuis")}
                              </label>
                            </div>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-11">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={zitbuis}
                                    required="required"
                                    onChange={(event) => {
                                    setZitbuis(event.target.value);
                                    }}
                                    min="200"
                                    max="800"
                                    step=".01"
                                  />
                                </div>
                                <div className="col-1 after-select pl-0">
                                  {t("mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row m-2">
                            <div className="col-md-4">
                              <label className="form-check-label">
                                {t("stack")}
                              </label>
                            </div>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-11">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={stack}
                                    required="required"
                                    onChange={(event) => {
                                      setStack(event.target.value);
                                    }}
                                    min="200"
                                    max="800"
                                    step=".01"
                                  />
                                </div>
                                <div className="col-1 after-select pl-0">
                                  {t("mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row m-2">
                            <div className="col-md-4">
                              <label className="form-check-label">
                                {t("reach")}
                              </label>
                            </div>
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-11">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={reach}
                                    required="required"
                                    onChange={(event) => {
                                      setReach(event.target.value);
                                    }}
                                    min="200"
                                    max="800"
                                    step=".01"
                                    pattern=""
                                  />
                                </div>
                                <div className="col-1 after-select pl-0">
                                  {t("mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 pt-5 pb-5">
                          <div className="row m-2">
                            <div className="col-md-12">
                              <div className="calculateCockpitBtn">
                                <input
                                  id="calculateCockpit"
                                  className="btn btn-secondary"
                                  type="submit"
                                  value={t("calculateCockpit")}
                                  disabled={disable}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 pt-5 no-print">
                              <p>{t("geometryText")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="container" id="cockpitAdvice">
        {(!!stemLength || message || size) && (
        <div className="col-md-12 no-print">
          <div className="row">
            <div className="col-12 pl-0">
              <h2 className="mt-4 mb-3">{t("frameSizeTitle")}</h2>
            </div>
          </div>
        </div>
        )}
        {(message || size) && (
            <>
              {size && (
                <>
                  <div className="print-only">
                    <h3>{t('cockpitAdviceResultPrintTitle')}</h3>
                  </div>
                  <div className="col-12 pl-0 pr-2">
                    <AdviceSource 
                      adviceSource={adviceSource}
                      setAdviceSource={setAdviceSource}
                      category={category}
                      saddleHeight={saddleHeight}
                      saddleSetback={saddleSetback}
                      handlebarReach={handlebarReach}
                      personLength={personLength}
                      drop={drop}
                      submitted={submitted}
                      adviceType="cockpitAdvice"
                    />
                  </div>
                  <div className="col-12 frameSizeSection">
                    <div className="row">
                      <div className="col-md-6 frameSizeTitle">
                          <h3>{t('frameSize')}</h3>
                      </div>
                      <div className="col-md-6 print-only">
                        <FrameSize />
                      </div>
                    </div>
                    <div className="row no-print">
                      <div className="col-md-6">
                        <p className="pr-5">
                          {t("unexpectedFrameSizeInfo_1")}
                          {" "}
                          <Link to={Locale.getUrlForCurrentLocale("/road-frame-size/")} className="link moreInfo">{t('WhatFrameSizeDoINeed')}</Link>
                          
                          {" "}
                          {t("unexpectedFrameSizeInfo_2")}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <FrameSize />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {message && (
                <div className="col-12 d-flex justify-content-center text-red pb-5 insecure-fit">
                  {message}
                </div>
              )}
            </>
        )}
        {!!stemLength && (
          <div className="col-md-12 recommendedCockpitSize">
            <div className="row">
              <h3 className="mt-4">{t("recommendedCockpitSize")}</h3>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img
                  alt={t("BikefitHome_BikeFitAdviceExample")}
                  className="img-fluid"
                  src={choseCockpitImage}
                />
              </div>
              <div className="col-md-6">
                <table className="cockpit-text">
                  <tbody>
                  <tr>
                    <td>a. {t("stemLength")}:</td>
                    <td className="text-right">{stemLength + " mm"}</td>
                  </tr>
                  <tr>
                    <td>b. {t("stemAngle")}:</td>
                    <td className="text-right">
                      {"" +
                      Math.abs(stemAngle) +
                      (stemAngle < 0
                        ? t("angleUpward")
                        : t("angleDownward"))}
                    </td>
                  </tr>
                  <tr>
                    <td>c. {t("Spacers")}:</td>
                    <td className="text-right">{spacers + " mm"}</td>
                  </tr>
                  {category === 'MTB' && (
                  <tr>
                    <td>d. {t("Rise")}:</td>
                    <td className="text-right">{rise + " mm"}</td>
                  </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="row">
                        <div className="text-red">{t("moreInformation")}</div>
                    </div> */}
          </div>
        )}
        {!!stemLength && (
          <div className="col-md-12 seatPostLength">
            <div className="row">
              <h3 className="mt-4">{t("seatpostLength")}</h3>
            </div>
            <div className="row">
              <div className="col-md-6">
                <img
                  alt={t("BikefitHome_BikeFitAdviceExample")}
                  className="img-fluid"
                  src={
                    Locale.getLanguage() === "nl" ? saddleImage : saddleImage
                  }
                />
              </div>
              <div className="col-md-6">
                <table className="seatpost-text">
                  <tbody>
                  <tr>
                    <td>A. {t("sandleAboveFrame")}:</td>
                    <td className="text-right">{saddleAboveFrame + " mm"}</td>
                  </tr>
                  <tr>
                    <td>B. {t("minSeatLength")}:</td>
                    <td className="text-right">{seatPost + " mm"}</td>
                  </tr>
                  </tbody>
                </table>
                {/* {stemLength === 0 && <div className="error-message">{t('NoFitMessage')}</div>} */}
                {t("seatPostText")}
              </div>
            </div>
          </div>
        )}
      </div>
      {/*<div className="step-section text-center pb-2">
                <div className="container ">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4 className="pt-4">{t("footerHeading")}</h4>
                            <div className="text-red pt-3 pb-5">{t("footerText")}</div>
                        </div>
                    </div>
                </div>
                            </div>*/}
      <Footer/>
    </div>
  );
};
export default CockpitAdvice;
