import { useTranslation } from "react-i18next";
import React from "react";
import Stars from "../../components/Bikefinder/Result/Stars"
const BikeDetailsCard = ({ item, traject, alphaRank }) => {
const [t] = useTranslation("bikeComparision");
  return (
    <div
      key={alphaRank+"-details"}
      id={alphaRank+"-details"}
      className={"detail-card optionr option-" + 1}
    >
      {
        item?
        <div className="option-inner text-center">
          <div className="option-number">{alphaRank}</div>
        
        <div className="bottom-wrap">
          <div className="info rank gear">
            <h4><strong>{t("Schakelsysteem")}</strong> </h4>
            {item.summary.drive_chain !== null ? (
              <Stars number={Math.round(item.summary.drive_chain / 10)} />
            ) : (
              <p>{t("Result_NotApplicable")}</p>
            )}
            <strong>{t('MTBSummary_BackDeRailer')}:</strong>
            <p className="wrap-2">{item.bike_model.rear_derailleur_source ? item.bike_model.rear_derailleur_source : t("Result_NotAvailable")}</p>
            <strong>{t('MTBSummary_FrontDeRailer')}:</strong>
            <p className="wrap-2">{item.bike_model.front_derailleur_source ? item.bike_model.front_derailleur_source : t("Result_NotApplicable")}</p>
            <strong>{t('MTBSummary_GearHandles')}:</strong>
            <p className="wrap-2">{item.bike_model.shifters_source ? item.bike_model.shifters_source : t("Result_NotApplicable")}</p>
            <strong>{t('MTBSummary_Cassette')}:</strong>
            <p className="wrap-2">{item.bike_model.cassette_source ? item.bike_model.cassette_source : t("Result_NotApplicable")}</p>
            <strong>{t('MTBSummary_Crankshaft')}:</strong>
            <p className="wrap-2">{item.bike_model.crank_source ? item.bike_model.crank_source : t("Result_NotAvailable")}</p>
            <hr/>
          </div>
          {traject === 'MTB' && (  
          <div className="info rank suspension">
          <h4><strong>{t("Vering")}</strong></h4>
            {item.summary.suspension !== null ? (
              <Stars number={Math.round(item.summary.suspension / 10)} />
            ) : (
              <p>{t("Result_NotApplicable")}</p>
            )}
            <strong>{t('MTBSummary_FrontFork')}:</strong>
            <p className="wrap-2">{item.bike_model.fork_source ? item.bike_model.fork_source : t("Result_NotAvailable")}</p>
            <strong>{t('MTBSummary_BackDampers')}:</strong>
            <p className="wrap-2">{item.bike_model.shock_source ? item.bike_model.shock_source : t("Result_NotApplicable")}</p>
            <hr/>
          </div>
          )}
          <div className="info rank brake">
          <h4><strong>{t("Remsysteem")}</strong></h4>
            {item.summary.brakes !== null ? (
              <Stars number={Math.round(item.summary.brakes / 10)} />
            ) : (
              <p>{t("Result_NotApplicable")}</p>
            )}
            <strong>{t('Remsysteem')}:</strong>
            <p className="wrap-2">{item.bike_model.brakes_source ? item.bike_model.brakes_source : t("Result_NotAvailable")}</p>
            <hr/>
          </div>
          {traject === 'Race' &&(
          <div className="info rank wheel">
          <h4><strong>{t("Wielset")}</strong></h4>
            {item.summary.wheels !== null ? (
              <Stars number={Math.round(item.summary.wheels / 10)} />
            ) : (
              <p>{t("Result_NotApplicable")}</p>
            )}
            <strong>{t('Wielset')}:</strong>
            <p className="wrap-2">{item.bike_model.wheel_source ? item.bike_model.wheel_source : t("Result_NotAvailable")}</p>
            <hr/>
          </div>
          )}
          <div className="info rank frame">
            <h4><strong>{t("Frame")}</strong></h4>
            {item.summary.frame !== null ? (
              <Stars number={Math.round(item.summary.frame / 10)} />
            ) : (
              <p>{t("Result_NotApplicable")}</p>
            )}
            <strong>{t('MTBSummary_FrameMaterial')}:</strong>
            <p className="wrap-3">{item.bike_model.frame_material_source ? item.bike_model.frame_material_source : t("Result_NotAvailable")}</p>
          </div>
        </div>
      </div>
      :
      ""
      }
      
    </div>
  );
};

export default BikeDetailsCard;
