import { useEffect, useState } from 'react';
import { useOpenFeature } from '../open-feature/OpenFeatureContext';

const useFeatureFlag = (flagName) => {
  const { openFeatureClient } = useOpenFeature();
  const [feature, setFeature] = useState(false)

  const loadFeatureValue = async () => {
    const featureValue = await openFeatureClient.getBooleanValue(flagName, false);
    setFeature(featureValue);
  }

  useEffect(() => {
    if (openFeatureClient) {
        loadFeatureValue();
    }
  }, [openFeatureClient]);

  return feature;
};

export default useFeatureFlag;