import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import DynamicTitleDescription from '../DynamicTitleDescription/DynamicTitleDescription';
import BikeFittingButton from '../button/BikeFittingButton';
import EmailForm from '../forms/EmailForm/EmailForm';


const FeatureSection = ({ title, styledWord, description, customCardData, badgeStyle, style, bikeFittingButton = true, onDescriptionClick = null }) => {
  const defaultStyle = {
    backgroundColor: '#585858',
    color: 'white',
    ...style
  }
  return (
    <div className='text-center py-3 py-sm-3 py-md-4 py-xl-5' style={defaultStyle}>
      <div className='container'>

        <DynamicTitleDescription title={title} styledWord={styledWord} color="#F4DE58" description={description} onDescriptionClick={onDescriptionClick} />
      {
        customCardData &&
        <CustomCard customCardData={customCardData} badgeStyle={badgeStyle} />
      }
      {bikeFittingButton && <BikeFittingButton buttonColor="#F4DE58" />}
      {
        !bikeFittingButton && <EmailForm />
      }
      </div>
    </div>
  );
};

export default FeatureSection;

const CustomCard = ({ customCardData, badgeStyle }) => {
  return (
    <Row xs={1} md={2} lg={3} className="my-5 mx-auto d-flex justify-content-center align-content-center w-90">

      {
        customCardData?.map((customCardData, index) =>
          <Col key={index} className='d-flex justify-content-center align-content-center px-0 p-1'>
            <Card className='position-relative' >
              <div className='badge' style={badgeStyle}>
                {
                  customCardData.icon ?
                    <img src={customCardData.icon} alt="" /> : <span className='icon-font'>{index + 1}</span>
                }
              </div>
              <Card.Body className='text-left mt-5'>
                <h3 className='h3'>{customCardData.title}</h3>
                <p className='text-l'>{customCardData.description}</p>
              </Card.Body>
            </Card>
          </Col>
        )}
    </Row>
  )
}