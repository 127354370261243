import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import Footer from '../../components/Footer';
import {
    Link
} from 'react-router-dom';
import Locale from "../../lib/Locale";

const Faq = () => {

	const [t, ] = useTranslation('faq')

	const [openFaqs, setOpenFaqs] = useState([])

	const faqClicked = number => {
		if (openFaqs.includes(number)) {
			console.log(openFaqs.filter(nr => nr !== number))
			setOpenFaqs(openFaqs.filter(nr => nr !== number))
		} else {
			console.log(openFaqs.concat([number]))
			setOpenFaqs(openFaqs.concat([number]))
		}
	}

    return (
        <div className="info-page">
            <div className="container main">
                <h2>{t('FAQ_MostAsked')}</h2>

                <div className="question-container">
                    <p onClick={() => faqClicked(1)} className="question">{t('FAQ_Independent')}</p>
                    <p className={'answer ' + (openFaqs.includes(1) ? 'show': '') }>{t('FAQ_QuestionOne')}.</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(2)} className="question">{t('FAQ_QuestionTwo')}</p>
                    <p className={'answer ' + (openFaqs.includes(2) ? 'show': '') }>{t('FAQ_QuestionTwoAnswer')}.</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(3)} className="question">{t('FAQ_QuestionThree')}</p>
                    <p className={'answer ' + (openFaqs.includes(3) ? 'show': '') }>{t('FAQ_QuestionThreeAnswer')}</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(4)} className="question">{t('FAQ_QuestionFour')}</p>
                    <p className={'answer ' + (openFaqs.includes(4) ? 'show': '') }>{t('FAQ_QuestionFourAnswer')}</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(5)} className="question">{t('FAQ_QuestionFive')}</p>
                    <p className={'answer ' + (openFaqs.includes(5) ? 'show': '') }>{t('FAQ_QuestionFiveAnswer')}</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(6)} className="question">{t('FAQ_QuestionSix')}</p>
                    <p className={'answer ' + (openFaqs.includes(6) ? 'show': '') }>{t('FAQ_QuestionSixAnswer')}</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(7)} className="question">{t('FAQ_QuestionSeven')}</p>
                    <p className={'answer ' + (openFaqs.includes(7) ? 'show': '') }>{t('FAQ_QuestionSevenAnswer')}.</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(8)} className="question">{t('FAQ_QuestionEight')}</p>
					<p className={'answer ' + (openFaqs.includes(8) ? 'show': '') }>{t('FAQ_QuestionEightAnswer')}
						<Link className="link" to={Locale.getUrlForCurrentLocale("/info/fine-tune")}>{t('FAQ_QuestionEightAnswerBikeTuning')}.</Link>
						{t('FAQ_QuestionEightAnswerTwo')}.
					</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(9)} className="question">{t('FAQ_QuestionNine')}</p>
                    <p className={'answer ' + (openFaqs.includes(9) ? 'show': '') }>{t('FAQ_QuestionNineAnswer')}</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(10)} className="question">{t('FAQ_QuestionTen')}</p>
                    <p className={'answer ' + (openFaqs.includes(10) ? 'show': '') }>{t('FAQ_QuestionTenAnswer')}.</p>
                </div>

                <div className="question-container">
                    <p onClick={() => faqClicked(11)} className="question">{t('FAQ_QuestionEleven')}</p>
                    <p className={'answer ' + (openFaqs.includes(11) ? 'show': '') }>{t('FAQ_QuestionElevenAnswer')}</p>
                </div>

            </div>

            <Footer t={t}/>

        </div>
    );
}

export default Faq;

