import React from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import { useTranslation } from "react-i18next";
import Locale from "../../../../lib/Locale";
import { Link } from 'react-router-dom';

const OurToolsCarousel = ({ onBikefitResultsClicked }) => {
  const [t] = useTranslation('home');

  const settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };

  return (
    <div className="home-our-tools-carousel">
      <div className="services-left">
        <div className="services-left-heading">
          <div className="left-heading-content"><img src="/img/home/search.jpg" alt="search" /><h4>{t('CompanyHome_BikeFinder')}</h4></div>
          <div className="left-heading-line"><img src="/img/home/hrline.jpg" alt="line" /></div>
          <div className="left-heading-subheading mt-3"><h5>{t('CompanyHome_findyourbike')}</h5><br /></div>
        </div>
        <div className="modern-review-slider">
          <Slider {...settings}>
            <div className="service service-1">
              <div className="black-overlay"></div>
              <div className="service-btn mb-4"><Link to={Locale.getUrlForCurrentLocale("/road")} type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</Link></div>
              <div className="service-content"><h5>{t('CompanyHome_RoadBike')}</h5>
                <hr className="serviceshr" />
                <p>{t('CompanyHome_FindRoadBike_Desc')}</p>
              </div>
            </div>
            <div className="service service-2">
              <div className="black-overlay"></div>
              <div className="service-btn mb-4"><Link to={Locale.getUrlForCurrentLocale("/mtb")} type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</Link></div>
              <div className="service-content"><h5>{t('CompanyHome_MountainBike')}</h5>
                <hr className="serviceshr" />
                <p>{t('CompanyHome_FindMountainBike_Desc')}</p>
              </div>
            </div>
            <div className="service service-3">
              <div className="black-overlay"></div>
              <div className="service-btn mb-4"><Link to={Locale.getUrlForCurrentLocale("/ebike")} type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</Link></div>
              <div className="service-content"><h5>{t('CompanyHome_EBike')}</h5>
                <hr className="serviceshr" />
                <p>{t('CompanyHome_What_EBike_Specs')}</p>
              </div>
            </div>

            <div className="service service-4">
              <div className="black-overlay"></div>
              <div className="service-btn mb-4"><Link to={Locale.getUrlForCurrentLocale("/compare-bikes")} type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</Link></div>
              <div className="service-content"><h5>{t('CompanyHome_BikeComparison')}</h5>
                <hr className="serviceshr" />
                <p>{t('CompanyHome_Results')}</p>
              </div>
            </div>


          </Slider>
        </div >
      </div >
      <div className="services-right mt-5">
        <div className="services-left-heading">
          <div className="right-heading-content"><img src="/img/home/ruler.jpg" alt="ruler" /><h4>{t('CompanyHome_BikeFit')}</h4></div>
          <div className="right-heading-line"><img src="/img/home/hrline.jpg" alt="line" /></div>
          <div className="right-heading-subheading mt-3"><h5>{t('CompanyHome_BikeFit_Title')}</h5></div>
        </div>
        <div className="modern-review-slider">
          <Slider {...settings}>
            <div className="service service-5">
              <div className="black-overlay"></div>
              <div className="service-btn mb-4"><Link to={Locale.getUrlForCurrentLocale("/bikefit")} type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</Link></div>
              <div className="service-content"><h5>{t('CompanyHome_OnlineBikeFit')}</h5>
                <hr className="serviceshr" />
                <p>{t('CompanyHome_OnlineBikeFit_Desc')}</p>
              </div>
            </div>
            <div className="service service-6">
              <div className="black-overlay"></div>
              <div className="service-btn mb-4"><Link to={Locale.getUrlForCurrentLocale('/frame-advice')} type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</Link></div>
              <div className="service-content"><h5>{t('CompanyHome_FrameSizeAdvice')}</h5>
                <hr className="serviceshr" />
                <p>{t('CompanyHome_FramemaatAdvies_Desc')}</p>
              </div>
            </div>
            <div className="service service-7">
              <div className="black-overlay"></div>
              <div className="service-btn mb-4"><Link to={Locale.getUrlForCurrentLocale('/cockpit-advice')} type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</Link></div>
              <div className="service-content"><h5>{t('CompanyHome_CockpitSetup')}</h5>
                <hr className="serviceshr" />
                <p>{t('CompanyHome_CockpitAdvies_Desc')}</p>
              </div>
            </div>
            <div className="service service-8">
              <div className="black-overlay"></div>
              <div className="service-btn mb-4"><button onClick={onBikefitResultsClicked} type="button" className="btn btn-services">{t('CompanyHome_ViewMore')}</button></div>
              <div className="service-content"><h5>{t('CompanyHome_BikeFitResults')}</h5>
                <hr className="serviceshr" />
                <p>{t('CompanyHome_Results')}</p>
              </div>
            </div>

          </Slider>
        </div >
      </div >
    </div >
  )
}

export default OurToolsCarousel;
