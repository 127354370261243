import { WEHITELABEL_INFO_LOADED, WEHITELABEL_INFO_NOT_FOUND } from '../actions/whiteLabelActionTypes';

const initialState = {
    mainDomain: true, 
    whitelabelDomain: false,
    info: null
};

function whitelabelReducer(state = initialState, action) {
    switch (action.type) {
        case WEHITELABEL_INFO_LOADED:
            const {data} = action
            return {...state, mainDomain: false, whitelabelDomain: true, info: data}
        case WEHITELABEL_INFO_NOT_FOUND:
            return {...state, mainDomain: true, whitelabelDomain: false, info: null}
        default:
            return state;
    }
}

export default whitelabelReducer;
