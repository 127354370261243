import CmsApi from "./CmsApi";
import Locale from "./Locale";

class Blog {
    async getAllBlogs(search = '', type = 'default') {
        try {
            const searchQuery = search ? `&filters[title][$contains]=${search}` : '';
            const response = await CmsApi.get(
              `/api/blogs?locale=${Locale.getLanguage()}${searchQuery}&populate=*&sort=rank&filters[type]=${type}`
            );

            if (response && response.data) {
                return response.data;
            } else {
                console.error('No data returned for getAllBlogs');
                return null;
            }
        } catch (error) {
            console.error(`Error in getAllBlogs request: ${error.message}`);
            throw error;
        }
    }

    async getBlogsInCategory(categoryId, search = '', type = 'default') {
        try {
            const searchQuery = search ? `&filters[title][$contains]=${search}` : '';
            const response = await CmsApi.get(
              `/api/blogs/?locale=${Locale.getLanguage()}&filters[categories][slug][$eq]=${categoryId}${searchQuery}&populate=*&sort=rank&filters[type]=${type}`
            );

            if (response && response.data) {
                return response.data;
            } else {
                console.error('No data returned for getBlogsInCategory');
                return null;
            }
        } catch (error) {
            console.error(`Error in getBlogsInCategory request: ${error.message}`);
            throw error;
        }
    }

    async getCategories() {
        try {
            const response = await CmsApi.get(`/api/categories?locale=${Locale.getLanguage()}`);
            if (response && response) {
                return response;
            } else {
                console.error('No data returned for getCategories');
                return null;
            }
        } catch (error) {
            console.error(`Error in getCategories request: ${error.message}`);
            throw error;
        }
    }

    getBlogBySlug(slug) {
        return new Promise((resolve, reject) => {
              // ?populate[singlePageBlook][populate]=*
            const query = `?locale=${Locale.getLanguage()}&populate[content][populate]=*&populate[seo][populate]=*`
            CmsApi.get(`/api/blogs/${slug}${query}`)
                .then(response => {
                    resolve(response?.data || [])
                })
                .catch(e => reject(e))
        });
    }
}
// eslint-disable-next-line
export default new Blog();
