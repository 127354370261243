import Locale from "../lib/Locale";
import axios from "axios";
import {config} from "../config";

const redirectLocalized = () => {
    if (config.geoApiKey) {
        axios.get('https://extreme-ip-lookup.com/json/?key=' + config.geoApiKey)
          // axios.get('http://ip-api.com/json/')
          .then(response => {
              let country = response.data.countryCode;
              let lang;

              switch (country) {
                  case 'NL':
                  case 'BE':
                      lang = 'nl';
                      break;
                  case 'GB':
                  case 'US':
                  case 'CA':
                  case 'DE':
                  case 'IT':
                      lang = 'en';
                      break;
                  default:
                      country = 'INT'
                      lang = 'en';
              }

              window.location = Locale.getUrlForLocale(country, lang)
          })
          .catch(() => {
              window.location = Locale.getUrlForLocale('INT', 'en')
          })
    } else {
        window.location = Locale.getUrlForLocale('INT', 'en')
    }
}

// eslint-disable-next-line
export default {
    name: 'detectFromUrl',

    lookup(options) {
        const country = Locale.getCountryFromUrl();
        const language = Locale.getLanguageFromUrl();

        if (!country || !language) {
            redirectLocalized();
            return false;
        }

        document.documentElement.lang = language;
        return `${language}-${country}`;
    },

    cacheUserLanguage(lng, options) {
    }
};
