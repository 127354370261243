import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Footer from '../../../components/Footer';
import StartAdvice from '../../../components/StartAdvice';
import {
	Link
} from 'react-router-dom';
import Locale from "../../../lib/Locale";

class InfoPosture extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div className="main">
			<div className="info-page">
				<StartAdvice t={this.props.t}/>
				<div className="container">
					<h1>{this.props.t('Belang van de fietshouding')}</h1>

					<p>{this.props.t('InfoPosture-Het belang van de')}.</p>

					<p>{this.props.t('InfoPosture-De fietsafstelling')}:</p>


					<div className="row">
						<div className="col-3">
							{this.props.t('1. Piekvermogen')}
						</div>
						<div className="col-9">
							{this.props.t('- realiseren van jouw maximale vermogen en snelheid')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('2. Efficiëntie')}
						</div>
						<div className="col-9">
							{this.props.t('- optimaliseren voor lange ritten; minimale energie verspilling')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('3. Comfort')}
						</div>
						<div className="col-9">
							{this.props.t('- beperken van pijntjes aan nek, rug, schouders etc.')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('4. Blessurepreventie')}
						</div>
						<div className="col-9">
							{this.props.t('- voorkomen van overbelasting blessures')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('5. Bike handling')}
						</div>
						<div className="col-9">
							{this.props.t('- maximaliseren van de controle en wendbaarheid van de fiets')}
						</div>

					</div>

					<p className="margintop">{this.props.t('InfoPosture-Helaas bestaat er geen')}.</p>

					<p>{this.props.t('InfoPosture-Ook jouw persoonlijke')}. </p>

					<p>{this.props.t('InfoPosture-Op basis van jouw')}. </p>

					<Link to={Locale.getUrlForCurrentLocale("/info/framefit-fine-tune")} activeClassName="active">{this.props.t('Lees meer over het optimaal afstellen van jouw fiets')}.</Link>
				</div>

				<Footer />

			</div></div>
		);
	}
}

export default withTranslation('infoPosture')(InfoPosture);
