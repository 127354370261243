import TermsConditions_EN from './docs/TermsConditions_EN.pdf';
import TermsConditions_NL from './docs/TermsConditions_NL.pdf';
import React from 'react';
import {useTranslation} from "react-i18next";
import Locale from "../../lib/Locale";

const TermsConditionsLink = () => {
    const [t] = useTranslation()

    const lang = Locale.getLanguage();
    let termsConditionsFile = '';

    switch (lang) {
        case "nl":
            termsConditionsFile = TermsConditions_NL;
            break;
        case "en":
        default:
            termsConditionsFile = TermsConditions_EN;
    }

    return <a target="_blank" rel="noopener noreferrer" href={termsConditionsFile}>{t('TermsConditions')}</a>
}

export default TermsConditionsLink;
