import React from 'react';
import { config } from '../config';

const ImageComponent = ({ alignment, imageData }) => {
    return (
        <div style={{textAlign: alignment}}>
            <img className={"img-responsive"} src={config.cmsApiUrl+imageData.url} alt={imageData.name} />
        </div>
    );
};
export default ImageComponent;
