import React from "react";
import { useSelector } from "react-redux";
import { selectWhitelabelDomain, selectWhitelabelInfo } from "../../redux/selectors/whitelabel";

const Loader = ({ extraText }) => {
    const whiteLabelDomain = useSelector(selectWhitelabelDomain);
    const whiteLabelInfo = useSelector(selectWhitelabelInfo);

    const src = whiteLabelDomain ? whiteLabelInfo?.logo_url : "/img/framefit_1.gif"

    return (
        <div className="loader">
            <img className={`loadergif ${whiteLabelDomain ? "whitelabelLoaderImg" : ''}`} src={src} alt="Loader..." />
            {extraText && (
                <p className="loading-text">{extraText}</p>
            )}
        </div>
    )
};

export default Loader;
