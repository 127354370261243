import ReactModal from "react-modal";
import React, { Component } from "react";
import "./style.css";
import PopupApi from "../../lib/Popup";

// ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.6)';
ReactModal.defaultStyles.overlay.backgroundColor = "rgba(1, 1, 1, 1)";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: true,
      component: "",
      smallDialog: false,
    };

    PopupApi.registerPopupOpenListener(this.show);
    PopupApi.registerPopupCloseListener(this.close);
  }

  componentWillMount() {
    ReactModal.setAppElement("body");
  }
  componentDidMount() {
    // if (this.props.show) {
    //   this.setState({ isShown: true,smallDialog:true });
      
    // } else {
      this.setState({ isShown: false,smallDialog:false });
    // }
  }
  show = (component, smallDialog) => {
    this.setState({
      component: component,
      isShown: true,
      smallDialog: smallDialog,
    });
  };

  close = () => {
    this.setState({ isShown: false });
  };

  render() {
    let smallDialog = this.state.smallDialog || false;

    let style = {
      content: {
        position: "absolute",
      },
    };

    return (
      <ReactModal
        isOpen={this.state.isShown}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.close}
        className={smallDialog ? "narrow" : "wide"}
        style={style}
      >
        <div className="dialog__content" ref="content">
          <button className="btn btn-secondary btn-close" onClick={this.close}>
            x
          </button>
          {this.state.component}
        </div>
      </ReactModal>
    );
  }
}

export default Popup;
