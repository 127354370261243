import React from 'react';
import useScreenSize from '../../../../../hooks/useScreenSize';

const LightGreyContainer = ({ children, style }) => {
  const sm = useScreenSize(768)

  const defaultStye = {
    backgroundColor: '#ECECEC',
    borderRadius: '20px',
    ...style
  }
  if (sm) {
    defaultStye.borderRadius = '0px'
  }
  return (
    <div className='m-sm-0 m-md-4 m-lg-5 text-center'>
      <div className="container p-3 p-sm-3 p-md-4 p-xl-5 shadow-lg" style={defaultStye}>
        {children}
      </div>
    </div>
  );
};

export default LightGreyContainer;