import React from 'react'
import FeedbackFooter from './FeedbackFooter';
import { useTranslation, Trans } from 'react-i18next';


const Feedback4 = ({onFeedback, onFinish, onClose, discountPercentage}) => {
    const [t, ] = useTranslation("subscription");
    return (
        <div>
            <div className="mb-2">
                {t('Bedankt voor jouw feedback')}
            </div>
            <div className="mb-2">
                {t('Feedback4_Title')}
            </div>
            <div className="mb-2">
                <ul>
                    <li>
                        {t('Fiets comfortabeler')}
                    </li>
                    <li>
                        {t('Voorkom blessures')}
                    </li>
                    <li>
                        {t('Verbeter jouw prestaties')}
                    </li>
                    <li>
                        {t('Koop de juiste fiets in de juiste maat')}
                    </li>
                </ul>
            </div>
            <div className="mb-2">
            <Trans i18nKey='subscription:Feedback4_Desc' />
            </div>
            <div className="mb-2">
            <Trans i18nKey='subscription:Feedback4_Desc2' values={{discountPercentage: discountPercentage}} />
            </div>
            <FeedbackFooter onFeedback={onFeedback} onFinish={onFinish} onClose={onClose} discountPercentage={discountPercentage}/>
        </div>
    )
}

export default Feedback4;