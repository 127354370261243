import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Footer from '../../../components/Footer';
import StartAdvice from '../../../components/StartAdvice';
import {
	Link
} from 'react-router-dom';
import Locale from "../../../lib/Locale";

class InfoFramefitFineTune extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div className="main">
			<div className="info-page">
				<StartAdvice t={this.props.t}/>
				<div className="container">
					<h1>{this.props.t('Framefit-fietsafstelling')}</h1>

					<p>{this.props.t('InfoFrameFitFineTune-Framefit adviseert jou welke')}.</p>

					<p>{this.props.t('InfoFrameFitFineTune-Ben je van plan')}.</p>

					<ol>
						<li>{this.props.t('Stel als eerste de zadelhoogte goed in')}.</li>
						<li>{this.props.t('InfoFrameFitFineTune-Verschuif')}.</li>
						<li>{this.props.t('InfoFrameFitFineTune-Installeer')}.</li>
						<li>{this.props.t('Maak een paar testritjes om aan je nieuwe fiets en afstelling te wennen')}.</li>
						<li>{this.props.t('Lees de Framefit-tips om de afstelling van je fiets verder te verfijnen')}.</li>
					</ol>

					<p>{this.props.t('InfoFrameFitFineTune-NB1')}.</p>

					<p>{this.props.t('InfoFrameFitFineTune-NB2')}.</p>

					<p>{this.props.t('Framefit is niet aansprakelijk voor eventuele gevolgen van het gegeven advies')}.
					<Link to={Locale.getUrlForCurrentLocale("/info/disclaimer")} className="link">{this.props.t('Bekijk de volledige disclaimer')}</Link></p>

				</div>

				<Footer t={this.props.t}/>
				</div>
			</div>
		);
	}
}

export default withTranslation('infoFramefitFinetune')(InfoFramefitFineTune);
