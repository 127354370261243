import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import Auth from "../../lib/Auth";
import "./style.css";
import Newsletter from "../../lib/Newsletter";
import PrivacyPolicyLink from "../../components/Policy/PrivacyPolicyLink";
import {cookieStorage} from "../../components/Tracking/lib/cookieStorage";

const Cookies = () => {
  const [t] = useTranslation("cookies");

  const stats = cookieStorage.getItem("stats")
  const marketing = cookieStorage.getItem("marketing")
  const optinEntry = Newsletter.getLocalEntry()
  const [optinData, setOptinData] = useState(optinEntry.optinData);
  const [optinMail, setOptinMail] = useState(optinEntry.optinMail);

  const [openFaqs, setOpenFaqs] = useState([]);

  const dataOptinUpdated = (checked) => {
    Newsletter.setOptinData(checked)
    setOptinData(checked)
  }

  const mailOptinUpdated = (checked) => {
    Newsletter.setOptinMail(checked)
    setOptinMail(checked)
  }

  const faqClicked = (number) => {
    if (openFaqs.includes(number)) {
      setOpenFaqs(openFaqs.filter((nr) => nr !== number));
    } else {
      setOpenFaqs(openFaqs.concat([number]));
    }
  };

  const updateGoogleConsent = (name, value) => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      const consentData = {
        ad_storage: stats ? 'granted' : 'denied',
        analytics_storage: stats ? 'granted' : 'denied',
        ad_user_data: marketing ? 'granted' : 'denied',
        ad_personalization: marketing ? 'granted' : 'denied',
        wait_for_update: 500
      }
      if (name === "stats") {
        consentData.ad_storage = value ? 'granted' : 'denied';
        consentData.analytics_storage = value ? 'granted' : 'denied';
      }
      if (name === "marketing"){
        consentData.ad_user_data = value ? 'granted' : 'denied';
        consentData.ad_personalization = value ? 'granted' : 'denied';
      }
      gtag('consent', 'update', consentData);
    }
  }
  return (
    <div className="main">
      <div className="container cookie-page">
        <div className="row mt-4">
          <div className="col-12">
            <h1 className="mb-0">{t("Cookies_Title")}</h1>
          </div>
        </div>{Auth.isLoggedIn()?
        <div className="row mt-4">
          <div className="col-12 data-setting-section">
            <div className="section-head">{t("Data_Setting_Title")}</div>
            <div className="form-check">
              <input
                type="checkbox"
                checked={optinData}
                onChange={(event) => dataOptinUpdated(event.target.checked)}
                className="form-check-input"
                id="optin_data"
              />
              <label className="form-check-label" htmlFor="optin_data">
                {t("Data_Optin")}
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                checked={optinMail}
                onChange={(event) => mailOptinUpdated(event.target.checked)}
                className="form-check-input"
                id="optin_mail"
              />
              <label className="form-check-label" htmlFor="optin_mail">
                {t("Mail_Optin")}
              </label>
            </div>
          </div>
        </div>:""}
        <div className="row mt-4">
          <div className="col-12 privacy-settings-section">
            <div className="section-head">{t("Privacy_Settings_Title")}<sup>*</sup></div>
            <div className="form-check">
              <input
                type="checkbox"
                value={true}
                defaultChecked={true}
                disabled={true}
                className="form-check-input"
                id="functional_cookies"
              />
              <label className="form-check-label" htmlFor="functional_cookies">
                {t("Functional_Cookies")}
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                value={stats === 'true'}
                defaultChecked={stats === 'true'}
                onChange={(event) => {
                  cookieStorage.setItem("stats", event.target.checked)
                  updateGoogleConsent("stats", event.target.checked)
                }}
                className="form-check-input"
                id="statistical_cookies"
              />
              <label className="form-check-label" htmlFor="statistical_cookies">
                {t("Statistical_Cookies")}
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                value={marketing === 'true'}
                defaultChecked={marketing === 'true'}
                onChange={(event) => {
                  cookieStorage.setItem("marketing", event.target.checked)
                  updateGoogleConsent("marketing", event.target.checked)
                }}
                className="form-check-input"
                id="marketing_cookies"
              />
              <label className="form-check-label" htmlFor="marketing_cookies">
                {t("Marketing_Cookies")}
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="question-container">
              <p onClick={() => faqClicked(1)} className="question">
                {t("Should_We_Forget_You")}
              </p>
              <p className={"answer " + (openFaqs.includes(1) ? "show" : "")}>
                {t("QuestionOneAnswer")}.
              </p>
            </div>

            <div className="question-container">
              <p onClick={() => faqClicked(2)} className="question">
                {t("Send_You_Your_Data")}
              </p>
              <p className={"answer " + (openFaqs.includes(2) ? "show" : "")}>
                {t("QuestionTwoAnswer")}.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="privacy-text">* {t('Privacy_Policy_Text1')} <PrivacyPolicyLink />{t('Privacy_Policy_Text2')}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Cookies;
