import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Subscription from '../../lib/Subscription';
import Loader from "../Loader";
import ReactGA from 'react-ga4';
import {checkChoices} from "../Bikefit/lib/bikefit";
import {useDispatch, useSelector} from "react-redux";
import {config} from '../../config';
import Redirections from "../../lib/Redirections";
import Api from "../../lib/Api";
import Locale from "../../lib/Locale";
import {selectBikefitChoices} from "../../redux/selectors/bikefit";
import {checkoutFinished} from "../../redux/actions/checkout";

const StatusCheck = ({ orderId, extension = false }) => {
    const [t,] = useTranslation('subscription')
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [tokenAlreadyGiven, setTokenAlreadyGiven] = useState(false)
    const [token, setToken] = useState('')
    const [accountType, setAccountType] = useState('token')
    const [prevLocation, setPrevLocation] = useState('')
    const [returnUrl, setReturnUrl] = useState('/');
    const bikefitChoices = useSelector(selectBikefitChoices);

    const dispatch = useDispatch()

    useEffect(() => {
        setPrevLocation(localStorage.getItem('prevLoc'))
    })

    useEffect(() => {
        processOrderStatus()
    }, [orderId])

    const itemBought = (code, type, price, valuta) => {
        ReactGA.event('purchase', {
            'transaction_id': orderId,
            'value': price / 100,
            'currency': valuta,
            items: [{
                item_id: code,
                item_name: `Product type: ${type}, code: ${code}`,
                item_category: type,
                value: price / 100,
                currency: valuta
            }]
        });

        ReactGA.event({
            action: 'sale',
            category: code,
            label: `Sold {$code} for ${valuta} ${price}`,
            value: price
        });

        if (typeof window.gtag !== "undefined" && config.trackGoogleAds !== false) {
            window.gtag('event', 'conversion', {
                'send_to': config.trackGoogleAds + '/' + config.trackGoogleAdsAction,
                'value': price / 100,
                'currency': valuta,
                'transaction_id': orderId
            });

            console.log('recorded conversion in google tag!');
        } else {
            console.log('Did not record conversion in google tag!');
        }
    }

    const sessionId = Api.getSessionId();
    const getDataUrl = () => Locale.getUrlForCurrentLocale(`${config.bikefitUrl}/bikefit/token/token-string/${token}/account-type/${accountType}/session-id/${sessionId}`);

    const noData = ! checkChoices(bikefitChoices)

    const continueFlow = () => {
        localStorage.removeItem('prevLoc')
        const returnUrlEncoded = encodeURI(prevLocation || returnUrl)
        Redirections.goTo(`${config.bikefitUrl}/bikefit/measurments-check?isPaid=true&returnUrl=${returnUrlEncoded}`)
    }

    const processOrderStatus = () => {
        Subscription.processOrderStatus(orderId)
            .then(({success, returnUrl, code, price, type, valuta, token, needsAccount, accountHasPassword}) => {
                if (success) {
                    dispatch(checkoutFinished())
                }

                // if the user needs an account and there is no password set yet, redirect to finishAccount
                if (success && needsAccount && ! accountHasPassword) {
                    localStorage.removeItem('prevLoc')
                    const returnUrlEncoded = encodeURI(prevLocation || returnUrl)
                    Redirections.goTo(`${config.bikefitUrl}/subscription/account/finish?returnUrl=${returnUrlEncoded}`)
                }

                setSuccess(success);
                setReturnUrl(returnUrl);
                setLoading(false)

                // if not succes

                if (token) {
                    setToken(token)
                    setAccountType(needsAccount ? 'login' : 'token')
                    itemBought(code, type, price, valuta)
                }
            })
            .catch((error) => {
                if (error.code === 'TokenAlreadyGiven') {
                    setSuccess(true);
                    setTokenAlreadyGiven(true);
                } else {
                    console.log(error);
                    alert(t('Order_CheckStatusError', {order_id: orderId}));
                }

                setLoading(false)
            })
    }

    let buttonText = ( ! success) ? 'Order_Back' : 'Order_ToResults';

    if (prevLocation) {
        buttonText = 'Order_BackToLastPage'
    }

    if (loading) {
        return <Loader extraText={t('Order_WaitMessage')}/>;
    }

    return (
        <div className="info-page">
            <div className="container main">
                <div className="row">
                    <div className="col-lg-8">
                        <h1>{success ? t('Order_Success_Title') : t('Order_Failure_Title')}</h1>
                        <p>{success ? t(extension ? 'Order_Extension_Success' : 'Order_Success_Text') : t('Order_Failure_Text')}</p>
                        { ! extension && ! noData && ! tokenAlreadyGiven && (
                            <div className="text-right">
                                <button className="btn btn-secondary" onClick={continueFlow}>{t(buttonText)}</button>
                            </div>
                        )}

                        { extension && (
                          <div className="text-right">
                              <a className="btn btn-secondary" href={Locale.getUrlForCurrentLocale("/")}>{t('Order_GoHome')}</a>
                          </div>
                        )}
                    </div>
                </div>
                {/* tokenAlreadyGiven && (
                    <div className="row">
                        <div className="col-12">
                            <p id="demo-2">{t('Order_TokenAlreadyGiven')}</p>
                        </div>
                    </div>
                ) */}
                {success && noData && token !== '' && ! extension && (
                    <div className="row">
                        <div className="col-12">
                            <p>{t('Order_Success_NoData')}</p>
                            <p style={{wordBreak: "break-word"}}>{getDataUrl()}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default StatusCheck;
