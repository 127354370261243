import React, {useEffect, useState} from 'react';
import { Trans, useTranslation } from "react-i18next";
import Newsletter from '../../lib/Newsletter';
import PrivacyPolicyLink from "../Policy/PrivacyPolicyLink";
import TermsConditionsLink from "../Policy/TermsConditionsLink";
import './style.css';
import Redirections from "../../lib/Redirections";
import {config} from "../../config";
import UrlParser from "../../lib/UrlParser";
import Loader from "../Loader";
import Api from "../../lib/Api";
import Auth from "../../lib/Auth";

const FinishAccount = () => {
  const [t,] = useTranslation('subscription')

  const [returnUrl, setReturnUrl] = useState('/')
  const [prevLocation, setPrevLocation] = useState('')

  const [firstName, setFirstName] = useState(Newsletter.getLocalEntry().firstname || '')
  const [lastName, setLastName] = useState(Newsletter.getLocalEntry().lastname || '')
  const [email, setEmail] = useState(Newsletter.getLocalEntry().email || '')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  const [optinMail, setOptinMail] = useState(Newsletter.getLocalEntry().optinMail || false)
  const [optinData, setOptinData] = useState(Newsletter.getLocalEntry().optinData || false)
  const [policy, setPolicy] = useState(Newsletter.getLocalEntry().policy || false)

  const [loading, setLoading] = useState(false);

  const inputFirstName = e => setFirstName(e.target.value)
  const inputLastName = e => setLastName(e.target.value)
  const inputEmail = e => setEmail(e.target.value)
  const inputPassword = e => setPassword(e.target.value)
  const inputPasswordRepeat = e => setPasswordRepeat(e.target.value)

  const changeOptinMail = e => setOptinMail(e.target.checked)
  const changeOptinData = e => setOptinData(e.target.checked)
  const changePolicy = e => setPolicy(e.target.checked)

  useEffect(() => {
    setReturnUrl(UrlParser.getParameter('returnUrl'));
    setPrevLocation(localStorage.getItem('prevLoc'));
  }, []);

  const submit = e => {
    e.preventDefault()

    let errors = checkAccount();

    if (errors.length === 0) {
      Newsletter.setEntry({
        firstname: firstName,
        lastname: lastName,
        email: email,
        optinMail: optinMail,
        optinData: optinData,
        policy: policy
      });

      setLoading(true);

      const data = {
        oldPassword: '',
        newPassword: password,
      }

      Api.post('/account/password', data).then((response) => {
        if (response.data.response.status === 1000) {
          continueFlow()
        } else {
          alert(t('GeneralError'))
        }
      });

    } else {
      alert(errors.join("\n - "))
    }
  }

  const skip = () => {
    setLoading(true);

    Auth.forgotPassword(email).finally(() => {
      continueFlow();
    });
  }

  const continueFlow = () => {
    localStorage.removeItem('prevLoc')
    const returnUrlEncoded = encodeURI(prevLocation || returnUrl)
    Redirections.goTo(`${config.bikefitUrl}/bikefit/measurments-check?isPaid=true&returnUrl=${returnUrlEncoded}`)
  }

  const checkAccount = () => {
    let errors = []
    if ([firstName, lastName, email, password].filter(value => !value).length > 0) {
      errors = [...errors, t('Account_FillIn')]
    }

    if (password !== passwordRepeat) {
      errors = [...errors, t('Account_PasswordRepeatNoMatch')]
    } else if (!policy) {
      errors = [...errors, t('Account_AcceptPolicy')]
    }

    return errors;
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="info-page">
      <div className="container main">
        <form onSubmit={submit}>
          <div className="row">
            <div className="col-12">
              <h1>{t('Account_FinishTitle')}</h1>
              <p>{t('Account_FinishIntro')}</p>
              <div className="form-group">
                <input type="text" onChange={inputFirstName} className="form-control"
                       placeholder={t('Account_FirstName')} value={firstName} name="firstName"
                       pattern="[A-Za-z]{3,16}"
                       title="3 to 16 letters"
                       required />
              </div>
              <div className="form-group">
                <input type="text" onChange={inputLastName} className="form-control"
                       placeholder={t('Account_LastName')} pattern="[A-Za-z]{3,16}"
                       title="3 to 16 letters" value={lastName} name="lastName"
                       required/>
              </div>
              <div className="form-group">
                <input type="email" onChange={inputEmail} className="form-control"
                       placeholder={t('Account_Email')} value={email} name="email" required/>
              </div>
              <div className="form-group">
                <input type="password" onChange={inputPassword}
                       className="form-control" placeholder={t('Account_Password')}
                       value={password} name="password" required/>
              </div>
              <div className="form-group">
                <input type="password" onChange={inputPasswordRepeat}
                       className="form-control" placeholder={t('Account_PasswordRepeat')}
                       value={passwordRepeat} name="passwordRepeat" required/>
              </div>
              <div className="form-check">
                <input type="checkbox" checked={optinMail} onChange={changeOptinMail}
                       className="form-check-input" id="optin_mail"/>
                <label className="form-check-label"
                       htmlFor="optin_mail">{t('Account_OptinMail')}</label>
              </div>
              <div className="form-check">
                <input type="checkbox" checked={optinData} onChange={changeOptinData}
                       className="form-check-input" id="optin_data"/>
                <label className="form-check-label"
                       htmlFor="optin_data">{t('Account_OptinData')}</label>
              </div>
              <div className="form-check">
                <input type="checkbox" checked={policy} onChange={changePolicy}
                       className="form-check-input" id="policy"/>
                <label className="form-check-label"
                       htmlFor="policy">{t('Account_Policy')}</label>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col mt-3 privacy-policy">
              <Trans i18nKey="subscription:Account_LinksToPolicies">
                See here our <TermsConditionsLink/> and <PrivacyPolicyLink/>
              </Trans>
            </div>
          </div>
          <div className="row mt-4 mb-5">
            <div className="col-12">
              <button className={"btn btn-secondary gtm-create-account"}>
                {t('Account_Finish')}
              </button>
              <button
                onClick={skip}
                type="button"
                id="skipbtn"
                className={"btn btn-secondary mt-2 mt-lg-0 mb-0 float-lg-right"}
              >
                {t('Account_Finish_Skip')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FinishAccount;
