import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Api from "../../lib/Api";

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [t] = useTranslation("subscription");

  const inputName = (event) => setName(event.target.value);
  const inputEmail = (event) => setEmail(event.target.value);
  const inputsubject = (event) => setSubject(event.target.value);
  const inputMessage = (event) => setMessage(event.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    Api.get("/sendcontact", {
      name,
      email,
      subject,
      message,
    }).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 2000);

      alert(t("CONTACT_FORM_Thank you message"));

      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    });
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <div className="row mt-5">
      <div className="col-md-6">
        <h1><strong>{t('CONTACT_FORM_we are here to help you')}</strong></h1>
        <p className="text-muted">{t("CONTACT_FORM_If you have any questions or want to work with us, we'd love to hear from you")}</p>
        <button className="btn btn-primary mb-5" onClick={scrollToTop}>{t('CONTACT_FORM_Buy Bike Fit')}</button>
      </div>
      <div className="col-md-6">
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                        type="text"
                        name="txtName"
                        onChange={inputName}
                        className="form-control"
                        placeholder={`${t("CONTACT_FORM_Full name")} *` }
                        value={name}
                        required
                        pattern=".*\S+.*"
                        title="This field cannot contain only spaces"
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <input
                        type="email"
                        name="txtEmail"
                        onChange={inputEmail}
                        className="form-control"
                        placeholder={`${t("CONTACT_FORM_Email address")} *`}
                        value={email}
                        required
                        pattern=".*\S+.*"
                        title="This field cannot contain only spaces"
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <input
                        type="text"
                        name="txtsubject"
                        onChange={inputsubject}
                        className="form-control"
                        placeholder={t("CONTACT_FORM_Subject")}
                        value={subject}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <textarea
                        name="txtMsg"
                        onChange={inputMessage}
                        className="form-control textfield"
                        placeholder={t("CONTACT_FORM_Your message")}
                        value={message}
                        ></textarea>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <button name="btnSubmit" className="btn btn-primary w-100" type="submit" disabled={loading ? 'disabled' : ''}>
                                <span className={`${loading && 'spinner-border spinner-border-sm'}`} role="status" aria-hidden="true"></span>
                            {t("CONTACT_FORM_Submit Request")}
                        </button>
                    </div>
                </div>
            </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
