import { withTranslation } from 'react-i18next';
import React, {Component} from 'react';
import Auth from "../../../lib/Auth";
import Popup from "../../../lib/Popup";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            disableButton: false
        };

        this.inputEmail = this.inputEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h3>{this.props.t('ForgotPassword_Title')}</h3>
                <p>{this.props.t('ForgotPassword_Info')}</p>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="email" name="txtEmail" onChange={this.inputEmail} className="form-control"
                                   placeholder={this.props.t('ForgotPassword_Mail')} value={this.state.email}/>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group text-center">
                            <input type="submit" name="btnSubmit" className="btn btn-secondary" disabled={this.state.disableButton}
                                   value={this.props.t('ForgotPassword_Button')}/>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    inputEmail(event) {
        this.setState({email: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({disableButton: true});

        Auth.forgotPassword(this.state.email)
            .then(() => {
                alert(this.props.t('ForgotPassword_Sent'));
                Popup.close();
            })
            .catch(() => {
                alert(this.props.t('ForgotPassword_Failure'));
                this.setState({disableButton: false});
            });
    }
}

export default withTranslation('forgotPassword')(ForgotPassword);