import { useTranslation, withTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import Api from "../../lib/Api";
import FormPage from "../FormPage";
import Loader from "../../components/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "../../config";
import "./style.css";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);


  const [t] = useTranslation("contact");


  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const inputName = (event) => setName(event.target.value);
  const inputEmail = (event) => setEmail(event.target.value);
  const inputPhone = (event) => setPhone(event.target.value);
  const inputMessage = (event) => setMessage(event.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    Api.get("/sendcontact", {
      name,
      email,
      phone,
      message,
    }).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 2000);

      alert(t("Bedankt voor je bericht."));

      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    });
  };

  // set captcha verfication to true
  function handleCaptchaChange(value) {
    setIsCaptchaVerified(true)
  }


  // validate message field on change, show errors and update message data to state
  const validateAndInputMessage = (event) => {
    const messageValue = event.target.value;
    if (!messageValue.trim()) {
      event.target.setCustomValidity("This field cannot contain only spaces");
      event.target.reportValidity();
    } else {
      event.target.setCustomValidity("");
      event.target.reportValidity();
    }

    inputMessage(event)
  };

  // return if inputMessage has proper value
  const validateFormOnSubmit = (event) => message.trim() !== '';

  // handle form submission
  const handleFormSubmission = (event) => {
    event.preventDefault();

    if (validateFormOnSubmit(event)) handleSubmit(event)
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (

        <React.Fragment>
          <FormPage
            title={t("Stuur ons een bericht")}
            handleSubmit={handleFormSubmission}
          >
            <div className="row contact-form">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    name="txtName"
                    onChange={inputName}
                    className="form-control"
                    placeholder={t("Jouw naam *")}
                    value={name}
                    pattern=".*\S+.*"
                    title="This field cannot contain only spaces"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="txtEmail"
                    onChange={inputEmail}
                    className="form-control"
                    placeholder={t("Jouw e-mail *")}
                    value={email}
                    required
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                    title="This field cannot contain only spaces"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="txtPhone"
                    onChange={inputPhone}
                    className="form-control"
                    placeholder={t("Jouw telefoon *")}
                    value={phone}
                    required
                    pattern=".*\S+.*"
                    title="This field cannot contain only spaces"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <textarea
                    name="txtMsg"
                    onChange={validateAndInputMessage}
                    className="form-control textfield"
                    placeholder={t("Jouw bericht *")}
                    value={message}
                    required
                    pattern=".*\S+.*"
                    title="This field cannot contain only spaces"
                  ></textarea>
                </div>
              </div>
              {config.recaptchaSitekey &&
                <div className="col-md-6">
                  <div className="form-group text-center">
                    <div className="recaptcha-parent">
                      <ReCAPTCHA
                        sitekey={config.recaptchaSitekey}
                        onChange={handleCaptchaChange}
                      />
                    </div>
                  </div>
                </div>
              }
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="submit"
                    name="btnSubmit"
                    className="btnContact gtm-submit-contact"
                    value={t("Verstuur bericht")}
                    disabled={!isCaptchaVerified}
                  />
                </div>
              </div>
            </div>
          </FormPage>
        </React.Fragment>
      )
      }
    </React.Fragment >
  );
};

export default withTranslation("contact")(Contact);
