import React, {useState} from 'react';
import Api from "../../lib/Api";
import Newsletter from "../../lib/Newsletter"
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Popup from "../../lib/Popup";

const MailPopup = ({manualPick, geometryType, zitbuis, stack, reach, adviceSource}) => {
    const [t,] = useTranslation('cockpitAdvice');

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState(Newsletter.getLocalEntry().firstname || '');
    const [email, setEmail] = useState(Newsletter.getLocalEntry().email || '');
    const [optinData, setOptinData] = useState(Newsletter.getLocalEntry().optinData || false);
    const [optinMail, setOptinMail] = useState(Newsletter.getLocalEntry().optinMail || false);
    const [policy, setPolicy] = useState(Newsletter.getLocalEntry().policy || false);
    const [error, setError] = useState('');



  const category = useSelector((store) => store.extraMeasurements?.bikeMeasurements?.category || "MTB");
  const transCat = category === "MTB" ? "mtb" : "road";
  const saddleHeight = useSelector((store) => store.extraMeasurements?.bikeMeasurements?.[transCat]?.["saddleHeight"] || "");
  const saddleSetback = useSelector((store) => store.extraMeasurements?.bikeMeasurements?.[transCat]?.["saddleSetback"] || "");
  const handlebarReach = useSelector((store) => store.extraMeasurements?.bikeMeasurements?.[transCat]?.["handlebarReach"] || "");
  const drop = useSelector((store) => store.extraMeasurements?.bikeMeasurements?.[transCat]?.["drop"] || "");
  const bike = useSelector((store) => store.extraMeasurements?.bikeSelection?.bike);
  const bikeType = useSelector(store => store.extraMeasurements?.bikeSelection?.bikeType || '')
  const year = useSelector(store => store.extraMeasurements?.bikeSelection?.year || '')
  const brand = useSelector(store => store.extraMeasurements?.bikeSelection?.brand || '')
  const model = useSelector(store => store.extraMeasurements?.bikeSelection?.model || '')

    const mailReport = (e) => {
        e.preventDefault();

        if ( ! policy) {
            setError(t('MAIL_POPUP_Policy_Error'))
            return;
        }

        let data = {
            bike_cat: category,
            saddle_height: saddleHeight * 10,
            saddle_setback: saddleSetback * 10,
            bike_length: handlebarReach * 10,
            bike_drop: drop * 10,
            manual_pick: manualPick,
            geometry_type: geometryType,
            zitbuis: zitbuis,
            stack: stack,
            reach: reach,
            bike_type: bikeType,
            year: year,
            brand: brand,
            model: model,
            email: email,
            firstname: firstName,
            advice_source: adviceSource
          };


        setLoading(true)
        Newsletter.setEntry({
            firstname: firstName,
            email: email,
            optinMail: optinMail,
            optinData: optinData,
            policy: policy
        })
        
        Api.post('/measurements/cockpit/report/for-bike/' + bike.summary.bike_id, data)
            .finally(() => {
                setLoading(false);
                Popup.close()
                alert(t('MAIL_POPUP_SENT'));
            });
    }

    const inputEmail = e => setEmail(e.target.value)
    const inputFirstName = e => setFirstName(e.target.value)
    const changeOptinMail = e => setOptinMail(e.target.checked)
    const changeOptinData = e => setOptinData(e.target.checked)
    const changePolicy = e => setPolicy(e.target.checked)

    return (
        <form onSubmit={mailReport}>
            <h3>{t('MAIL_POPUP_TITLE')}</h3>
            <div className="row">
                <div className="col-md-12">
                    {error !== '' && (
                      <div className="alert alert-danger">
                          {error}
                      </div>
                    )}
                    <div className="form-group">
                        <input type="text" onChange={inputFirstName} className="form-control"
                               placeholder={t('MAIL_POPUP_FIRSTNAME')} value={firstName}
                               required="required" />
                    </div>
                    <div className="form-group">
                        <input type="email" onChange={inputEmail} className="form-control"
                               placeholder={t('MAIL_POPUP_EMAIL')} value={email}
                               required="required" />
                    </div>
                    <div className="form-check">
                            <input type="checkbox" checked={optinMail} onChange={changeOptinMail}
                                    className="form-check-input" id="optin_mail"/>
                            <label className="form-check-label"
                                    htmlFor="optin_mail">{t('MAIL_POPUP_OptinMail')}</label>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" checked={optinData} onChange={changeOptinData}
                                className="form-check-input" id="optin_data"/>
                        <label className="form-check-label"
                                htmlFor="optin_data">{t('MAIL_POPUP_OptinData')}</label>
                    </div>
                    <div className="form-check">
                        <input type="checkbox" checked={policy} onChange={changePolicy}
                                className="form-check-input" id="policy"/>
                        <label className="form-check-label"
                                htmlFor="policy">{t('MAIL_POPUP_Policy')}</label>
                    </div>

                </div>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                    <input type="submit" name="btnSubmit" className="btn btn-secondary" disabled={loading}
                            value={t('MAIL_POPUP_SUBMIT')}/>
                </div>
                <div className="col-6 text-right">

                </div>
            </div>
        </form>
    );
}

export default MailPopup;
