class UrlParser {
    stepToId = {
        'bike-type': 'stepA',
        'sitting-position': 'stepB',
        'body-sizes': 'stepC',
        'part-sizes': 'stepD',
    };

    idToStep = {
        'stepA': 'bike-type',
        'stepB': 'sitting-position',
        'stepC': 'body-sizes',
        'stepD': 'part-sizes',
    };

    getStepConfigFromUrl(urlPath) {
        const bikefitUrlPath = urlPath.split('bikefit/')[1] || '';
        const bikefitUrlPathElements = bikefitUrlPath.split('/');
        let step, subStep;

        if (bikefitUrlPathElements.length > 0) {
            step = this.stepToId[bikefitUrlPathElements[0]];
        }

        if (bikefitUrlPathElements.length > 1 && bikefitUrlPathElements[1] !== '') {
            subStep = bikefitUrlPathElements[1];
        } else {
            subStep = this.getFirstSubStep(step);
        }

        return {step, subStep};
    }

    getStepUrlId(step) {
        return this.idToStep[step];
    }

    getFirstSubStep(step) {
        switch (step) {
            // case "stepB":
            //     return "sittingPosition";
            case "stepC":
                return "bodyMeasurement";
            case "stepD":
                return "partSizes";
            default:
                return "bikeType";
        }
    }
}
// eslint-disable-next-line
export default new UrlParser();
