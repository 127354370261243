import Locale from "./Locale";

class CurrencyFormatter {

    format = (num, symbol = "EUR", isZeroFractionDigit) => {
        let locale = `${Locale.getLanguage()}-${Locale.getCountry()}`;
        if (locale === 'lt-INT') {
            locale = 'lt-LT';
        } else if (locale === 'nl-INT') {
            locale = 'nl-NL';
        } else if(locale === 'en-INT') {
            locale = 'en-US';
        }

        if (locale === 'lt-INT') {
            locale = 'nl-NL';
        }
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: symbol,
            minimumFractionDigits: isZeroFractionDigit ? 0 : 2
        }).format(num)
    }
}
// eslint-disable-next-line
export default new CurrencyFormatter();
