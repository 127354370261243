import {applyMiddleware, combineReducers, createStore} from "redux";
import bikefit from "./reducers/bikefit";
import DataStore from "../lib/DataStore";
import bikeFitOptions from "../bikeFitOptions";
import ebike from "./reducers/ebike";
import checkoutReducer from "./reducers/checkout";
import extraMeasurements from "./reducers/extraMeasurements";
import feedback from "./reducers/exitFeedback";
import {thunk} from "redux-thunk";
import whitelabelReducer from "./reducers/whitelabel";

let store;
let storedData = {
    ebike: undefined,
    bikefit: undefined,
    checkout: undefined,
    whitelabel: undefined,
    extraMeasurements: undefined,
    feedback: undefined
};
const reducers = combineReducers({ extraMeasurements, ebike, bikefit, checkout: checkoutReducer, whitelabel: whitelabelReducer, feedback });

storedData['checkout'] = JSON.parse(localStorage.getItem('checkout')) || {checkout: {}, returnUrl: ''};
storedData['whitelabel'] = JSON.parse(localStorage.getItem('whitelabel')) || { mainDomain: true, whitelabelDomain: false, info: null};

storedData['extraMeasurements'] = JSON.parse(localStorage.getItem('extraMeasurements')) || {bikeMeasurements: {category: "MTB"}, bikeSelection: {}};
storedData['feedback'] = JSON.parse(localStorage.getItem('feedback')) || {
    name: '',
    email: '',
    option: 0,
    comment: '',
};

const bikefitFlowString = localStorage.getItem('bikefitFlow');

if (bikefitFlowString) {
    const bikefitFlow = JSON.parse(bikefitFlowString);

    if (!bikefitFlow.hasOwnProperty('choices')) {
        bikefitFlow.choices = {};
    }

    if (!bikefitFlow.choices.hasOwnProperty('stepA')) {
        bikefitFlow.choices.stepA = {};
    }

    if (!bikefitFlow.choices.hasOwnProperty('stepB')) {
        bikefitFlow.choices.stepB = {};
    }

    if (!bikefitFlow.choices.hasOwnProperty('stepC')) {
        bikefitFlow.choices.stepC = {};
    }

    for (let bikeFitOption in bikeFitOptions) {
        if (bikeFitOptions[bikeFitOption].step === "stepC"
            && DataStore.isKey(bikeFitOptions[bikeFitOption].dataName || "")
            && DataStore.hasValue(bikeFitOptions[bikeFitOption].dataName || "")
        ) {
            bikefitFlow.choices.stepC[bikeFitOption] = DataStore.getValue(bikeFitOptions[bikeFitOption].dataName);
        }
    }

    storedData['bikefit'] = { ...bikefitFlow, ...DataStore.getValues() }
}

const ebikeFlowString = localStorage.getItem('ebikeFlow');

if (ebikeFlowString) {
    const ebikeFlow = JSON.parse(ebikeFlowString);

    if (!ebikeFlow.hasOwnProperty('choices')) {
        ebikeFlow.choices = {};
    }

    if (!ebikeFlow.choices.hasOwnProperty('bikeType')) {
        ebikeFlow.choices.bikeType = {};
    }

    if (!ebikeFlow.choices.hasOwnProperty('engineORBatteryCapacity')) {
        ebikeFlow.choices.engineORBatteryCapacity = {};
    }

    if (!ebikeFlow.choices.hasOwnProperty('safety')) {
        ebikeFlow.choices.safety = {};
    }

    if (!ebikeFlow.choices.hasOwnProperty('partSpecification')) {
        ebikeFlow.choices.partSpecification = {};
    }

    storedData['ebike'] = ebikeFlow;
}

store = createStore(reducers, storedData, applyMiddleware(thunk));

store.subscribe(() => {
    const bikefitState = store.getState()['bikefit'];
    const ebikeState = store.getState()['ebike'];
    const checkout = store.getState()['checkout'];
    const whitelabel = store.getState()['whitelabel'];
    const extraMeasurements = store.getState()['extraMeasurements'];
    const feedback = store.getState()['feedback'];

    for (let bikeFitOption in bikeFitOptions) {
        if (
            bikeFitOptions[bikeFitOption].step === "stepC"
            && DataStore.isKey(bikeFitOptions[bikeFitOption].dataName || "")
            && ((((bikefitState || {}).choices || {}).stepC || {})[bikeFitOption])
        ) {
            DataStore.setValue({ [bikeFitOptions[bikeFitOption].dataName]: bikefitState.choices.stepC[bikeFitOption] });
        }
    }

    DataStore.pushData();
    localStorage.setItem('bikefitFlow', JSON.stringify(bikefitState));
    localStorage.setItem('ebikeFlow', JSON.stringify(ebikeState));
    localStorage.setItem('checkout', JSON.stringify(checkout));
    localStorage.setItem('whitelabel', JSON.stringify(whitelabel));
    localStorage.setItem('feedback', JSON.stringify(feedback));

    localStorage.setItem('extraMeasurements', JSON.stringify(extraMeasurements));
});


export default store;
