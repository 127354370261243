import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Redirections from "../../lib/Redirections";
import {useDispatch} from "react-redux";
import {chooseSubscription, setReturnUrl} from "../../redux/actions/checkout";
import CurrencyFormatter from "../../lib/CurrencyFormatter";
import ReactGA from 'react-ga4';
import useFeatureFlag from '../../hooks/useFeatureFlag';

const BikefitPlansForCheckout =
  ({
     returnUrl,
     onlyPayForAccount = false,
     extension = false,
    extendingAccountSubscriptionId = 0,
    subscriptions
   }) => {
    const [t, i18next] = useTranslation('subscription')
    const [t2] = useTranslation('newLandingPage')
    const dispatch = useDispatch();
    const freeReport = useFeatureFlag("free_report")

    const shouldRedirectDirectly = subscription => {
      return onlyPayForAccount && !subscription.needs_account
    }

    const cookieStorage = {
      getItem: (key) => {
        return localStorage.getItem(`cookie_${key}`)
      },
      setItem: (key, value) => {
        return localStorage.setItem(`cookie_${key}`, value)
      },
    };

    const clickSubscription = subscription => {
      ReactGA.event('add_to_cart', {
        currency: subscription.price.currency,
        value: subscription.price.price / 100,
        items: [{
          item_id: subscription.code,
          item_name: `Product type: ${subscription.type}, code: ${subscription.code}`,
          item_category: subscription.type,
          price: subscription.price.price / 100,
          currency: subscription.price.currency
        }]
      });

      if (shouldRedirectDirectly(subscription)) {
        Redirections.goTo(returnUrl)
      } else {
        dispatch(chooseSubscription(subscription, extension, extendingAccountSubscriptionId))
        dispatch(setReturnUrl(returnUrl))
        Redirections.goTo('/subscription/payment/data')
      }
    }



    useEffect(() => {
      cookieStorage.setItem('hasGoneToPaymentPage', 'false');
    }, [i18next.language])

    return (<>
      {
        freeReport && 
      <div className={'price-card card w-100 p-3 mb-3'}>
        <div className="row ">
          <div className="col-md-3 pl-4 my-auto">
            <h6><strong>{t('BIKE_FIT_INSIGHTS')}</strong></h6>
          </div>
          <div className='col-md-6 my-auto p-md-0'><ul className='m-0 p-md-0'>
            <li>{t('BASIC_BIKE_FIT_INSIGHTS')}</li>
            <li>{t('BIKE_PERSONALIZATION_REQUIREMENTS')}</li>
          </ul>
          </div>
          <div className='col-md-3 text-center my-auto'>

            <span className="subscription-price"><span>{t('FREE')}</span></span><br />
            <button className='btn btn-light btn-signup text-orange' onClick={() => { Redirections.goTo("/free-resource") }}>{t('VIEW')}</button>
          </div>
        </div>
      </div>
      }
      {subscriptions.map((subscription, index) => {
        if (freeReport && index === 0) {
          return false
        }
        let buttonText;
        if (shouldRedirectDirectly(subscription)) {
          buttonText = t('Bikefit_StartDirectly');
        } else if (extension) {
          buttonText = t('Bikefit_ExtendAccount');
        } else {
          buttonText = t(subscription.needs_account ? 'Bikefit_PayAccount' : 'Bikefit_PayOnce');
        }

        let price = 0;
        let beforePrice = 0;
        if (subscription.price) {
          price = CurrencyFormatter.format(subscription.price.price / 100, subscription.price.currency);

          if (subscription.price.before_price) {
            beforePrice = CurrencyFormatter.format(subscription.price.before_price / 100, subscription.price.currency);
          }

          if (extension && subscription.price.extension_price) {
            price = CurrencyFormatter.format(subscription.price.extension_price / 100, subscription.price.currency);
            beforePrice = CurrencyFormatter.format(subscription.price.price / 100, subscription.price.currency);
          }
        } else {
          price = 0
        }

        // const points = extractPointsFromSubscription(subscription)

        return (
          <div key={subscription.code} className={'price-card card w-100 p-3 mb-3' + ((!extension && subscription.highlight) ? ' highlighted' : '')}>
            <div className="row ">
              <div className="col-md-3 pl-4 my-auto">
                {/* <h5 className=''>{subscription.lang ? subscription.lang.title : subscription.code}</h5> */}
                <h6><strong>{index === 0 ? t('BIKE_FIT_PLAN_One-Time Bike Fit') : (index === 1 ? (freeReport ? t2("Full_Bike_Fit_Report") : t('BIKE_FIT_PLAN_Account For Cyclists')) : t('BIKE_FIT_PLAN_Subscription For Professionals'))}</strong></h6>
              </div>
              <div className='col-md-6 my-auto p-md-0'>
                {index === 0 && <ul className='m-0 p-md-0'>
                  <li>{t('BIKE_FIT_PLAN_One bike fit report')}</li>
                  <li>{t('BIKE_FIT_PLAN_Based on your current input')}</li>
                </ul>}
                {index === 1 && <ul className='m-0 p-md-0'>
                  {
                    freeReport ? (
                      <>
                        <li>{t2('Your_Optimal_Riding_Position')}</li>
                        <li>{t2('Bike_Setup_Specifications')}</li>
                        <li>{t2('Frame_Size_Advice')}</li>
                        <li>{t2('Money_Back_Guarantee')}</li>
                      </>
                    ) : (
                      <>
                          <li>{t('BIKE_FIT_PLAN_6 months access to all our services')}</li>
                          <li>{t('BIKE_FIT_PLAN_Unlimited bike fits for one person')}</li>
                          <li>{t('BIKE_FIT_PLAN_Money back guarantee')}</li>
                      </>
                    )
                  }
                </ul>}
                {index === 2 && <ul className='m-0 p-md-0'>
                  <li>{t('BIKE_FIT_PLAN_Unlimited bike for one year')}</li>
                  <li>{t('BIKE_FIT_PLAN_Online training')}</li>
                </ul>}
              </div>
              <div className='col-md-3 text-center my-auto'>
                {beforePrice !== 0 && <>
                  <span className="subscription-price"><span className="old-price">{beforePrice}</span></span><br/></>}
                <span className="subscription-price"><span>{price}</span></span><br />
                <button className={'btn btn-light btn-signup' + ((!extension && subscription.highlight) ? ' text-orange' : '')} onClick={() => clickSubscription(subscription)}>{index === 0 ? t('BIKE_FIT_PLAN_Buy') : (index === 1 & freeReport) ? t('Get_report') : buttonText}</button>
              </div>
            </div>
          </div>
        )
      })}
    </>)
  }

export default BikefitPlansForCheckout;
