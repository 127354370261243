import { createContext, useContext } from 'react';

// Create the context
export const OpenFeatureContext = createContext({
  openFeatureClient: null
});

// Custom hook for easy access to the context
export const useOpenFeature = () => {
  const context = useContext(OpenFeatureContext);
  if (!context) {
    throw new Error('useOpenFeature must be used within an OpenFeatureProvider');
  }
  return context;
};
