import React, {useEffect} from "react";
import { useState } from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setExtraMeasurementBikeCat, setExtraMeasurementBikeValue} from "../../redux/actions/extraMeasurements";

const BikeMeasurements = (props) => {
  const [t,] = useTranslation("framemaatAdvice");
  const dispatch = useDispatch();

  const [bikefitButtonActive, setBikefitButtonActive] = useState(false);
  const [bikefitCat, setBikefitCat] = useState();
  const [transCat, setTransCat] = useState();
  const [advice, setAdvice] = useState();

  const category = useSelector(store => store.extraMeasurements?.bikeMeasurements?.category || 'MTB')
  const saddleHeight = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['saddleHeight'] || '')
  const saddleSetback = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['saddleSetback'] || '')
  const handlebarReach = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['handlebarReach'] || '')
  const personLength = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['personLength'] || '')
  const drop = useSelector(store => store.extraMeasurements?.bikeMeasurements?.[transCat]?.['drop'] || '')

  useEffect(() => {
    const choices = JSON.parse(localStorage.getItem("last_result_choices") || "{}");
    setAdvice(JSON.parse(localStorage.getItem("last_result_advice") || "{}"));

    if (choices?.stepA?.bikeType === 1) {
      setBikefitCat("Race");
    } else if (choices?.stepA?.bikeType === 2) {
      setBikefitCat("MTB");
    }
  }, []);

  useEffect(() => {
    if (category === 'MTB') {
      setTransCat('mtb')
    } else if (category === 'Race') {
      setTransCat('road')
    }
  }, [category]);

  const setValue = (type, value) => {
    dispatch(setExtraMeasurementBikeValue(type, value));
  }

  const onFillBikefit = () => {
    dispatch(setExtraMeasurementBikeValue('saddleHeight', advice.saddle_height / 10))
    dispatch(setExtraMeasurementBikeValue('saddleSetback', advice.saddle_setback / 10))
    dispatch(setExtraMeasurementBikeValue('handlebarReach', advice.bike_length / 10))
    dispatch(setExtraMeasurementBikeValue('personLength', advice.person_length / 10))
    dispatch(setExtraMeasurementBikeValue('drop', advice.bike_drop / 10))
  }

  useEffect(() => {
    setBikefitButtonActive(bikefitCat === category && advice);
  }, [bikefitCat, category, advice])
  
  return (
      <>
      <div className="col-md-12">
        <div className="row m-2">
          <div className="col-md-5">
            <label className="form-check-label">{t('BicycleCategory')}
            </label>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-11">
                <select className="form-control" value={category} onChange={(event) => {
                  dispatch(setExtraMeasurementBikeCat(event.target.value))
                }}>
                  <option key="category-1" value={false}>{t('select one')}</option>
                  <option key="category-2" value="Race">{t('Racebike')}</option>
                  <option key="category-3" value="MTB">{t('Mountainbike')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 no-print">
        <div className="row m-2">
          <div className="col-md-5">
            <label className="form-check-label">{t('personLength')}
            </label>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-11">
                <input type="number" className="form-control" value={personLength}
                       onChange={(event) => {
                         setValue('personLength', event.target.value)
                       }} required="required" min="120" max="240" step=".01"/>
              </div>
              <div className="col-1 after-select pl-0">
                {t('cm')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row m-2">
          <div className="col-md-5">
            <label className="form-check-label">{t('SaddleHeight')}
            </label>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-11">
                <input type="number" className="form-control" value={saddleHeight} onChange={(event) => {
                  setValue('saddleHeight', event.target.value)
                }} required="required" min="50" max="100" step=".01"/>
              </div>
              <div className="col-1 after-select pl-0">
                {t('cm')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row m-2">
          <div className="col-md-5">
            <label className="form-check-label">{t('SaddleSetback')}
            </label>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-11">
                <input type="number" className="form-control" value={saddleSetback} onChange={(event) => {
                  setValue('saddleSetback', event.target.value)
                }} required="required" min="1" max="15" step=".01"/>
              </div>
              <div className="col-1 after-select pl-0">
                {t('cm')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row m-2">
          <div className="col-md-5">
            <label className="form-check-label">{t('handlebarReach')}
            </label>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-11">
                <input type="number" className="form-control" value={handlebarReach}
                       onChange={(event) => {
                         setValue('handlebarReach', event.target.value)
                       }} required="required" min="40" max="100" step=".01"/>
              </div>
              <div className="col-1 after-select pl-0">
                {t('cm')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row m-2">
          <div className="col-md-5">
            <label className="form-check-label">{t('Drop')}
            </label>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-11">
                <input type="number" onChange={(event) => {
                  setValue('drop', event.target.value);
                }} className="form-control" value={drop} required="required" min="-20" max="10"
                       step=".01"/>
              </div>
              <div className="col-1 after-select pl-0">
                {t('cm')}
              </div>
              <div className="col-11 no-print">
                <p><em>{t('bikeFitpara0')}</em></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-2 ml-3">
        <button type="button" className="btn btn-secondary" onClick={onFillBikefit} disabled={!bikefitButtonActive} >{t("Update Bikefit")}</button>
      </div>
      </>
  );
}

export default BikeMeasurements;
