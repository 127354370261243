import { withTranslation } from 'react-i18next';
import React, {Component} from 'react';
import Footer from '../../../components/Footer';
import StartAdvice from '../../../components/StartAdvice';

class InfoFitScore extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="main">
                <div className="info-page">
                    <StartAdvice t={this.props.t}/>
                    <div className="container">
                        <h1>{this.props.t('De score voor ‘geometrische fit’')}</h1>

                        <p>{this.props.t('InfoFitScore-De score voor de ‘geometrische fit’')}.</p>

                        <div className="row">
                            <div className="col-3">
                                {this.props.t('Voorkeur snelheid')}
                            </div>
                            <div className="col-3">
                                {this.props.t('-> competitieve zithouding')}
                            </div>
                            <div className="col-6">
                                {this.props.t('= diepe en lange zitpositie')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                {this.props.t('Compromis')}
                            </div>
                            <div className="col-3">
                                {this.props.t('-> sportieve zithouding')}
                            </div>
                            <div className="col-6">
                                {this.props.t('= gemiddelde zitpositie')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                {this.props.t('Voorkeur comfort')}
                            </div>
                            <div className="col-3">
                                {this.props.t('-> recreatieve zithouding')}
                            </div>
                            <div className="col-6">
                                {this.props.t('= rechterop en kortere zitpositie')}
                            </div>
                        </div>

                        <p>{this.props.t('InfoFitScore-Een fiets die')}.</p>

                        <ol>
                            <li>{this.props.t('Het ‘fit-potentieel’')}</li>
                            <li>{this.props.t('De ‘aandachtspunten’')}</li>
                        </ol>

                        <p className="margintop">{this.props.t('InfoFitScore-Het ‘fit-potentieel’')}:</p>

                        <ul>
                            <li>{this.props.t('InfoFitScore-Nadelig zijn voor de')}</li>
                            <li>{this.props.t('InfoFitScore-Dicht bij het maximale')}</li>
                        </ul>

                        <p>{this.props.t('InfoFitScore-Van het ‘fit-potentieel’ worden')}.</p>

                        <p>{this.props.t('InfoFitScore-Het is overigens heel gebruikelijk')}.</p>


                    </div>

                    <Footer/>
                </div>
            </div>
        );
    }
}

export default withTranslation('infoFitScore')(InfoFitScore);
