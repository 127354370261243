import Api from "./Api";

class MoreInfo
{
    constructor() {
        this.showListeners = [];
        this.closeListeners = [];
    }

    registerMoreInfoOpenListener = (listener) => {
        this.showListeners.push(listener);
    };

    registerMoreInfoCloseListener = (listener) => {
        this.closeListeners.push(listener);
    };

    show = (section, part) => {
        Api.get('/more_info', {section:section, part: part}).then(response => {
            const text = (response.data.data[0] || {text: ''}).text;
            for (let listener in this.showListeners) {
                if (this.showListeners.hasOwnProperty(listener)) {
                    this.showListeners[listener](text);
                }
            }
        });
    };

    close = () => {
        for (let listener in this.closeListeners) {
            if (this.closeListeners.hasOwnProperty(listener)) {
                this.closeListeners[listener]();
            }
        }
    };
}
// eslint-disable-next-line
export default new MoreInfo();