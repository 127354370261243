import flagsmith from 'flagsmith';
import {ProviderEvents, OpenFeatureEventEmitter} from "@openfeature/js-sdk";

export class FlagsmithFeatureProvider {
    metadata = {
        name: 'Flagsmith Feature Provider',
    };

    events = new OpenFeatureEventEmitter();

    constructor(apiKey, apiHost) {
        flagsmith.init({
            api: apiHost,
            cacheFlags: true,
            environmentID: apiKey,
            onChange: this.handleFlagsChange.bind(this),
            onError: (error) => this.events.emit(ProviderEvents.Error, {error})
        }).then(() => this.events.emit(ProviderEvents.Ready));
    }

    handleFlagsChange(oldFlags, params) {
        // Emit events or handle other custom logic if needed
    }

    async resolveBooleanEvaluation(flagKey, defaultValue, context) {
        this.prepareTraits(context);
        const isEnabled = flagsmith.hasFeature(flagKey);
        return {value: isEnabled, source: 'flagsmith'};
    }

    async resolveStringEvaluation(flagKey, defaultValue, context) {
        this.prepareTraits(context);
        const value = flagsmith.getValue(flagKey);
        return {value: value || defaultValue, source: 'flagsmith'};
    }

    async resolveNumberEvaluation(flagKey, defaultValue, context) {
        this.prepareTraits(context);
        const value = flagsmith.getValue(flagKey);
        return {value: Number(value) || defaultValue, source: 'flagsmith'};
    }

    async resolveObjectEvaluation(flagKey, defaultValue, context) {
        this.prepareTraits(context);
        const value = flagsmith.getValue(flagKey);
        return {value: value || defaultValue, source: 'flagsmith'};
    }

    prepareTraits(context) {
        if (context?.targetingKey) {
            flagsmith.identify(context?.targetingKey, context)
        } else {
            this.setTraits(context);
        }
    }

    setTraits(traits) {
        flagsmith.setTraits(traits);
    }
}

export default FlagsmithFeatureProvider;
