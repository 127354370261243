import i18n from '../i18n'
import Redirections from "./Redirections";
import UrlParser from "./UrlParser";

class Locale {
    getLanguage = () => i18n.language.split('-')[0] || 'nl';
    getCountry = () => i18n.language.split('-')[1] || 'NL'

    getCountryFromUrl = () => {
        const country = this.parseUrl()['country'] || false;
        return country ? country.toUpperCase(): false;
    }
    getLanguageFromUrl = () => this.parseUrl()['lang'] || false;

    getUrlForLang = lang => this.getUrlForLocale(this.getCountry(), lang);
    getUrlForCountry = country => this.getUrlForLocale(country, this.getLanguage());

    getUrlForCurrentLocale = url => this.getUrlForLocale(this.getCountry(), this.getLanguage(), url);

    getUrlForLocale = (country, lang, url = false) => {
        const toParseUrl = url || window.location.href;
        if (! UrlParser.isInternal(toParseUrl)) {
            return toParseUrl;
        }

        const urlParts = UrlParser.parsePath(toParseUrl);
        const pathItems = urlParts['path'].split('/');

        const newPath = (pathItems.length >= 3 && (pathItems[1].length === 2 || pathItems[1] === 'int') && pathItems[2].length === 2)
          ? `/${country.toLowerCase()}/${lang}/${pathItems.slice(3).join('/')}`
          : `/${country.toLowerCase()}/${lang}/${pathItems.slice(1).join('/')}`;

        let newUrl = urlParts.hasOwnProperty('baseUrl') ? urlParts['baseUrl'] + newPath : newPath;
        return (newUrl.lastIndexOf('/') === newUrl.length - 1) ? newPath: newPath + '/';
    }

    isLocalizedUrl = url => this.parseUrl(url) !== false;

    parseUrl = (url = false) => {
        const urlParts = UrlParser.parsePath(url || window.location.href);
        const path = urlParts["path"];

        const pathItems = path.split('/');

        if (pathItems.length >= 3 && (pathItems[1].length === 2 || pathItems[1] === 'int') && pathItems[2].length === 2) {
            return {country: pathItems[1].toUpperCase(), lang: pathItems[2]}
        } else {
            return false;
        }
    }

    updateUrl = () => {
        const url = this.getUrlForLocale(this.getCountry(), this.getLanguage());
        Redirections.goTo(url);
    }

    setLanguage = lang => {
        i18n.changeLanguage(`${lang}-${this.getCountry()}`);
        this.updateUrl();
    }

    setCountry = country => {
        i18n.changeLanguage(`${this.getLanguage()}-${country}`);
        this.updateUrl();
    }
}
// eslint-disable-next-line
export default new Locale();
