import React, { useEffect, useState } from 'react';
import Footer from "../../../components/Footer";
import ImageComponent from '../../../components/ImageComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import TextComponent from '../../../components/TextComponent';
import HeaderComponent from '../../../components/HeaderComponent';
import BlogService from "../../../lib/Blog";
import isEmpty from "lodash/isEmpty";
import Loader from "../../../components/Loader";
import NotFound from "../../NotFound";
import { useParams } from 'react-router';
import DocumentMeta from "react-document-meta";
import {config} from "../../../config";
import './style.css';
import i18n from "../../../i18n";

const SingleBlog = () => {
    const [blogData, setBlogData] = useState({});
    const [seoMeta, setSeoMeta] = useState({});
    const [structuredData, setStructuredData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const params = useParams();


    const processSeoData = (seoData) => {
        let meta = {};

        let metaName = {};
        let metaProperty = {};

        /* known meta types */
        if (seoData?.canonicalURL) {
            meta['canonical'] = seoData?.canonicalURL;
        }

        if (seoData?.metaTitle) {
            meta['title'] = seoData?.metaTitle;
        }

        if (seoData?.metaDescription) {
            meta['description'] = seoData?.metaDescription;
        }


        /* meta names */
        if (seoData?.metaViewport) {
            metaName['viewport'] = seoData?.metaViewport;
        }

        if (seoData?.metaRobots) {
            metaName['robots'] = seoData?.metaRobots;
        }

        if (seoData?.metaImage) {
            metaName['image'] = config.cmsApiUrl + seoData?.metaImage?.data?.attributes?.url;
        }

        /* social media */
        for (let socialData of seoData?.metaSocial || []) {
            switch(socialData.socialNetwork) {
                case "Facebook":
                    metaProperty['og:title'] = socialData?.title;
                    metaProperty['og:description'] = socialData?.description;
                    break;
                case "Twitter":
                    metaName['twitter:title'] = socialData?.title;
                    metaName['witter:description'] = socialData?.description;
                    break;
                default: break;
            }
        }
        // seoData?.metaSocial?.map((socialData) => {
        //     switch(socialData.socialNetwork) {
        //         case "Facebook":
        //             metaProperty['og:title'] = socialData?.title;
        //             metaProperty['og:description'] = socialData?.description;
        //             break;
        //         case "Twitter":
        //             metaName['twitter:title'] = socialData?.title;
        //             metaName['witter:description'] = socialData?.description;
        //             break;
        //     }
        // });

        /* closing tags */
        if (metaName) {
            meta['meta'] = {name: metaName};
            if (metaProperty) {
                meta['meta']['property'] = metaProperty;
            }
        } else if (metaProperty) {
            meta['property'] = {property: metaProperty};
        }

        if (seoData?.structuredData) {
            setStructuredData(seoData?.structuredData);
        }

        setSeoMeta(meta);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const blog = await BlogService.getBlogBySlug(params.slug);
                setBlogData(blog?.data?.attributes?.content);
                processSeoData(blog?.data?.attributes?.seo);
            } catch (error) {
                console.error('Error fetching blog:', error);
                setError('Error fetching blog.');
            } finally {
                setLoading(false);
            }
        };

        i18n.on('languageChanged', async (lng) => {
            await fetchData();
        });

        fetchData();
    }, [params.slug]);

    const Component = ({item}) => {
        switch (item?.__component) {
            case 'blog-content.image':
                return <ImageComponent
                  key={`image-${item.id}`}
                  alignment={item.alignment}
                  imageData={item.image.data[0].attributes}
                />;
            case 'blog-content.button':
                return <ButtonComponent key={item.id} {...item} />;
            case 'blog-content.text':
                return <TextComponent key={item.id} {...item} />;
            case 'blog-content.title':
                return <HeaderComponent key={item.id} {...item} />;
            default:
                return null;
        }
    }

    if (loading) {
        return <Loader/>
    }

    if (error || isEmpty(blogData)) {
        return <NotFound/>;
    }

    return (
      <>
          <DocumentMeta {...seoMeta} />
          {structuredData && <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(structuredData),
            }}
          />}
          <div className="info-page">
              <div className="blog-page container">
                  <div>
                      {blogData?.map((item, index) => <Component key={`blog-${index}`} item={item}/>)}
                  </div>
              </div>
          </div>
          <Footer/>
      </>
    );
};

export default SingleBlog;
