import React, { useEffect, useState } from 'react';
import Footer from '../../../components/Footer';
import BlogOverviewGrid from './BlogOverviewGrid';
import BlogService from '../../../lib/Blog';
import Loader from "../../../components/Loader";
import i18n from "../../../i18n";
import "./style.css";

const OtherBlogs = () => {
    const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            const blogs = await BlogService.getAllBlogs(null, 'seo');
            setBlogData(blogs.data);
        } catch (error) {
            console.error('Error fetching blogs:', error);
            setError('Error fetching blogs.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        i18n.on('languageChanged', async (lng) => {
            await fetchData();
        });

        fetchData();
    }, []);

    if (loading) {
        return <Loader />
    }

    return (
      <div className={'info-page'}>
          <div className={`blog-overview`}>
              <div className="container">
                  <div className="row blog-overview-posts-wrapper">
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                      {!error && blogData && <BlogOverviewGrid blogData={blogData} />}
                  </div>
              </div>
              <Footer />
          </div>
      </div>
    );
};

export default OtherBlogs;
