import {
    FEEDBACK_OPTION_CHANGED,
    FEEDBACK_COMMENT_CHANGED, FEEDBACK_CREATED,
} from '../actions/exitFeedbackActionTypes';

const initialState = {
    name: '',
    email: '',
    option: 0,
    comment: '',
    feedbackId: null,
    discountPercentage: 0,
};

function feedback(state = initialState, action) {
    switch (action.type) {
        case FEEDBACK_OPTION_CHANGED:
            return {...state, ...{option: action.value}}
        case FEEDBACK_COMMENT_CHANGED:
            return {...state, ...{comment: action.value}}
        case FEEDBACK_CREATED:
            const {
                discount_token: discountToken,
                exit_feedback: exitFeedback
            } = action.value;
            return {
                ...state,
                discountPercentage: discountToken.percentage,
                feedbackId: exitFeedback.id
            }
        default:
            return state;
    }
}

export default feedback;