// download guide for new landing page 
export function downloadGuide(t) {
    let baseUrl = 'asset/pdf/';
    let pdfUrl = t('pdf_name');

    const link = document.createElement('a');
    link.href = baseUrl + pdfUrl;
    link.download = pdfUrl;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}