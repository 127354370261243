import React from 'react'
import {useTranslation} from "react-i18next";

const NotFound = () => {
    const [t, ] = useTranslation()

    return (
        <div className="info-page">
            <div className="container main">
                <h1>{t('Page_NotFound_Title')}</h1>
                <p>{t('Page_NotFound_Intro')}</p>
            </div>
        </div>
    )
}

export default NotFound;