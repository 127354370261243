import {
  NEXT,
  BACK,
  CHANGE_CHOICE,
  INIT_STEP,
  GOTO,
  START_FROM_BIKEFINDER,
  ANGLE_ADVICE_FETCHED, CHOICES_FETCHED
} from "./bikefitActionTypes";

const changeChoice = (newChoice) => ({
  type: CHANGE_CHOICE,
  choice: newChoice,
});

const choicesFetched = (choices) => ({
  type: CHOICES_FETCHED,
  choices
})

const angleFetched = (advicedAngle, minAngle, maxAngle) => ({
  type: ANGLE_ADVICE_FETCHED,
  advicedAngle,
  minAngle,
  maxAngle
});

const next = (isWhiteLabelDomain) => ({
  type: NEXT,
  isWhiteLabelDomain
});

const back = (isWhiteLabelDomain) => ({
  type: BACK,
  isWhiteLabelDomain
});

const initStep = (step, subStep) => ({
  type: INIT_STEP,
  step,
  subStep,
});

const startFromBikefinder = (bikeCat, bikeType, fromResultPage = false) => ({
  type: START_FROM_BIKEFINDER,
  bikeCat,
  bikeType,
  fromResultPage,
});

const goTo = (step, subStep) => ({
  type: GOTO,
  step,
  subStep,
});

export { angleFetched, changeChoice, choicesFetched, next, back, initStep, startFromBikefinder, goTo };
