import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import defaultLang from "./defaultLang";
import detectFromUrl from "./detectFromUrl";

import { nl, en, lt } from "./locales";
import loadWhitelabelTranslations from "./whitelabels";

const options = {
  debug: false,

  resources: {
    "nl-NL": nl,
    "en-NL": en,

    "nl-BE": nl,
    "en-BE": en,

    "nl-DE": nl,
    "en-DE": en,

    "nl-CA": nl,
    "en-CA": en,

    "nl-AU": nl,
    "en-AU": en,

    "nl-IT": nl,
    "en-IT": en,

    "nl-PL": nl,
    "en-PL": en,

    "nl-GB": nl,
    "en-GB": en,

    "nl-US": nl,
    "en-US": en,

    "nl-INT": nl,
    "en-INT": en,
    "lt-INT": lt,
  },


  fallbackLng: "nl-NL",
  ns: ["common"],

  defaultNS: "common",
  fallbackNS: "common",

  detection: {
    order: ["detectFromUrl", "cookie", "localStorage", "defaultLang"],
  },

  interpolation: {
    format: function (value, format, locale) {
      if (locale === 'nl-INT') {
        locale = 'nl-NL';
      } else if(locale === 'en-INT') {
        locale = 'en-US';
      }
      if (format === "euro")
        return new Intl.NumberFormat(locale, {
          style: "currency",
          currency: "EUR",
        }).format(value);
      return value;
    },
  },

  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
    transKeepBasicHtmlNodesFor: ["b", "br", "span", "strong", "i", "p", "u", "h1", "h2", "h3"],
  },
};

const callBack = (err ,t) => {
  document.querySelector('html').setAttribute('lang',i18n.language.substr(0, 2));
}

const languageDetector = new LanguageDetector();
languageDetector.addDetector(defaultLang);
languageDetector.addDetector(detectFromUrl);

i18n.use(languageDetector).init(options, callBack);

loadWhitelabelTranslations(i18n);

export default i18n;
