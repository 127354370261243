import React from 'react';
import { Button } from 'react-bootstrap';
import { startBikefit } from '../../../../components/Bikefit/lib/bikefit';
import { useTranslation } from 'react-i18next';

const BikeFittingButton = ({ buttonColor, textColor }) => {
  const { t } = useTranslation()
  return (
    <Button variant='' style={{ backgroundColor: buttonColor, color: textColor }} className='custom-button cta' onClick={() => startBikefit(true)}>{t("bikeFittingBtn")}</Button>
  );
};

export default BikeFittingButton;