import {
  FEEDBACK_OPTION_CHANGED,
  FEEDBACK_COMMENT_CHANGED,
  FEEDBACK_CREATED,
} from './exitFeedbackActionTypes';

const feedbackOptionUpdated = (value) => ({
  type: FEEDBACK_OPTION_CHANGED,
  value
});

const feedbackUpdated = (value) => ({
  type: FEEDBACK_CREATED,
  value
})

const setFeedbackComment = (value) => ({
  type: FEEDBACK_COMMENT_CHANGED,
  value
});

export {feedbackUpdated, feedbackOptionUpdated, setFeedbackComment};
