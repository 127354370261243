import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Subscription from '../../lib/Subscription';
import CurrencyFormatter from '../../lib/CurrencyFormatter';


const FeedbackFooter = ({onFeedback, onFinish, onClose, discountPercentage = 50, page = 0}) => {
    const [t, ] = useTranslation("subscription");
    const [beforePrice, setBeforePrice] = useState(0.00);
    const [price, setPrice] = useState(0.00);

    const loadSubscriptions = () => {
        Subscription.getSubscriptions('bikefit')
          .then(subscriptions => {
            subscriptions.forEach((subscription, index) => {
                if(subscription.highlight === true && subscription.price) {
                    setBeforePrice((subscription.price?.before_price || 0.00) / 100)
                    setPrice((subscription.price?.price || 0.00) / 100)
                }
            })
        })
    }

    useEffect(() => {
      loadSubscriptions();
    }, [i18next.language])
    return (
        <div className="d-flex mt-4 grid feedback-footer">
            <Button variant="outline-secondary" className="m-2" onClick={(e) => {e.preventDefault(); onClose();}}>
                {t('Afbreken')}
            </Button>
            {
              (process.env.NODE_ENV !== "production" ||  process.env.APP_ENV === "develop") && (
                <Button variant="outline-secondary" className={"m-2"} onClick={(e) => {e.preventDefault(); onFeedback(page);}}>{t('Vorige')}</Button>
              )
            }
            <div className="flex-grow-1 m-2"></div>
            <div className="m-2">
                <span className="subscription-price p-2">
                    <span className='old-price h6'> {CurrencyFormatter.format(beforePrice, 'EUR')}</span>
                </span>
                <span className="subscription-price p-2">
                    <span className='old-price h6'> {CurrencyFormatter.format(price, 'EUR')}</span>
                </span>
                <span className="subscription-price p-2">
                    <span className='new-price h4'> {CurrencyFormatter.format((price * (100 - discountPercentage) / 100), 'EUR')}</span>
                </span>
            </div>
            <Button type="primary" className={"m-2 px-4"} onClick={(e) => {e.preventDefault(); onFinish()}}>{t('Afrekenen')}</Button>
        </div>
    )
}

export default FeedbackFooter;