import { config } from "../config";

const hostname = window.location.hostname;
const whitelabelDomains = config.whitelabeDomains || [];

var locales = ['nl', 'en', 'lt'];
const countries = ['NL', 'BE', 'DE', 'CA', 'AU', 'IT', 'PL', 'GB', 'US', 'INT'];

const addResourceBundle = (lang, namespace, data, i18n) => {
  for (const country of countries) {
    const locale = `${lang}-${country}`;
    try {
      if (data && typeof data === 'object') {
        Object.entries(data).forEach(([key, value]) => {
          i18n.addResource(locale, namespace, key, value);
        });
      }
    } catch (error) {
    }
  }
};

const loadLocaleFile = async (localeFile, languageKey, i18n) => {
  try {
    const localeData = await import(`./locales/${localeFile}`);
    if (localeData) {
      Object.entries(localeData).forEach(([namespace, data]) => {
        addResourceBundle(languageKey, namespace, data, i18n);
      });
    }
  } catch (error) {
  }
};

const loadWhitelabelTranslations = async (i18n) => {
  if (whitelabelDomains.length > 0 && whitelabelDomains.includes(hostname)) {
    for (const lang in locales) {
      await loadLocaleFile(hostname + '.' + locales[lang] +'.json', locales[lang], i18n);
    }
  }
}


export default loadWhitelabelTranslations;