import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import ExitFeedback from "../lib/ExitFeedback";
import Locale from "../lib/Locale";
import Redirections from "../lib/Redirections";
import Subscription from "../lib/Subscription";
import UrlParser from "../lib/UrlParser";
import {
    setFeedbackComment,
    feedbackOptionUpdated, feedbackUpdated
} from "../redux/actions/exitFeedback";
import { chooseSubscription, setReturnUrl } from "../redux/actions/checkout";
import { useOpenFeature } from '../open-feature/OpenFeatureContext';
import Feedback0 from "./Feedback/Feedback0";
import Feedback1 from "./Feedback/Feedback1";
import Feedback2 from "./Feedback/Feedback2";
import Feedback3 from "./Feedback/Feedback3";
import Feedback4 from "./Feedback/Feedback4";
import Feedback5 from "./Feedback/Feedback5";
import {selectFeedbackComment, selectFeedbackId, selectFeedbackOption, selectFeedbackDiscountPercentage} from "../redux/selectors/exitFeedback";


const PreventNavigationDialog = () => {
    const [t, ] = useTranslation("subscription");
    const [preventNavigation, setPreventNavigation] = useState(false);
    const dispatch = useDispatch();
    const feedbackId = useSelector(selectFeedbackId);
    const discountPercentage = useSelector(selectFeedbackDiscountPercentage);
    const option = useSelector(selectFeedbackOption) || 0;
    const otherComment = useSelector(selectFeedbackComment) || '';
    const history = useHistory();
    const [ prevent, setPrevent ] = useState(true);
    const [ discountSubscription, setDiscountSubscription ] = useState();
    const [returnUrl, setReturnUrlState] = useState(
      Locale.getUrlForCurrentLocale("/bikefit/result-page")
    );
    const { openFeatureClient } = useOpenFeature();

    const [features, setFeatures] = useState({
        discountPopup: false,
        discountPopupOptions: Array(5).fill(false),
    });

    const isCheckoutOrFreeResourcePage = (targetLocation) => {
        const urlComponents = UrlParser.parsePath(targetLocation);
        const match = urlComponents?.path?.match(/.*(subscription\/payment\/data|free-resource\/).*/);
        return match?.length > 0;
    }

    const loadDiscountSubscription = () => {
        Subscription.getSubscriptions('bikefit')
          .then(subscriptions => {
              setDiscountSubscription(subscriptions.filter(subscription => subscription.highlight === true)?.[0] || null)
          })
    }

    const loadFeatureValue = async () => {
        try {
            const featurePromises = [
                openFeatureClient.getBooleanValue('discount_popup', false),
                openFeatureClient.getBooleanValue('discount_popup_percentage_option_1', false),
                openFeatureClient.getBooleanValue('discount_popup_percentage_option_2', false),
                openFeatureClient.getBooleanValue('discount_popup_percentage_option_3', false),
                openFeatureClient.getBooleanValue('discount_popup_percentage_option_4', false),
                openFeatureClient.getBooleanValue('discount_popup_percentage_option_5', false),
                openFeatureClient.getBooleanValue('free_report', false)
            ];
            const [discountPopup, option1, option2, option3, option4, option5, isFreeReport] = await Promise.all(featurePromises)
            setFeatures({
                discountPopup,
                discountPopupOptions: [option1, option2, option3, option4, option5],
                isFreeReport
            })
        } catch (error) {
            console.error('Error fetching feature values:', error);
        }
    };

    useEffect(() => {
        if (openFeatureClient) {
            loadFeatureValue();
        }
    }, [openFeatureClient]);

    const processReturnUrlParameter = () => {
        const parameterValue = (window.location.search.match(new RegExp(`[?&]returnUrl=([^&]+)`)) || [null, null])[1];

        if (parameterValue) {
            setReturnUrlState(
              Locale.getUrlForCurrentLocale(decodeURIComponent(parameterValue))
            );
        }
    };

    const handleShow = useCallback((forcePrevent = false) => {
        if (forcePrevent || prevent) {
            const dialogShown = JSON.parse(localStorage.getItem('dialogShown'));
            if(dialogShown && !forcePrevent)
            {
                setPrevent(false);
                return false;
            }
    
            try {
                localStorage.setItem('dialogShown', 'true');
                setPreventNavigation(true);
            } catch (e) {
                console.error("Failed to write to localStorage:", e);
            }
            return true;
        }
        return false;
    }, [prevent]); // Assuming `prevent` is a state or comes from props    

    const handleUnload = (event) => {
        const prevented = handleShow();

        if(prevented && event)
        {
            event.preventDefault();
            event.stopPropagation();

            const dialogText = t("Geef jouw feedback en krijg een beloning")
            event.returnValue = dialogText;
            return dialogText;
        }
    }

    const handleClose = () => {
        setPreventNavigation(false);
    }

    const onFeedback = async (values) => {
        const currentOption = typeof values === 'number' ? values : 5;
        dispatch(feedbackOptionUpdated(currentOption));
        const comment = typeof values === 'string' ? values : '';
        dispatch(setFeedbackComment(comment));

        const exitFeedback = await ExitFeedback.sendExitFeedback(
          currentOption,
          comment,
          false,
          feedbackId
        );

        dispatch(feedbackUpdated(exitFeedback));
    }

    const onCheckout = async () => {
        // fill exit_feedback table and create and return a discount token
        const exitFeedback = await ExitFeedback.sendExitFeedback(
          option,
          otherComment,
          true,
          feedbackId
        );

        dispatch(feedbackUpdated(exitFeedback));

        const discountToken = exitFeedback?.discount_token;
        if(discountToken)
        {
            handleClose();

            dispatch(chooseSubscription(discountSubscription))
            dispatch(setReturnUrl(returnUrl))
            Redirections.goTo(`/subscription/payment/data/discount/${discountToken?.token}`);
        }
    }

    useEffect(() => {
        loadDiscountSubscription();
        processReturnUrlParameter();
    }, []);

    // This is for blocking pages using navigations
    useEffect(() => {
        const advice = JSON.parse(localStorage.getItem("last_result_advice"));
        const dialogShown = JSON.parse(localStorage.getItem('dialogShown'));

        setPrevent(!advice && !dialogShown && !!features.discountPopup);

        const unblockHandle = history.block((targetLocation) => {
            const advice = JSON.parse(localStorage.getItem("last_result_advice"));
            const dialogShown = JSON.parse(localStorage.getItem('dialogShown'));

            if (!isCheckoutOrFreeResourcePage(targetLocation?.pathname)) {
                if (! advice && ! dialogShown) {
                    const prevented = handleShow(true);

                    return ! prevented;
                }
                return true;
            }
        });
        return unblockHandle
    }, [preventNavigation, features.discountPopup])

    const mouseTrack = (e) => {
        const topValue = 10;
        let mouseY = e.clientY;
        if(mouseY<topValue) {
            handleShow();
        }
    }

    // Track mouse to check if we are leaving the page?
    useEffect(() => {
        window.addEventListener("mouseout", mouseTrack);
        return () => {
            window.removeEventListener("mouseout", mouseTrack)
        }
    },[mouseTrack])

    const visibilitychange = useCallback(() => {
        if (document.visibilityState === 'hidden') {
            handleShow();
        }
    }, []);

    // Track mouse to check if we are leaving the page?
    useEffect(() => {
        window.addEventListener("visibilitychange", visibilitychange);
        return () => {
            window.removeEventListener("visibilitychange", visibilitychange)
        }
    },[visibilitychange])


    
    // This is for blocking refresh and back buttons
    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload)
        return () => {
            window.removeEventListener("beforeunload", handleUnload)
        }
    }, [handleUnload])

    const feedbackTexts = [
        t("Geef jouw feedback en krijg een beloning"),
        t("Niet goed gemeten_1"),
        t("Twijfel aan de betrouwbaarheid_2"),
        t("Prijs niet gezien_3"),
        t("De prijs is te hoog_4"),
        t("Anders_5"),
        ""
    ];

    return (
            <>
            <Modal
            className="modal exit-popup"
                show={(preventNavigation && !!discountSubscription)}
            //onHide={handleClose}
            animation={'false'}
            keyboard={'false'} 
            size="lg"
            style={{marginTop: '0px'}}
            centered
            >
            <Modal.Header className="pb-2">
                    <div>
                        <img className="img-responsive" src="/img/logos/BBA-logo-white.png" width={200} alt="Logo BestBikeAdvice" />
                        <h2 className="mt-3">
                        {feedbackTexts[option]}
                        </h2>
                    </div>
            </Modal.Header>
            <Modal.Body className="mt-2">
                {[
                        <Feedback0 onFeedback={onFeedback} onClose={handleClose} discountPopupOptions={features.discountPopupOptions} feedbackTexts={feedbackTexts} />,
                    <Feedback1 onFeedback={onFeedback} onClose={handleClose} onFinish={onCheckout} discountPercentage={discountPercentage} />,
                    <Feedback2 onFeedback={onFeedback} onClose={handleClose} onFinish={onCheckout} discountPercentage={discountPercentage} />,
                    <Feedback3 onFeedback={onFeedback} onClose={handleClose} onFinish={onCheckout} discountPercentage={discountPercentage} />,
                    <Feedback4 onFeedback={onFeedback} onClose={handleClose} onFinish={onCheckout} discountPercentage={discountPercentage} />,
                    <Feedback5 onFeedback={onFeedback} onClose={handleClose} onFinish={onCheckout} discountPercentage={discountPercentage} />,
                    ][option]}
            </Modal.Body>
        </Modal>

        </>
    )
}

export default PreventNavigationDialog