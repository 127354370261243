import PrivacyPolicy_EN from './docs/PrivacyPolicy_EN.pdf';
import PrivacyPolicy_NL from './docs/PrivacyPolicy_NL.pdf';
import React from 'react';
import {useTranslation} from "react-i18next";
import Locale from "../../lib/Locale";

const PrivacyPolicyLink = ({download}) => {
    const [t,] = useTranslation()

    const lang = Locale.getLanguage();
    let privacyPolicyFile = '';

    switch (lang) {
        case "nl":
            privacyPolicyFile = PrivacyPolicy_NL;
            break;
        case "en":
        default:
            privacyPolicyFile = PrivacyPolicy_EN;
    }

    return <a target="_blank" rel="noopener noreferrer" href={privacyPolicyFile} download={download ? 'download' : null}>{t('PrivacyPolicy')}</a>
}

export default PrivacyPolicyLink;
