import { BACK, NEXT, CHANGE_CHOICE, INIT_STEP, GOTO } from '../actions/ebikeActionTypes';
import ebikeOptions from "../../components/Ebike/ebike";
import Redirections from "../../lib/Redirections";

const initialState = {
    step: "bikeType",
    subStep: "bikeCategory",
    choices: {
        "bikeType": {},
        "engineORBatteryCapacity": {
            "lengthAndWeight": {
                "tall": "",
                "weight": ""
            }
        },
        "safety": {},
        "partSpecification": {},
    },
};

const getNextBigStep = step => {
    return (step === "bikeType") ? "engineORBatteryCapacity" : (step === "engineORBatteryCapacity") ? "safety" : (step === "safety") ? "partSpecification" : "result";
};

const getLastBigStep = step => {
    return (step === "result") ? "partSpecification" : (step === "partSpecification") ? "safety" : (step === "safety") ? "engineORBatteryCapacity" : "bikeType";
};

const goToStep = (step, subStep) => {
    if (step === 'result') {
        Redirections.goTo('/ebike/result');
    } else {
        Redirections.goTo('/ebike/' + step + '/' + subStep);
    }
};

const getBackStep = (choices, step, subStepName) => {
    let found = false, lastStep, length;

    if (step === "bikeType" && subStepName === "frameType") {
        let currentChoices = JSON.parse(localStorage.getItem("ebikeFlow"))["choices"][step];
        lastStep = { step, subStep: currentChoices["bikeCategory"] };
        found = true;
    }
    else {
        for (let ebikeOption in ebikeOptions) {
            if (ebikeOptions[ebikeOption]['choices']) {
                length = Object.values(ebikeOptions[ebikeOption]['choices']).filter(option => option['nextStep'] === subStepName).length;
            } else if (ebikeOptions[ebikeOption]["nextStep"] === subStepName) {
                length = 1;
            }
            if (length >= 1) {
                found = true;
                lastStep = { step, subStep: ebikeOption };
                break;
            }
        }
    }

    if (!found) {
        let foundStep = getLastBigStep(step);
        let foundSubStep;

        for (let ebikeOption in ebikeOptions) {
            if (ebikeOptions[ebikeOption].choices) {
                if (ebikeOptions[ebikeOption]['stepKey'] === foundStep && Object.values(ebikeOptions[ebikeOption].choices)[0]['nextStep'] === false) {
                    foundSubStep = ebikeOption;
                }
            } else {
                if (ebikeOptions[ebikeOption]['stepKey'] === foundStep && ebikeOptions[ebikeOption]['nextStep'] === false) {
                    foundSubStep = ebikeOption;
                }
            }
        }
        lastStep = { step: foundStep, subStep: foundSubStep };
    }

    return lastStep;
}

const getNextStep = (step, subStepName, choice, choices) => {
    let nextStep;

    let nextStepOption = step;
    let nextSubStepOption = false;

    if (ebikeOptions[subStepName].choices) {
        nextSubStepOption = ebikeOptions[subStepName].choices[choice]['nextStep'];
    } else {
        nextSubStepOption = ebikeOptions[subStepName]['nextStep'];
    }

    if (nextSubStepOption === false) {
        nextStepOption = getNextBigStep(step);
        switch (step) {

            case "bikeType":
                nextSubStepOption = "maximumSpeed";
                break;
            case "engineORBatteryCapacity":
                nextSubStepOption = "safety";
                break;
            case "safety":
                nextSubStepOption = "accessories";
                break;
            case "partSpecification":
                nextSubStepOption = "advice";
                break;
            default:
                break;
        }
    }
    nextStep = { step: nextStepOption, subStep: nextSubStepOption }
    return nextStep;
}

function ebike(state = initialState, action) {
    const currentChoice = (state.choices[state.step] || {})[state.subStep] || 0;

    switch (action.type) {

        case BACK:
            const backStep = getBackStep(state.choices, state.step, state.subStep);
            goToStep(backStep.step, backStep.subStep);

            return { ...state, ...backStep };

        case NEXT:
            const nextStep = getNextStep(state.step, state.subStep, currentChoice, state.choices);
            goToStep(nextStep.step, nextStep.subStep);

            return { ...state, ...nextStep };

        case CHANGE_CHOICE:
            const { step, subStep } = state;
            let newState = {...state}

            newState.choices[step][subStep] = action.choice

            return newState;

        case INIT_STEP:
            return { ...state, ...{ step: action.step, subStep: action.subStep } };

        case GOTO:
            goToStep(action.step, action.subStep);
            return { ...state, ...{ step: action.step, subStep: action.subStep } };

        default:
            return state;
    }
}

export default ebike;