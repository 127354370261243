import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Footer from '../../../components/Footer';
import StartAdvice from '../../../components/StartAdvice';

class InfoFineTune extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div className="main">
			<div className="info-page  ">
				<StartAdvice t={this.props.t}/>
				<div className="container">
					<h1>{this.props.t('Fietsafstelling finetunen')}</h1>

					<p>{this.props.t('InfoFineTune-Met het gepersonaliseerde Framefit')}.</p>

					<p>{this.props.t('InfoFineTune-Een waarschuwing vooraf:')}. </p>

					<p>{this.props.t('InfoFineTune-Bij het afstellen van je')}.</p>

					<p>{this.props.t('InfoFineTune-De fietsafstelling beïnvloedt')}:</p>

					<div className="row">
						<div className="col-3">
							{this.props.t('1. Piekvermogen')}
						</div>
						<div className="col-9">
							{this.props.t('- realiseren van jouw maximale vermogen en snelheid')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('2. Efficiëntie')}
						</div>
						<div className="col-9">
							{this.props.t('- optimaliseren voor lange ritten; minimale energie verspilling')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('3. Comfort')}
						</div>
						<div className="col-9">
							{this.props.t('- beperken van pijntjes aan nek, rug, schouders etc.')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('4. Blessurepreventie')}
						</div>
						<div className="col-9">
							{this.props.t('- voorkomen van overbelasting blessures')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('5. Bike handling')}
						</div>
						<div className="col-9">
							{this.props.t('- maximaliseren van de controle en wendbaarheid van de fiets')}
						</div>

					</div>

					<p className="margintop">{this.props.t('InfoFineTune-Per afstelmogelijkheid')}.</p>

					<p>{this.props.t('Hieronder behandelen we achtereenvolgens de volgende afstelmogelijkheden')}:</p>

					<ul>
						<li>{this.props.t('Schoenplaatjes')}</li>
						<li>{this.props.t('Crank-lengte')}</li>
						<li>{this.props.t('Zadelhoogte')}</li>
						<li>{this.props.t('Zadelbreedte')}</li>
						<li>{this.props.t('Zadel-setback')}</li>
						<li>{this.props.t('Zadelhoek')}</li>
						<li>{this.props.t('Zitlengte en drop')}.</li>
					</ul>

					<h2>{this.props.t('Schoenplaatjes')}</h2>

					<p>{this.props.t('InfoFineTune-De schoenplaatjes kun je zowel')} </p>

					<p>{this.props.t('InfoFineTune-Het midden van het schoenplaatje')}.</p>

					<p>{this.props.t('InfoFineTune-Voor de rotatie geldt in het')}.</p>

					<p><u>{this.props.t('Mogelijke klachten')}</u></p>

					<ul>
						<li>{this.props.t('InfoFineTune-Staan je plaatjes te ver')}</li>
						<li>{this.props.t('InfoFineTune-Staan je hielen te ver1')}.</li>
						<li>{this.props.t('InfoFineTune-Staan je hielen te ver2')}.</li>
					</ul>

					<h2>{this.props.t('Crank-lengte')}</h2>

					<p>{this.props.t('InfoFineTune-In de praktijk wordt er weinig')}. </p>

					<p>{this.props.t('InfoFineTune-Fiets je graag met een hoge')}.</p>

					<p><u>{this.props.t('Mogelijke klachten')}</u></p>

					<p>{this.props.t('InfoFineTune-Een te lange crank')}.</p>

					<p>{this.props.t('InfoFineTune-Er zijn cranks beschikbaar')}.</p>

					<p>{this.props.t('InfoFineTune-In Framefit is het advies')}.</p>

					<h2>{this.props.t('Zadelhoogte')}</h2>

					<p>{this.props.t('InfoFineTune-De zadelhoogte is waarschijnlijk')}.</p>

					<p>{this.props.t('InfoFineTune-Voor een maximaal piekvermogen')}.</p>

					<p>{this.props.t('InfoFineTune-De hoogste efficiency')}.</p>

					<p>{this.props.t('InfoFineTune-Een te hoog zadel kan')}</p>

					<p>{this.props.t('InfoFineTune-Om het aantal vragen binnen')}.</p>

					<p>{this.props.t('InfoFineTune-Bovenstaande uitleg is gericht')}.</p>

					<p><u>{this.props.t('Mogelijke klachten')}</u></p>

					<ul>
						<li>{this.props.t('InfoFineTune-Een te hoog zadel kan')}</li>
						<li>{this.props.t('InfoFineTune-Staat je zadel te laag')}</li>
					</ul>

					<h2>{this.props.t('Zadelbreedte')}</h2>

					<p>{this.props.t('InfoFineTune-De juiste zadelbreedte')}</p>

					<p>{this.props.t('InfoFineTune-Heb je een recreatieve')}.</p>

					<p><u>{this.props.t('Mogelijke klachten')}</u></p>

					<ul>
						<li>{this.props.t('InfoFineTune-Met een te breed zadel')}</li>
						<li>{this.props.t('InfoFineTune-Een te smal zadel')}.</li>
					</ul>

					<h2>{this.props.t('Zadel-setback')}</h2>

					<p>{this.props.t('InfoFineTune-De zadel-setback')}</p>

					<p>{this.props.t('InfoFineTune-Het is eenvoudig om zelf')}</p>

					<p><u>{this.props.t('Mogelijke klachten')}</u></p>

					<ul>
						<li>{this.props.t('InfoFineTune-Staat je zadel te ver naar voren,')}.</li>
						<li>{this.props.t('InfoFineTune-Staat je zadel te ver naar achteren,')}.</li>
					</ul>

					<p>{this.props.t('InfoFineTune-Je hebt maar een paar centimeter')}.</p>

					<h2>{this.props.t('Zadelhoek')}</h2>

					<p>{this.props.t('InfoFineTune-De richtlijn voor de hoek')}.</p>

					<p>{this.props.t('InfoFineTune-Je kunt de optimale hoek')}.</p>

					<p>{this.props.t('InfoFineTune-Redenen om de punt van')}:</p>

					<ul>
						<li>{this.props.t('InfoFineTune-Als je bekken niet goed')}.</li>
						<li>{this.props.t('InfoFineTune-Als je te veel steunt')}.</li>
						<li>{this.props.t('InfoFineTune-Als je last van je')}.</li>
					</ul>

					<p>{this.props.t('InfoFineTune-Redenen om de punt van je')}:</p>

					<ul>
						<li>{this.props.t('InfoFineTune-Als je niet stabiel zit')}.</li>
					</ul>

					<h2>{this.props.t('Zitlengte en drop')}</h2>

					<p>{this.props.t('InfoFineTune-Er is veel wetenschappelijk')}. </p>

					<p>{this.props.t('InfoFineTune-De positie van het stuur')}.</p>

					<p>{this.props.t('InfoFineTune-Er zijn dus meerdere')}.</p>

					<p>{this.props.t('InfoFineTune-In één van onze vragen moet je een')}:</p>

					<div className="row">
						<div className="col-3">
							{this.props.t('Voorkeur snelheid')}
						</div>
						<div className="col-3">
							{this.props.t('-> competitieve zithouding')}
						</div>
						<div className="col-6">
							{this.props.t('= diepe en lange zitpositie')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('Compromis')}
						</div>
						<div className="col-3">
							{this.props.t('-> sportieve zithouding')}
						</div>
						<div className="col-6">
							{this.props.t('= gemiddelde zitpositie')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('Voorkeur comfort')}
						</div>
						<div className="col-3">
							{this.props.t('-> recreatieve zithouding')}
						</div>
						<div className="col-6">
							{this.props.t('= rechterop en kortere zitpositie')}
						</div>
					</div>

					<p className="margintop">{this.props.t('InfoFineTune-Helaas is niet iedere')}</p>

					<p>{this.props.t('InfoFineTune-Wanneer je op onze adviespagina')}</p>

					<p>{this.props.t('InfoFineTune-Hieronder vind je een korte')}</p>

					<p><u>{this.props.t('Stuurpen: lengte en hoek')}</u></p>

					<p>{this.props.t('InfoFineTune-De stuurpen kun je in diverse lengten')}.</p>

					<p><u>{this.props.t('Spacers')}</u></p>

					<p>{this.props.t('InfoFineTune-Spacers zijn ringen die je boven')}.</p>

					<p><u>{this.props.t('Stuur rise')}</u></p>

					<p>{this.props.t('InfoFineTune-Het stuur van een mountainbike')}.</p>

				</div>

				<Footer/>
</div>
			</div>
		);
	}
}

export default withTranslation('infoFineTune')(InfoFineTune);
