import React from "react";
import {Trans} from "react-i18next";
import pubLogo from "../../../Homes/BikefitHome/img/publicatie-logo.png";

function Publications() {
  return (
    <div style={{ textAlign: "left" }}>
      <img alt="" src={pubLogo} />
      <Trans i18nKey="infoReview:REVIEWS_PUBLICATION">
          <p>
            Samen met het Meander Medisch Centrum zijn we een onderzoek gestart
            waarin we de kwaliteit van ons bikefit algoritme objectief gaan
            vergelijken met de kwaliteit van een dynamische bikefit door een bikefit
            expert.
          </p>
          <p>
            Wij zijn ervan overtuigd dat onze online bikefit voor de meeste mensen
            even bruikbaar is als het advies van een bikefit expert. We hopen dat
            dit onderzoek dat gaat bevestigen.
          </p>
          <p>
            Zodra het onderzoek afgerond en gepubliceerd is zullen we hier een link naar de
            publicatie plaatsen.
          </p>
      </Trans>
    </div>
  );
}

export default Publications;
