const NEXT = "NEXT";
const BACK = "BACK";
const CHANGE_CHOICE = "CHANGE_CHOICE";
const CHOICES_FETCHED = "CHOICES_FETCHED";
const INIT_STEP = "INIT_STEP";
const GOTO = "GOTO";
const START_FROM_BIKEFINDER = "START_FROM_BIKEFINDER";
const ANGLE_ADVICE_FETCHED = 'ANGLE_ADVICE_FETCHED';
const START = "START";

export {
  NEXT,
  BACK,
  CHANGE_CHOICE,
  CHOICES_FETCHED,
  INIT_STEP,
  GOTO,
  START_FROM_BIKEFINDER,
  ANGLE_ADVICE_FETCHED,
  START,
};
