import Api from "./Api";

class Reviews {
  getCustomerReviews = () => {
    return Api.get("/review/customer");
  };

  getExpertReviews = () => {
    return Api.get("/review/professional");
  };
}
// eslint-disable-next-line
export default new Reviews();
