import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Footer from '../../components/Footer';
import Auth from "../../lib/Auth";

class ConfirmAccount extends Component {
	constructor(props) {
		super(props);
		let requestUrl = props.history.location.pathname;
		let confirmCode = requestUrl.split("/confirm/")[1].split("/")[0];
		let userEmail = requestUrl.split("/email/")[1].split("/")[0];

		this.state = {
			confirmationCode: confirmCode,
			userEmail: userEmail,
		    loading: true,
		    success: false
		};
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.loadConfirm()
	}
	render() {
		return(
			<div className = "main">
				<div className="container form-page">
					{ ! this.state.loading && this.state.success &&
						<div>
							<h3>{this.props.t('ConfirmAccount_Activated')}</h3>
							<span>{this.props.t('ConfirmAccount_Activated_Sub')}</span>
						</div>
					}
					{ ! this.state.loading && ! this.state.success &&
						<div>
							<h3>{this.props.t('ConfirmAccount_NotActivated')}</h3>
							<span>{this.props.t('ConfirmAccount_NotActivated_Sub')}</span>
						</div>
					}
				</div>
				<Footer />
			</div>
		);
	}
	loadConfirm = () => {
		Auth.confirm(this.state.confirmationCode, this.state.userEmail)
			.then(() => this.setState({success: true}))
			.finally(() => this.setState({loading: false}))
		;
	}


}

export default withTranslation('confirmAccount')(ConfirmAccount);
