import React from 'react';

const HeaderComponent = ({title, header_level}) => {
    const HeaderType = header_level
    return (
        <HeaderType>
            {title}
        </HeaderType>
    );
}
export default HeaderComponent;
