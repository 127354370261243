import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MeasurementList from '../../components/Bikefit/Result/MeasurementList';
import { useSelector } from 'react-redux';
import { selectBikefitChoices } from '../../redux/selectors/bikefit';
import { getResultVars } from '../../components/Bikefit/lib/bikefit';
import Api from '../../lib/Api';
import Redirections from '../../lib/Redirections';
import { Link } from "react-router-dom";
import Locale from '../../lib/Locale';

const FreeReport = () => {
  const [t] = useTranslation("bikefitResult");
  const choices = useSelector(selectBikefitChoices);
  const [qualityScore, setQualityScore] = useState("");
  const [freeReport, setFreeReport] = useState({});

  useEffect(() => {
    const url = "/get_measurements_quality";
    const resultVars = getResultVars(choices);

    Api.get(url, resultVars)
      .then((response) => {
        setQualityScore(response.data.data.quality_score);
      })
      .catch((response) => {
        console.log("error");
      });
  }, []);

  useEffect(() => {
    const url = "/bikefit-free-report";
    const resultVars = getResultVars(choices);

    Api.post(url, resultVars)
      .then((response) => {
        setFreeReport(response.data.response.data);
      })
      .catch((response) => {
        console.log("error", response);
      });
  }, [])

  return (
    <div className="container main resultContainer bikefit">
      <div className="d-flex justify-content-center align-items-center py-2 bg-yellow mb-md-4 mb-3 mt-2">
        <img className="w-25" src="img/logo.png" alt="logo" />
      </div> 

      <div className="row justify-content-center row-eq-height">
        <div className="col-12">
          <h2>{t("BIKEFIT_ADVICE_FOR")}</h2>
          <p>{t("PERSONAL_FRAMEFIT_BIKE_ADVICE")}</p>

          <h3>{t("YOUR_INPUT")}</h3>
          <p>
            {t("BASIS_FOR_ADVICE", {
              qualityScore:
                qualityScore && t("QUALITY_MEASUREMENTS_" + qualityScore),
            })}
          </p>
          <br />
          <MeasurementList choices={choices} /> 
          <br />
          <div>
            <div className='row'>
              <div className='col-md-6'>
                <h3>{t("YOUR_BASIC_BIKE_FIT_INSIGHTS")}</h3>
                <p>
                  {t("RIDING_POSITION_TOUCHPOINTS")}
                </p>
                <p>
                  {t("BIKE_FIT_COMPARISON")}
                </p>
              </div>
              <div className='col-md-6 d-flex align-items-center'>
                <img className='w-100' src="img/road_bike_bikefit_abc.png" alt="" />
              </div>
            </div> 
            {
              !freeReport.saddle_height?.skip &&
              <p>
                <strong>A. {t("SADDLE_HEIGHT_")}</strong><br />
                  <Trans i18nKey='bikefitResult:Longer_Legs,_Higher_Saddle'>
                    Because you have <b>{{ longer_shorter_legs: t(`legs.${freeReport.saddle_height?.longer_shorter_legs || "shorter"}`) }} legs</b> than average, your saddle position is <b>{{ saddlePosition: `${freeReport.saddle_height?.amount || 0} mm ${t(`saddle.${freeReport.saddle_height?.higher_lower || "lower"}`)}` }} </b>compared to the average person of your height.
                  </Trans>
              </p>
            }
            <p>
              <strong>B. {t("SADDLE_SETBACK_")}</strong><br />
              {
                !freeReport.saddle_setback?.skip ?
                  <Trans i18nKey='bikefitResult:Longer_Thighs,_Larger_Setback'>
                    Because you have <b>{{ thighSize: t(`thigh.${freeReport.saddle_setback?.longer_shorter_thighs}`) }}</b>than average your saddle setback is <b>{{ saddleSetback: `${freeReport.saddle_setback?.amount || 0} mm ${t(`saddle_setback.${freeReport.saddle_setback?.larger_smaller || "smaller"}`)}` }}</b> compared to the average person of your height
                  </Trans> :
                  t("No_deviances")
              }
            </p>
            {
              !freeReport.saddle_to_bar_distance?.skip &&
              <p>
                <strong>C. {t("SADDLE_TO_BAR_DISTANCE")}</strong><br />
                  <Trans i18nKey='bikefitResult:Longer_Torso,_Larger_Saddle_to_Bar_Distance'>
                    You need a <b>{{ saddleSize: `${freeReport.saddle_to_bar_distance?.amount || 0} mm ${t(`saddle_to_bar_distance.${freeReport.saddle_to_bar_distance?.longer_shorter || "shorter"}`)}` }}</b> saddle to bar distance compared to the average person of your height.
                  </Trans>

                  {freeReport.saddle_to_bar_distance?.reason?.length > 0 && (
                    <>
                      <br />
                      {t("because_you_have") + ": "}
                      <b>
                        {freeReport.saddle_to_bar_distance.reason
                          .map((r) => t(`reason.${r}`))
                          .join(', ')}
                      </b>
                    </>
                  )}
              </p>
            }
            {
              !freeReport.saddle_to_bar_drop?.skip &&
              <p>
                <strong>D. {t("SADDLE_TO_BAR_DROP")}</strong><br />
                  <Trans i18nKey='bikefitResult:Shorter_Torso,_Larger_Saddle_to_Bar_Drop'>
                    You need a <b>{{ saddleToBar: `${freeReport.saddle_to_bar_drop?.amount || 0} mm ${t(`saddle_to_bar_drop.${freeReport.saddle_to_bar_drop?.larger_smaller || "smaller"}`)}` }}</b> saddle to bar drop compared to the average person of your height.
                  </Trans>
                {freeReport.saddle_to_bar_drop?.reason?.length > 0 && (
                  <>
                    <br />
                      {t("because_you_have") + ": "}
                      <b>
                      {freeReport.saddle_to_bar_drop.reason
                        .map((r) => t(`reason.${r}`))
                        .join(', ')}
                      </b>
                  </>
                )}
              </p>
            }
            <h3>{t("NEEDED_FRAME_SIZE_TITLE")}</h3>
            <p>{t("Frame_Size_Selection_Warning")}</p>
            <p>{t("Frame_Size_Explanation")}</p>
            <p>{t("Handlebar_Position_Importance")}</p>
            <p>{t("Frame_Size_Recommendation")}</p>
            <br />

            <h3>{t("Full_Bike_Fit_Report_Overview")}</h3>
            <p>{t("Bike_Fit_Report_Interest")}</p>
            <button
              id="printPageButton"
              className="btn btn-secondary"
              onClick={() => Redirections.goTo("/subscription/payment/bikefit")}
            >
              {t("Buy_Your_Full_Report")}
            </button>
            <br />
            <br />
            <br />

            <p>{t("Example_Report_Information")}</p>
            <Link className="btn btn-default" to={Locale.getUrlForCurrentLocale("/subscription/payment/bikefit/#tab-section")}>
              {t("View_Example_Report")}
            </Link>
            <br />
            <br />
            <br />

            <p>{t("RESULT_PAGE_WISH")}</p>
            <div className='mb-5'>
              <img className="result-bottom-logo" src="img/logo.png" alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeReport;