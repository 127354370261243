import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ReviewMultiCarousel.css";

const ExpertReviewMultiCarousel = ({ interval, reviews = [], component }) => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const CustomLeftArrow = ({ onClick }) => {
        return (
            <button className={`left carousel-arrow top`} onClick={onClick}>
                <span className="fa fa-chevron-left"></span>
            </button>
        )
    }

    const CustomRightArrow = ({ onClick }) => {
        return (
            <button className={`right carousel-arrow top`} onClick={onClick}>
                <span className="fa fa-chevron-right"></span>
            </button>
        )
    }
    
    return (
        <div className="carousel-wrapper-div">
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={interval}
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
            >
                {reviews.map((review, index) => (
                    <div key={`slide-${index}`}>{component(review)}</div>
                ))}
            </Carousel>
        </div>
    );
};

export default ExpertReviewMultiCarousel;
