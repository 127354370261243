import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Footer from '../../../components/Footer';
import StartAdvice from '../../../components/StartAdvice';

class InfoMtbTypes extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div className="main">
			<div className="info-page">
				<StartAdvice t={this.props.t}/>
				<div className="container">
					<h1>{this.props.t('De verschillende soorten mountainbikes')}</h1>

					<p>{this.props.t('Framefit maakt onderscheid tussen 6 soorten mountainbikes:')} </p>

					<div className="row">
						<div className="col-3">
						{this.props.t('Cross Country')}
						</div>
						<div className="col-9">
							{this.props.t('– snel, licht en wendbaar')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('Trail')}
						</div>
						<div className="col-9">
							{this.props.t('– alleskunner voor klimmen en dalen')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
						{this.props.t('Enduro')}
						</div>
						<div className="col-9">
							{this.props.t('– een echte daler, waarmee je ook naar boven kan fietsen')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('Downhill')}
						</div>
						<div className="col-9">
							{this.props.t('– een pure daler, naar boven neem je een lift')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('Fat bike')}
						</div>
						<div className="col-9">
							{this.props.t('– brede banden voor losse ondergrond')}
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							{this.props.t('Dirt bike')}
						</div>
						<div className="col-9">
							{this.props.t('– voor bike parks met veel jumps')}
						</div>
					</div>
					<br/>
					<p>{this.props.t('InfoMtbTypes-De Cross Country')}
					{this.props.t('InfoMtbTypes-De verschillende soorten mountainbikes')}</p>
					<img className="img-responsive" src="/img/01_bike_type_overview.jpg" alt="Overzicht mountainbikes"/>
					<h2>{this.props.t('Cross Country mountainbikes')}</h2>
					<img className="img-responsive" src="/img/Vraag-3Ab.jpg" alt="Cross country mountainbikes"/>
					<p>{this.props.t('InfoMtbTypes-Bij de cross country mountainbike')}.</p>
					<p>{this.props.t('InfoMtbTypes-De meeste XC-mountainbikes')}.</p>
					<p>{this.props.t('InfoMtbTypes-De XC-mountainbike is de fiets voor alle')}:</p>
					<ul>
						<li>{this.props.t('Mountainbike-wedstrijden in Nederland, van strandraces tot wedstrijden op de Veluwe of in Limburg')}</li>
						<li>{this.props.t('De XC-worldcup-wedstrijden op meer uitdagende buitenlandse parcoursen')}</li>
						<li>{this.props.t('Lange meerdaagse marathonwedstrijden, zoals de Cape Epic')}.</li>
					</ul>
					<h2>{this.props.t('Trail mountainbikes')}</h2>
					<img className="img-responsive" src="/img/Vraag-3Bb.jpg" alt="Trail mountainbikes"/>
					<p>{this.props.t('InfoMtbTypes-Trail Bikes zijn ontworpen')}.</p>
					<p>{this.props.t('InfoMtbTypes-Ben je op zoek naar een')}.</p>
					<p>{this.props.t('InfoMtbTypes-Vrijwel alle Trail Bikes')}.</p>
					<p>{this.props.t('InfoMtbTypes-Er zijn geen specifieke wedstrijddisciplines voor Trail Bikes')}.</p>

					<h2>{this.props.t('Enduro-mountainbikes')}</h2>
					<img className="img-responsive" src="/img/Vraag-3Cc.jpg" alt="Enduro-mountainbikes"/>
					<p>{this.props.t('InfoMtbTypes-De Enduro-mountainbike is gemaakt')}. </p>
					<p>{this.props.t('InfoMtbTypes-Enduro-wedstrijden hebben een')}.</p>
					<p>{this.props.t('InfoMtbTypes-Verwar Enduro niet met')}.</p>

					<h2>{this.props.t('Downhill-mountainbikes')}</h2>
					<img className="img-responsive" src="/img/Vraag-3Cd.jpg" alt="Downhill-mountainbikes"/>
					<p>{this.props.t('InfoMtbTypes-Bij Downhill (ook wel Gravity genaamd)')}.</p>
					<p>{this.props.t('InfoMtbTypes-Bij Downhill draag je uitgebreide')}.</p>
					<p>{this.props.t('InfoMtbTypes-Downhill is een speciale')}.</p>

					<h2>{this.props.t('Fat bikes')}</h2>
					<img className="img-responsive" src="/img/Vraag-4Cb.jpg" alt="Fat bikes"/>
					<p>{this.props.t('InfoMtbTypes-Fat bikes hebben extreem brede banden')}.</p>
					<p>{this.props.t('InfoMtbTypes-De meeste Fat bikes')}.</p>

					<h2>{this.props.t('Dirt bikes')}</h2>
					<img className="img-responsive" src="/img/Vraag-3Bc.jpg" alt="Dirt bikes"/>
					<p>{this.props.t('InfoMtbTypes-Dirt bikes lijken')}.
					{this.props.t('Framefit biedt nog geen uitgebreid advies met betrekking tot Dirt bikes')}.</p>

				</div>

				<Footer t={this.props.t}/>
			</div>
			</div>
		);
	}
}

export default withTranslation('infoMtbTypes')(InfoMtbTypes);
