import axios from 'axios';
import { config } from '../config';

class CmsApi {
    constructor() {
        this.api = axios.create({
            baseURL: config.cmsApiUrl
        });
    }

    async get(url, options = {}) {
        try {
            return await this.api.get(url, options);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return null;
            }
            // Handle errors
            console.error(`Error in API request: ${error.message}`);
            throw error;
        }
    }
}

export default new CmsApi();
