import Locale from "./Locale"

class Head {
    addCanonicalTag() {
        var headID = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.rel = "canonical";
        link.href = Locale.getUrlForCurrentLocale(window.location.href);
        link.type = "text/html";
        headID.appendChild(link);
    }

    addHreflangLinks() {
        let links = document.querySelectorAll("link[rel='alternate']")
        links.forEach(link => {
            link.remove();
        })
        let urls = {
            'nl-NL': Locale.getUrlForLocale('nl', 'nl'),
            'en-GB': Locale.getUrlForLocale('gb', 'en'),
            'en-US': Locale.getUrlForLocale('us', 'en'),
            'en-CA': Locale.getUrlForLocale('ca', 'en'),
            'en-DE': Locale.getUrlForLocale('de', 'en'),
            'en-IT': Locale.getUrlForLocale('it', 'en'),
            'en-NL': Locale.getUrlForLocale('int', 'en')
        }
        Object.keys(urls).forEach((key, index) => {
            this._addHreflangLink(key, urls[key])
        })
    }

    _addHreflangLink(hreflang, href) {
        var headID = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.rel = "alternate";
        link.href = href;
        link.hreflang = hreflang;
        link.type = "text/html";
        headID.appendChild(link);
    }
}
// eslint-disable-next-line
export default new Head();
