import {
  BACK,
  NEXT,
  CHANGE_CHOICE,
  CHOICES_FETCHED,
  INIT_STEP,
  START_FROM_BIKEFINDER,
  ANGLE_ADVICE_FETCHED,
  GOTO,
  START,
} from "../actions/bikefitActionTypes";
import bikeFitOptions from "../../bikeFitOptions.json";
import UrlParser from "../../components/Bikefit/UrlParser";
import Redirections from "../../lib/Redirections";
import { config } from "../../config";
import Subscription from "../../lib/Subscription";
import { getResultVars } from "../../components/Bikefit/lib/bikefit";
import Api from "../../lib/Api";

const initialState = {
  step: "stepA",
  subStep: "bikeType",
  choices: {
    stepA: {},
    stepB: {
      priorities: {
        1: 2,
        2: 2,
        3: 2,
        4: 2,
        5: 2,
      },
    },
    stepC: {},
    stepD: {},
  },
  fromBikeFitFlow: false,
  fromBikefinderResult: false,
  bikeCat: "",
  minAngle: 35,
  maxAngle: 55
};

const getNextBigStep = (step) => {
  return step === "stepA"
    ? "stepB"
    : step === "stepB"
      ? "stepC"
      : step === "stepC"
        ? "stepD"
        : "results";
};

const getLastBigStep = (step) => {
  return step === "results"
    ? "stepD"
    : step === "stepD"
      ? "stepC"
      : step === "stepC"
        ? "stepB"
        : "stepA";
};

const getBackStep = (choices, step, subStepName, isWhiteLabelDomain = false) => {

  // find last step 
  let found = false, lastStep;

  for (let bikeFitOption in bikeFitOptions) {
    if (
      bikeFitOption !== "nav_Bar" &&
      Object.values(bikeFitOptions[bikeFitOption]["data"]).filter(
        (option) => option["substep"][0] === subStepName
      ).length >= 1
    ) {
      found = true;
      lastStep = { step, subStep: bikeFitOption };
    }
  }

  // for priorities, redirect to flexibility
  if (subStepName === 'priorities') {
    found = true;
    lastStep = { step, subStep: 'flexibility' };

    // for suspension redirect to partSizes
  } else if (subStepName === 'suspension') {
    found = true;
    lastStep = { step, subStep: 'partSizes' };
  }

  // redirect if not found
  if (!found) {
    let foundStep = getLastBigStep(step);
    let foundSubStep;

    if (subStepName === "sittingPositionMTB") {
      // foundSubStep =
      //   bikeFitOptions["bikeType"].data[choices.stepA.bikeType - 1].substep[0];
      foundSubStep =
        bikeFitOptions["bikeType"].data[1].substep[0];
    } else if (subStepName === "sittingPositionRoad") {
      foundSubStep =
        bikeFitOptions["bikeType"].data[0].substep[0];
    } else {
      for (let bikeFitOption in bikeFitOptions) {
        if (
          bikeFitOptions[bikeFitOption]["step"] === foundStep &&
          bikeFitOptions[bikeFitOption].data[0]["substep"][0] === ""
        ) {
          foundSubStep = bikeFitOption;
        }
      }
    }
    lastStep = { step: foundStep, subStep: foundSubStep };
  }

  return lastStep;
};

const getNextStep = (step, subStepName, choice, choices, isWhiteLabelDomain = false) => {

  let nextStep;


  switch (subStepName) {
    case "physicalComplaints":
      nextStep = { step: "stepB", subStep: "priorities" };
      break;
    case "priorities":
      nextStep = { step: "stepB", subStep: "backAngle" };
      break;
    case "backAngle":
      nextStep = { step: "stepC", subStep: "bodyMeasurement" };
      break;
    case "partSizes":
      if (choices["stepA"]["bikeType"] === 1) {
        nextStep = { step: getNextBigStep(step) };
      } else {
        nextStep = { step: "stepD", subStep: "suspension" };
      }
      break;
    case "suspension":
      nextStep = { step: getNextBigStep(step) };
      break;
    case "mountainBikeCategory":
      nextStep = { step: "stepB", subStep: "sittingPositionMTB" };
      break;
    case "roadBikeCategory":
      nextStep = { step: "stepB", subStep: "sittingPositionRoad" };
      break;
    default:

      let nextSubStepOption;

      // for flexibility, go to priorities directly
      if (subStepName === "flexibility") {
        nextStep = { step: "stepB", subStep: "priorities" };
      } else {

        // for step A and step B - find next step by choice
        if (step === "stepA" || step === "stepB") {

          nextSubStepOption = bikeFitOptions[subStepName].data.filter(
            (option) => option.id === choice
          )[0]["substep"][0];
        } else {
          nextSubStepOption = bikeFitOptions[subStepName].data[0]["substep"][0];
        }

        let nextStepOption = step;

        if (nextSubStepOption === "") {
          nextStepOption = getNextBigStep(step);
        }

        nextStep = { step: nextStepOption, subStep: nextSubStepOption };
      }

  }

  return nextStep;
};

const goToStep = (step, subStep) => {
  if (step === "results") {
    const isUserInfoPage = JSON.parse(localStorage.getItem('isUserInfoPage'))
    if (isUserInfoPage){
      Redirections.goTo(config.bikefitUrl + "/bikefit/user-info");
    }else{
      Redirections.goTo(config.bikefitUrl + "/bikefit/result-page");
    }
  } else {
    Redirections.goTo(
      config.bikefitUrl +
      "/bikefit/" +
      UrlParser.getStepUrlId(step) +
      "/" +
      subStep
    );
  }
};

const setDefaults = state => {
  if (state.choices.stepA['bikeType'] === 2) {
    state = setDefaultMtb(state)
  }

  if (state.choices.stepA['bikeType'] === 1) {
    state = setDefaultRoad(state)
  }

  state = setDefaultCrank(state)

  return state
}

const setDefaultCrank = (state) => {
  if ("legLength" in state.choices.stepC
    && state.choices.stepC["legLength"]
    && state?.choices?.["stepD"]?.["partSizes"]
    && 2 in state.choices["stepD"]["partSizes"] === false
  ) {
    let legLength = state.choices.stepC["legLength"];

    if (legLength < 826) {
      state.choices["stepD"]["partSizes"][2] = 170;
    } else if (legLength <= 855) {
      state.choices["stepD"]["partSizes"][2] = 172.5;
    } else {
      state.choices["stepD"]["partSizes"][2] = 175;
    }
  }

  return state
}

const setDefaultMtb = (state) => {
  const choice = state.choices['stepA']['mountainBikeCategory'];
  const doCalculate = 'suspension' in state.choices["stepD"] === false
    && choice !== undefined
    && state.subStep !== 'suspension';

  if (doCalculate) {
    if (choice === 1) {
      state.choices["stepD"]["suspension"] = { 1: 100, 2: 0 };
    } else if (choice === 2) {
      state.choices["stepD"]["suspension"] = { 1: 140, 2: 140 };
    } else {
      state.choices["stepD"]["suspension"] = { 1: 0, 2: 0 };
    }
  }

  if (
    "partSizes" in state.choices["stepD"] === false
    || 1 in state.choices["stepD"]["partSizes"] === false
    || 4 in state.choices["stepD"]["partSizes"] === false
  ) {
    let crankLength = ((state.choices.stepD || {}).partSizes || {})[2] || false;
    state.choices["stepD"]["partSizes"] = { 1: 274, 4: 30 };
    if (crankLength) {
      state.choices["stepD"]["partSizes"][2] = crankLength
    }
  }

  return state;
}

const setDefaultRoad = (state) => {
  if ("partSizes" in state.choices["stepD"] === false
    || 1 in state.choices["stepD"]["partSizes"] === false
    || 3 in state.choices["stepD"]["partSizes"] === false
  ) {
    let crankLength = ((state.choices.stepD || {}).partSizes || {})[2] || false;

    state.choices["stepD"]["partSizes"] = { 1: 274, 3: 80 };

    if (crankLength) {
      state.choices["stepD"]["partSizes"][2] = crankLength
    }
  }

  return state;
}

function getCurrentChoice(state) {
  let subStepKey = bikeFitOptions[state.subStep]?.localKey || state.subStep;

  return (state.choices[state.step] || {})[subStepKey] || 0;
}

function bikefit(state = initialState, action) {
  const currentChoice = getCurrentChoice(state);

  switch (action.type) {
    case ANGLE_ADVICE_FETCHED:
      return { ...state, advicedAngle: action.advicedAngle, minAngle: action.minAngle, maxAngle: action.maxAngle }
    case BACK:
      const backStep = getBackStep(state.choices, state.step, state.subStep, action.isWhiteLabelDomain);
      goToStep(backStep.step, backStep.subStep);
      return { ...state, ...backStep };
    case NEXT:

      const trajectory = bikeFitOptions["bikeType"].data
        .filter((option) => state.choices.stepA["bikeType"] === option.id)[0]
        .apiValue.toLowerCase();

      let returnUrl;

      if (
        state.fromBikeFitFlow &&
        ((state.subStep === "suspension" && trajectory === "mtb")
          || (state.subStep === "partSizes" && trajectory === "race"))
      ) {
        // back to bikefinder.
        if (trajectory === "mtb") {
          if (state.fromBikefinderResult) {
            returnUrl = config.mtbUrl + "/mtb/advice/result";
          } else {
            returnUrl = config.mtbUrl + "/mtb/advice/price";
          }
        } else {

          if (state.fromBikefinderResult) {
            returnUrl = config.raceUrl + "/road/advice/result";
          } else {
            returnUrl = config.raceUrl + "/road/advice/price";
          }
        }

        Subscription.checkSubscription("bikefit").then((result) => {
          if (result) {
            Redirections.goTo(returnUrl);
          } else {
            // request an advanced fit so we have a record of it in generated results, so a report
            // will automatically be sent.
            Api.get("/advanced-fit", getResultVars(state.choices))
              .then((response) => {
                Subscription.paywall('bikefit', null, returnUrl);
              })
          }
        });

        return state;
      }

      const nextStep = getNextStep(
        state.step,
        state.subStep,
        currentChoice,
        state.choices,
        action?.isWhiteLabelDomain
      );

      goToStep(nextStep.step, nextStep.subStep);
      return { ...state, ...nextStep };
    case CHOICES_FETCHED:
      return { ...state, choices: action.choices }
    case CHANGE_CHOICE:
      let newState = Object.assign({}, state);

      const choiceKey = bikeFitOptions[state.subStep]?.['localKey'] || state.subStep;

      newState["choices"][state.step][choiceKey] = action.choice;

      if (state.subStep !== 'legLength') {
        setDefaults(newState)
      }

      return newState;
    case INIT_STEP:
      let newInitState = {
        ...state,
        step: action.step,
        subStep: action.subStep,
      };

      if (state.subStep !== 'legLength') {
        setDefaults(newInitState)
      }

      if (action.subStep === "bikeType") {
        newInitState["fromBikeFitFlow"] = false;
      }

      return newInitState;

    case START:
      return {
        ...state,
        fromBikeFitFlow: false,
      };

    case START_FROM_BIKEFINDER:
      const bikeTypeChoice = bikeFitOptions["bikeType"]["data"].filter(
        (choice) =>
          choice.apiValue.toLowerCase() === action.bikeCat.toLowerCase()
      )[0];
      const bikeType = bikeTypeChoice.id;
      const step2Key = bikeTypeChoice.substep[0];
      const step2Value = bikeFitOptions[step2Key]["data"].filter(
        (choice) => choice.apiValue === action.bikeType
      )[0].id;

      let stepA = { bikeType };
      stepA[step2Key] = step2Value;

      let stepD = state.choices.stepD || {}

      let nextState = {
        ...state,
        bikeCat: action.bikeCat.toLowerCase(),
        choices: { ...state.choices, stepA, stepD },
        fromBikeFitFlow: true,
        fromBikefinderResult: action.fromResultPage,
      };

      nextState = setDefaults(nextState);

      return nextState;
    case GOTO:
      goToStep(action.step, action.subStep);
      return { ...state, ...{ step: action.step, subStep: action.subStep } };
    default:
      return state;
  }
}

export default bikefit;
