import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Footer from '../../components/Footer';
import './style.css';
import Api from "../../lib/Api";

class BikefitPartners extends Component {
	componentDidMount() {
		this.fetchBikeFitters();
	}

	constructor(props) {
		super(props);

		this.state = {
			bikeFitters: []
		};
	}

	fetchBikeFitters() {
		Api.get('/bikefitters').then(response => {
			this.setState({bikeFitters: (response.data || {}).data || []});
		});
	}

	render() {
		return(
			<div className = "main">
				<div className="container info-page">
					<div className="row mb-3">
						<div className="col-12">
							<h1>{this.props.t('BikePartners_Title')}</h1>
						</div>
					</div>
					<div className="row mb-3">
						<div className="col-12">
							<p>{this.props.t('BikePartners_Text')}</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3 col-sm-12 map-wrapper">
							<img className="map-image" alt={this.props.t('BikePartners_Map')} src="/img/bikefitters.jpg" />
							{
								this.state.bikeFitters.map(fitter => (
									<div className="map-marker" key={fitter.number + '-' + fitter.name}
										 style={{bottom: fitter.y + '%', left: fitter.x + '%'}}>
									<div className="map-marker-symbol"></div>
									<div className="map-marker-number">{fitter.number}</div>
								</div>
								))
							}
						</div>
						<div className="col-md-9 col-sm-12">
							<table className="table table-striped table-responsive">
								<thead className="thead-dark">
									<tr>
										<th>{this.props.t('BikePartners_City')}</th>
										<th>{this.props.t('BikePartners_Bikefitter')}</th>
										<th>{this.props.t('BikePartners_Website')}</th>
									</tr>
								</thead>
								<tbody>
								{
									this.state.bikeFitters.map(fitter => (
										<tr key={fitter.number + '-' + fitter.name}>
											<td>{fitter.number}.&nbsp;{fitter.city}</td>
											<td>{fitter.name}</td>
											<td><a target="_blank" href={fitter.website} rel="noopener noreferrer">{fitter.website}</a></td>
										</tr>
									))
								}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}


}

export default withTranslation('bikefitPartners')(BikefitPartners);
