import React, {Component} from 'react';
import Footer from '../../components/Footer';
import "./style.css"

class Index extends Component {
    render() {
        return (
            <div className="main">
                <div className="container form-page">
                    <div className="header-image"><img src="/img/logo.png" alt="contact"/></div>
                    <form onSubmit={this.props.handleSubmit}>
                        <h3>{this.props.title}</h3>
                        {
                            this.props.subTitle !== '' &&
                            <p className="subtitle">{this.props.subTitle}</p>
                        }
                        {this.props.children}
                    </form>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Index;
