import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {config} from "../../config";

class StartAdvice extends Component {
	render() {
		return(
			<div className="startAdvice">
			{config.defaultTraject === 'MTB' &&
				<Link to="/mtb/advice/experience" className="adviceBtn btn btn-primary">{this.props.t('Start advies')}</Link>
			}
			{config.defaultTraject === 'Race' &&
				<Link to="/road/advice/experience" className="adviceBtn btn btn-primary">{this.props.t('Start advies')}</Link>
			}
			</div>
		);
	}
}

export default withTranslation('startAdvice')(StartAdvice);