const SET_BIKE = "SET_BIKE";
const SET_BIKE_VALUE = "SET_BIKE_VALUE";
const SET_BIKE_SELECTION_VALUE = "SET_BIKE_SELECTION_VALUE";
const SET_CATEGORY = "SET_CATEGORY"

export {
  SET_BIKE,
  SET_BIKE_VALUE,
  SET_BIKE_SELECTION_VALUE,
  SET_CATEGORY
};
