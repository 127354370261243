class Popup
{
    constructor() {
        this.showListeners = [];
        this.closeListeners = [];
    }

    registerPopupOpenListener = (listener) => {
        this.showListeners.push(listener);
    };

    registerPopupCloseListener = (listener) => {
        this.closeListeners.push(listener);
    };

    show = (component, smallDialog) => {
        smallDialog = smallDialog || false;
        for (let listener in this.showListeners) {
            if (this.showListeners.hasOwnProperty(listener)) {
                this.showListeners[listener](component, smallDialog);
            }
        }
    };

    close = () => {
        for (let listener in this.closeListeners) {
            if (this.closeListeners.hasOwnProperty(listener)) {
                this.closeListeners[listener]();
            }
        }
    };
}
// eslint-disable-next-line
export default new Popup();