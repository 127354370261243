import {
  SET_BIKE,
  SET_BIKE_VALUE,
  SET_BIKE_SELECTION_VALUE,
  SET_CATEGORY
} from "../actions/extraMeasurementsActionTypes";

const initialState = {
  bikeMeasurements: {
    category: "MTB",
    mtb: {
      saddleHeight: '',
      saddleSetback: '',
      handlebarReach: '',
      drop: ''
    },
    road: {
      saddleHeight: '',
      saddleSetback: '',
      handlebarReach: '',
      drop: ''
    }
  },
  bikeSelection: {
    bikeType: '',
    year: 2021,
    model: '',
    brand: '',
    bike: {}
  }
};

function extraMeasurements(state = initialState, action) {
  let newState = {...state}

  switch (action.type) {
    case SET_BIKE:
      return {...state, bikeSelection: {...state.bikeSelection, bike: action.bike}}
    case SET_CATEGORY:
      return {...state, bikeMeasurements: {...state.bikeMeasurements, category: action.value}}
    case SET_BIKE_VALUE:
      if (['saddleHeight', 'saddleSetback', 'handlebarReach', 'personLength', 'drop'].includes(action.key)) {
        if (state.bikeMeasurements.category === 'MTB') {
          if ( ! newState.bikeMeasurements.mtb) {
            newState.bikeMeasurements.mtb = {}
          }
          newState.bikeMeasurements.mtb[action.key] = action.value
        } else {
          if ( ! newState.bikeMeasurements.road) {
            newState.bikeMeasurements.road = {}
          }
          newState.bikeMeasurements.road[action.key] = action.value
        }
      } else {
        console.log('extraMeasurements bike key is not in allowed keys', action.key)
      }

      return newState;
    case SET_BIKE_SELECTION_VALUE:
      if (['bikeType', 'year', 'model', 'brand'].includes(action.key)) {
        newState.bikeSelection[action.key] = action.value
      }

      return newState;
    default:
      return state;
  }
}

export default extraMeasurements;
