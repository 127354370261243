import NL from './nl.json';
import EN from './en.json';
import LT from './lt.json';

let nl = NL;
let en = EN;
let lt = LT;


export { nl, en, lt };
