import React from "react";
import Card from "react-bootstrap/Card";
import "./ExpertCard2.css";

const ExpertCard2 = (review) => {
  const content = (review.lang || {}).content || "";
  const title = (review.lang || {}).title || "";
  const author = review.author || "";

  function createMarkup(text) {
    return { __html: text };
  }

  return (
    <Card className="expertcard shadow-column">
      <Card.Body className="expertcard__body">
        <img alt="" src={review.image} style={{ width: 100, height: 100 }} />
        <Card.Text
          dangerouslySetInnerHTML={createMarkup(content)}
          className="expertcard__content"
        ></Card.Text>
        <div>
          <div style={{ marginBottom: 35 }}>
            <img
              alt="Logo"
              src={review.logo}
              style={{ width: "100%", maxHeight: 100 }}
            />
          </div>
          <div
            dangerouslySetInnerHTML={createMarkup(author)}
            style={{ fontSize: "20px" }}
          ></div>
          <div dangerouslySetInnerHTML={createMarkup(title)}></div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExpertCard2;
