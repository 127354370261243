import React, { useEffect, useState } from 'react';
import Footer from '../../../components/Footer';
import BlogOverviewGrid from './BlogOverviewGrid';
import BlogService from '../../../lib/Blog';
import Loader from "../../../components/Loader";
import i18n from "../../../i18n";
import "./style.css";

const BlogOverview = () => {
    const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("all");

    const fetchCategories = async () => {
        try {
            const response = await BlogService.getCategories();
            setCategories(response.data.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchDataByCategory = async (category, search) => {
        try {
            setLoading(true);
            let response;

            if (category === 'all') {
                response = await BlogService.getAllBlogs(search);
            } else {
                response = await BlogService.getBlogsInCategory(category, search);
            }

            setBlogData(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setLoading(false);
        }
    }

    const onFilterButtonClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        i18n.on('languageChanged', async (lng) => {
            await fetchCategories();
            await fetchDataByCategory(selectedCategory, search);
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const blogs = await BlogService.getAllBlogs();
                setBlogData(blogs.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                setError('Error fetching blogs.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        // Use a separate function to handle async operation for fetching categories
        fetchCategories();
    }, []); // Empty dependency array ensures this useEffect runs only once

    useEffect(() => {
        fetchDataByCategory(selectedCategory, search)
    }, [search, selectedCategory]);

    return (
      <>
          <div className={`blog-overview`}>
              <div className="container">
                  <div className="row blog-overview-filter-wrapper justify-content-center m-4">
                      <button
                        type="button"
                        className={"mx-2 btn btn-filter " + (selectedCategory === "all" ? "active" : "")}
                        onClick={(e) => { e.preventDefault(); onFilterButtonClick("all"); }}
                      >
                          All
                      </button>
                      {categories && categories?.map((cat, index) =>
                        <button
                          key={`{category-${index + 1}}`}
                          type="button"
                          className={`mx-2 btn btn-filter ${(selectedCategory === cat?.attributes?.slug ? "active" : "")}`}
                          onClick={(e) => {
                              e.preventDefault();
                              onFilterButtonClick(cat?.attributes?.slug)
                          }}
                        >
                            {cat?.attributes?.name?.toUpperCase()}
                        </button>
                      )}
                  </div>
                  <div className="row justify-content-center mb-4 search-div">
                      <div className="input-group w-auto mb-2">
                          <div className="input-group-prepend">
                              <span className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></span>
                              <input className="form-control" type="text" placeholder={"Search"} id="search" onChange={(e) => setSearch(e.target.value) } />
                          </div>
                      </div>
                  </div>
                  <div className="row blog-overview-posts-wrapper">
                      {loading && <Loader />}
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                      {!loading && !error && blogData && <BlogOverviewGrid blogData={blogData} />}
                  </div>
              </div>
              <Footer />
          </div>
      </>
    );
};

export default BlogOverview;
