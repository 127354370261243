import Api from './Api'
import {config} from "../config";
import ReactGA from "react-ga4";
import sha256 from 'crypto-js/sha256';
import Locale from "./Locale";

class Newsletter {
    setEntry(obj) {
        localStorage.setItem('newsletter_info', JSON.stringify(obj))
        Api.post('/newsletter/add', obj)

        this.setGtagUserData(obj);
    }

    setGtagUserData(rawUserData = null) {
        if (config.trackGa !== false) {
            if (rawUserData === null) {
                rawUserData = this.getLocalEntry();
            }

            let userData = {
                email_address: this.hashData(rawUserData.email),
                first_name: this.hashData(rawUserData.firstname),
                last_name: this.hashData(rawUserData.lastname),
            }
            const country = Locale.getCountry();

            if (country && country !== 'INT') {
                userData['country'] = country;
            }

            ReactGA.gtag('set', 'user_data', userData);
        }
    }

    hashData(data) {
        if (!data) return null; // Check if the data is valid before hashing
        return sha256(data.trim().toLowerCase()).toString(); // Trim, lowercase, then hash
    }

    fetchLocalEntry() {
        Api.get('newsletter')
          .then((response) => {
              const status = response?.data?.response?.status || 0
              const newsletterData = response?.data?.response?.data

              if (status === 1000) {
                  localStorage.setItem('newsletter_info', JSON.stringify(newsletterData))
              }
          })
    }

    setOptinData(value) {
        let newsletterInfo = this.getLocalEntry()
        newsletterInfo.optinData = value
        localStorage.setItem('newsletter_info', JSON.stringify(newsletterInfo))
        if (newsletterInfo.firstname) {
            Api.post('/newsletter/add', newsletterInfo)
        }
    }

    setOptinMail(value) {
        let newsletterInfo = this.getLocalEntry()
        newsletterInfo.optinMail = value
        localStorage.setItem('newsletter_info', JSON.stringify(newsletterInfo))
        if (newsletterInfo.firstname) {
            Api.post('/newsletter/add', newsletterInfo)
        }
    }

    getLocalEntry() {
        let newsletterInfo = localStorage.getItem('newsletter_info');
        newsletterInfo = newsletterInfo ? JSON.parse(newsletterInfo) : {}
        newsletterInfo.optinData = newsletterInfo.optinData || false
        newsletterInfo.optinMail = newsletterInfo.optinMail || false

        return newsletterInfo;
    }

    setShownBefore() {
        if (!this.shownBefore()) {
            localStorage.setItem('newsletter_info', '{}');
        }
    }

    shownBefore() {
        return localStorage.getItem('newsletter_info') !== null
    }
}
// eslint-disable-next-line
export default new Newsletter();
