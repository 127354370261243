import { useTranslation } from "react-i18next";
import React from "react";
import Stars from "../../components/Bikefinder/Result/Stars"
const BikeComparisionCard = ({ item, traject, selectMtb,alphaRank }) => {
const [t] = useTranslation("bikeComparision");

/*
var brandUrl = '';
switch(item.bike_model.brand) {
    case 'BMC':
        brandUrl = 'https://www.bmc-switzerland.com/stockists';
        break;
    case 'Cannondale':
        brandUrl = 'https://www.cannondale.com/nl_NL/Europe/Dealers-near-you.aspx';
        break;
    case 'Canyon':
        brandUrl = 'https://www.canyon.com/nl/';
        break;
    case 'CUBE':
        brandUrl = 'https://www.cube.eu/nl/dealer-search/';
        break;
    case 'Felt':
        brandUrl = 'https://eu-en.feltbicycles.com/pages/dealers';
        break;
    case 'Focus':
        brandUrl = 'https://www.focus-bikes.com/be_nl/store-locator/';
        break;
    case 'Merida':
        brandUrl = 'https://www.merida.nl/dealers/';
        break;
    case 'Orbea':
        brandUrl = 'https://www.orbea.com/be-nl/dealers/?country=NL';
        break;
    case 'Scott':
        brandUrl = 'https://www.scott-sports.com/nl/en/dealers/locator';
        break;
    case 'Specialized':
        brandUrl = 'https://www.specialized.com/nl/nl/store-finder';
        break;
    case 'Stevens':
        brandUrl = 'https://www.stevensbikes.de/2019/index.php?cou=NL&lang=nl_NL&dealermap';
        break;
    case 'Trek':
        brandUrl = 'https://www.trekbikes.com/nl/nl_NL/store-finder/';
        break;
    default:
        break;
}
*/
  return (
    <div
      key={alphaRank}
      id={alphaRank}
      className={"optionr option-" + 1}
      // onClick={() => selectMtb(item)}
    >
      {
        item?
        <div className="option-inner text-center">
        <div className="option-number">{alphaRank}</div>

        <div className="top-wrap">
          <h3>{item.bike_model.brand + " " + item.bike_model.model}</h3>
          <img
            className="img-responsive"
            src={"/img/bikes/" + item.summary.image}
            alt={
              "Afbeelding " +
              item.bike_model.brand +
              " " +
              item.bike_model.model
            }
          />
        </div>
        <div className="bottom-wrap">
          <div className="info price">
            <strong>{t("Adviesprijs")}</strong>
            <br />
            &euro;{item.summary.price?item.summary.price.toLocaleString("nl"):t("Result_NotAvailable")}
          </div>
          <div className="info weight">
            <strong>{t("Gewicht")}</strong>
            <br />
            {item.bike_model.weight
              ? Math.round(item.bike_model.weight / 100) / 10 + " kg"
              : t("Result_NotAvailable")}
          </div>
          <div className="info material">
            <strong>{t("Framemateriaal")}</strong>
            <br />
            {item.bike_model.frame_material
              ? item.summary.frame_material
              : t("Result_NotAvailable")}
          </div>
          <div className="info rank gear">
            <strong>{t("Schakelsysteem")}</strong>
            <br />
            {item.summary.drive_chain !== null ? (
              <Stars number={Math.round(item.summary.drive_chain / 10)} />
            ) : (
              t("Result_NotApplicable")
            )}
          </div>
          {traject === "MTB" && (
            <div className="info rank suspension">
              <strong>{t("Vering")}</strong>
              <br />
              {item.summary.suspension !== null ? (
                <Stars number={Math.round(item.summary.suspension / 10)} />
              ) : (
                t("Result_NotApplicable")
              )}
            </div>
          )}
          <div className="info rank brake">
            <strong>{t("Remsysteem")}</strong>
            <br />
            {item.summary.brakes !== null ? (
              <Stars number={Math.round(item.summary.brakes / 10)} />
            ) : (
              t("Result_NotApplicable")
            )}
          </div>
          {traject === "Race" && (
          <div className="info rank wheel">
            <strong>{t("Wielset")}</strong>
            <br />
            {item.summary.wheels !== null ? (
              <Stars number={Math.round(item.summary.wheels / 10)} />
            ) : (
              t("Result_NotApplicable")
            )}
          </div>
          )}
          <div className="info rank frame">
            <strong>{t("Framesterkte en -garantie")}</strong>
            <br />
            {item.summary.frame !== null ? (
              <Stars number={Math.round(item.summary.frame / 10)} />
            ) : (
              t("Result_NotApplicable")
            )}
          </div>


        </div>
        {/* to be uncommented later */}
        {/* <a
          className={"btn btn-default"}
          href={brandUrl}
          target="_blank"
        >
          {t("FindPointOfSale")}
        </a> */}
      </div>
      :
      ""
      }
      
    </div>
  );
};

export default BikeComparisionCard;
