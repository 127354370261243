import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Footer from '../../../components/Footer';
import StartAdvice from '../../../components/StartAdvice';

class InfoDisclaimer extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div className="main">
			<div className="info-page">
				<StartAdvice t={this.props.t}/>
				<div className="container">
					<h1>{this.props.t('Disclaimer')}</h1>

					<p>{this.props.t('InfoDisclaimer-Framefit is niet aansprakelijk')}</p>

					<ul>
						<li>{this.props.t('Fouten in de data van fietsproducenten')}</li>
						<li>{this.props.t('Fouten in de lichaamsmaten die de consument heeft opgegeven')}</li>
						<li>{this.props.t('Fouten in de Framefit-algoritmen')}</li>
						<li>{this.props.t('Persoonlijke eigenschappen die aanzienlijk afwijken van het gemiddelde')}.</li>
					</ul>

					<p>{this.props.t('InfoDisclaimer-Bij twijfel adviseren wij om een fiets')}.</p>

				</div>

				<Footer t={this.props.t}/>

			</div></div>
		);
	}
}

export default withTranslation('infoDisclaimer')(InfoDisclaimer);
