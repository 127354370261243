import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Subscription from "../../lib/Subscription";
import './style.css'
import Auth from "../../lib/Auth";
import ReactGA from 'react-ga4';

const BikefinderOptions = ({trajectory}) => {
    const [t, i18next] = useTranslation('subscription')

    const [subscriptions, setSubscriptions] = useState([])

    const pay = subscription => {
        ReactGA.event('add_to_cart', {
            currency: subscription.price.currency,
            value: subscription.price.price / 100,
            items: [{
                item_id: subscription.code,
                item_name: `Product type: ${subscription.type}, code: ${subscription.code}`,
                item_category: subscription.type,
                price: subscription.price.price / 100,
                currency: subscription.price.currency
            }]
        });

        let returnUrl = '/' + trajectory + '/advice/result';
        if (subscription.needs_account && (! Auth.isLoggedIn() || Auth.getAccountType() === 'token')) {
            returnUrl = `/subscription/account?returnUrl=${returnUrl}`;
        }

        Subscription.createOrderAndGoToPayment(
            'bikefinder',
            subscription.code,
            returnUrl
        );
    }

    const loadSubscriptions = () => {
        Subscription.getSubscriptions('bikefinder')
            .then(subscriptions => {
                setSubscriptions(subscriptions)
            })
    }

    useEffect(() => {
        loadSubscriptions();

        ReactGA.event('view_item_list', {item_list_id: 'bikefinder', item_list_name: 'Bikefinder'});
    }, [i18next.language])

    return (
        <div className="info-page">
            <div className="container main">
                <div className="row">
                    {subscriptions.map(subscription => {
                        const buttonText = t(subscription.needs_account ? 'Bikefinder_PayAccount' : 'Bikefinder_PayOnce')
                        let locale = '';
                        switch (i18next.language) {
                            case 'nl_NL':
                                locale = 'nl-NL';
                                break;
                            default:
                                locale = 'en-GB';
                        }
                        const price = Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR'}).format(subscription.price / 100)
                        return (
                            <div key={subscription.code} className="col">
                                <div className={"shadow-column" + subscription.highlight ? ' highlighted' : ''}>
                                    <div className="subscription-block subscription-head-block">
                                        <h2>{subscription.lang ? subscription.lang.title : subscription.code}</h2>
                                        <span className="subscription-price">{price}</span>
                                    </div>
                                    <div className="subscription-block subscription-pay-button">
                                        <button className="btn btn-secondary" onClick={() => pay(subscription)}>{buttonText}</button>
                                    </div>
                                    {subscription.lang &&
                                    <div className="subscription-block subscription-points" dangerouslySetInnerHTML={{'__html':subscription.lang.points_html}} />
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default BikefinderOptions;
