import React from 'react';
import DynamicTitleDescription from '../DynamicTitleDescription/DynamicTitleDescription';
import IconTitleList from '../Lists/IconTitleList/IconTitleList';
import BikeFittingButton from '../button/BikeFittingButton';
import LightGreyContainer from '../containers/LightGreyContainer/LightGreyContainer';
import { useTranslation } from 'react-i18next';
import useScreenSize from '../../../../hooks/useScreenSize';

const FeatureListSection = ({ title, styledWord, description }) => {
  const sm = useScreenSize(768)
  const lg = useScreenSize(1024)

  const { t } = useTranslation("newLandingPage")
  const data = [
    {
      icon: "img/newLandingPage/icon/Check_Icon.png",
      title: t("Fiets comfortabeler")
    },
    {
      icon: "img/newLandingPage/icon/Check_Icon.png",
      title: t("Verbeter jouw")
    },
    {
      icon: "img/newLandingPage/icon/Check_Icon.png",
      title: t("Koop de")
    },
  ]

  return (
    <LightGreyContainer >
      <DynamicTitleDescription
        title={title}
        styledWord={styledWord}
        color="#F5755D"
        description={description}
      />

      <IconTitleList data={data} iconBg='white' style={{ backgroundColor: '#585858', color: 'white', padding: sm ? '10px' : '10px 28px', borderRadius: '12px', width: sm ? '100%' : lg ? '85%' : '65%', marginLeft: 'auto', marginRight: 'auto' }} />

      <BikeFittingButton buttonColor="#F5755D"
        textColor="white" />
    </LightGreyContainer>
  );
};

export default FeatureListSection;