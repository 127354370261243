import Auth from "./Auth";
import Newsletter from "../lib/Newsletter"
class DataStore {
    attributes = [
        'sex',
        'gender',
        // 'position',
        'length',
        'age',
        'arm',
        'romp',
        'leg',
        'weight',

        // bikefit
        'lower_leg',
        'upper_leg',
        'torso',
        'shoe',

        'mtbSadleheight',
        'mtbSetback',
        'mtbLength',
        'mtbSadleground',
        'mtbSteerground',
        'mtbSuspension',
        'mtbFully',
        'raceSadleheight',
        'raceSetback',
        'raceLength',
        'raceDrop',
        'raceSteerground',
        'raceSadleground',
    ];

    intAttributes = ['sex', 'gender'];

    isKey = key => this.attributes.includes(key);

    hasValue = (key) => localStorage.getItem(key) !== undefined;
    hasValues = () => this.attributes.filter((key) => this.hasValue(key)).length > 0;

    getValues = () => {
        let values = {};
        this.attributes.forEach((key) => values[key] = this.getValue(key));
        return values;
    };

    getValue = (key) => {
        if (this.isKey(key)) {
            if (this.intAttributes.includes(key)) {
                return parseInt(localStorage.getItem(key), 10);
            } else {
                return localStorage.getItem(key)
            }
        } else {
            console.error(key + " is no data key (getValue)");
        }
    };

    setValue = (params) => {
        for (const [key, value] of Object.entries(params)) {
            if (this.isKey(key)) {
                if (value === null) {
                    localStorage.removeItem(key);
                } else {
                    localStorage.setItem(key, value);
                }
            } else {
                console.error(key + " is no data key (setValue)");
            }
        }
    };

    pushData = () => {
        const optinData = Newsletter.getLocalEntry().optinData
        return new Promise((resolve, reject) => {
            if (Auth.isLoggedIn() && optinData) {
                Auth.setDataSettings(this.getValues())
                  .then(() => resolve())
                  .catch(() => reject())
            } else {
                resolve();
            }
        })
    }

    isSame = (params) => {
        return this.attributes.filter((key) => (params[key] !== this.getValue(key))).length === 0;
    };
}
// eslint-disable-next-line
export default new DataStore();
