import React from 'react';
import './style.css'

const DynamicTitleDescription = ({ isHeader, title, styledWord, description, color, classNames, onDescriptionClick = null }) => {
  const words = title?.split(' ')
  const styledWords = styledWord?.split(' ')

  return (
    <div className={`text-left text-md-center  ${classNames}`}>
      {
        isHeader ?
          <h1 className={`h1 ${classNames}`}>
            {
              words.map((word, index) => {
                const style = styledWords?.includes(word) ? color : ""

                return (
                  <span key={index} style={{ color: style }}>
                    {word} {word.includes(':') ? <br /> : ' '}
                  </span>
                )
              })
            }
          </h1> :
          <h2 className={`h2 ${classNames}`}>
            {
              words.map((word, index) => {
                const style = styledWords?.includes(word) ? color : ""

                return (
                  <span key={index} style={{ color: style }}>
                    {word} {word.includes(':') ? <br /> : ' '}
                  </span>
                )
              })
            }
          </h2>
      }
      <p className={`${isHeader ? `h1-sub ${classNames}` : `h2-sub ${classNames}`}`} onClick={onDescriptionClick}>{description}</p>
    </div>
  );
};

export default DynamicTitleDescription;