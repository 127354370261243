import React from "react";

const Stars = ({number}) => {
    return (
        <div className={"score score" + number}>
            <div className="star star1"></div>
            <div className="star star2"></div>
            <div className="star star3"></div>
            <div className="star star4"></div>
            <div className="star star5"></div>
        </div>
    );
};

export default Stars;