export const selectBikefitChoices = (store) => store.bikefit?.choices;
export const selectBikefitStep = (store) => store.bikefit.step;
export const selectBikefitSubStep = (store) => store.bikefit.subStep;
export const selectBikefitBikeTypeId = (store) => store.bikefit.choices?.["stepA"]?.["bikeType"] || 0
export const selectBikefitBikeType = (store) => (store.bikefit.choices?.["stepA"]?.["bikeType"] || 1) === 1 ? 'Road' : 'MTB';
export const selectFromBikefitMtbCategory = (store) => store.bikefit.choices?.["stepA"]?.["mountainBikeCategory"] || 0

export const selectFromBikefitFlow = (store) => store.bikefit.fromBikeFitFlow;

export const selectBikefitCurrentChoice = (defaultValue) => store => {
  const step = selectBikefitStep(store);
  const subStep = selectBikefitSubStep(store);

  return store.bikefit.choices?.[step]?.[subStep] || defaultValue;
}

export const selectBikefitAllCurrentStepChoices = (defaultValue) => store => {
  const step = selectBikefitStep(store);
  return store.bikefit.choices?.[step] || defaultValue;
}