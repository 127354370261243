import React from 'react';

const IconTitleList = ({ data, style, iconBg }) => {
  return (
    <ul className="list-group my-md-5">
      {data?.map((data, index) => (
        <li key={index} className="d-flex align-items-center text-left mb-3" style={style}>
          <img className={`icon mr-3 bg-${iconBg} rounded-pill`} src={data.icon} alt="" />
          <span className={` ${iconBg ? 'h4 m-0' : 'text-l'} `} >{data.title}</span>
        </li>
      ))}
    </ul>
  );
};

export default IconTitleList;