import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

import Locale from "../../lib/Locale";
import Auth from "../../lib/Auth";
import Redirections from "../../lib/Redirections";
import Loader from "../Loader";
import Subscription from "../../lib/Subscription";
import ReactGA from 'react-ga4';

const BikefitExtension = ({subscriptionCode, token, accountSubscriptionId}) => {
  const [t, ] = useTranslation("subscription");
  const [loading, setLoading] = useState(false);
  const returnUrl = Locale.getUrlForCurrentLocale("/");

  const goToPayment = () => {
    Subscription.createOrderAndGoToPayment(
      'bikefit',
      subscriptionCode,
      returnUrl,
      true,
      accountSubscriptionId
    );
  }

  useEffect(() => {
    setLoading(true)

    ReactGA.event('view_item_list', {item_list_id: 'bikefit_extension', item_list_name: 'Bikefit extension'});

    if (token && ! Auth.isLoggedIn()) {
      Auth.checkValidToken(token).then(() => {
        Auth.setAuth(token, 'login');
        goToPayment()
      }).catch(() => {
        alert(t('Subscription_ExtensionTokenExpired'))
        Redirections.goTo('/pricing')
      })
    } else {
      goToPayment()
    }
  }, [])

  return loading ? <Loader></Loader> : (
    <div className="main">

    </div>
  );
};

export default BikefitExtension;
