import React from 'react';
import { Route, Switch } from "react-router-dom";
// import BikefitSimple from "./BikefitSimple";
import BikeFinder from "./Bikefinder";
import BikefinderOptions from "./BikefinderOptions";
import BikefitExtension from "./BikefitExtension";
import BikefitPurchaseOptions from "./BikefitPurchaseOptions";
import CheckoutData from "./CheckoutData";
import StatusCheck from "./StatusCheck";
import FinishAccount from "./FinishAccount";
import BikefitLogin from './BikefitLogin';

const Routes = ({match}) => (
    <React.Fragment>
        <Switch>
            {/*<Route path={`${match.url}/payment/bikefit`}>*/}
            {/*    <BikefitSimple />*/}
            {/*</Route>*/}

            <Route path={`${match.url}/login`}>
                <BikefitLogin />
            </Route>

            <Route path={`${match.url}/payment/bikefit`}>
                <BikefitPurchaseOptions />
            </Route>

            <Route path={`${match.url}/account/finish`}>
                <FinishAccount />
            </Route>

            <Route path={`${match.url}/extend-bikefit/:subscriptionCode/:token/:accountSubscriptionId`}
                   render={({match}) => <BikefitExtension token={match.params.token} subscriptionCode={match.params.subscriptionCode}
                                                          accountSubscriptionId={match.params.accountSubscriptionId}/>}
            />

            <Route path={`${match.url}/payment/data/discount/:discount`}
                   render={({match}) => <CheckoutData discountTokenFromUrl={match.params.discount} />}>
            </Route>

            <Route exact path={`${match.url}/payment/data`}>
                <CheckoutData />
            </Route>

            <Route path={`${match.url}/payment/bikeFinder/:trajectory`}
                render={({match}) => <BikeFinder trajectory={match.params.trajectory} />} />

            <Route path={`${match.url}/payment/bikeFinder-options/:trajectory`}
                   render={({match}) => <BikefinderOptions trajectory={match.params.trajectory} />} />

            <Route
              path={`${match.url}/status/:orderId`}
              render={({match}) => <StatusCheck orderId={match.params.orderId} />}
            />

            <Route
              path={`${match.url}/bikefit-extension/status/:orderId`}
              render={({match}) => <StatusCheck orderId={match.params.orderId} extension={true} />}
            />
        </Switch>
    </React.Fragment>
)

export default Routes;
